import { createContext, useState, useMemo } from "react";
/*istanbul ignore next */
export const PageInit = {
  isEditMode: false,
  setEditMode(mode) {
    this.isEditMode = mode;
  },
  isDirty: false,
  setDirty(val) {
    this.isDirty = val || false;
  },
  leftMenuClicked: false,
  setLeftMenuClicked(val) {
    this.leftMenuClicked = val;
  },
  leftMenuClickedUrl: "",
  setLeftMenuClickedUrl(url) {
    this.leftMenuClickedUrl = url;
  },
  breadcrumbRootNode: null,
  setBreadcrumbRootNode(breadcrumbRootNode) {
    this.breadcrumbRootNode = breadcrumbRootNode;
  },
  breadcrumbRoute: [],
  setBreadcrumbRoute(route) {
    this.breadcrumbRoute = route;
  },
  isContentIdsSelected: false,
  setIsContentIdsSelected(status) {
    this.isContentIdsSelected = status;
  },
};

export const PageContext = createContext(PageInit);
/*istanbul ignore next */
const PageContextProvider = ({ children }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [breadcrumbRoute, setBreadcrumbRoute] = useState([]);
  const [breadcrumbRootNode, setBreadcrumbRootNode] = useState(null);
  const [isContentIdsSelected, setIsContentIdsSelected] = useState(false);
  const value = useMemo(
    () => ({
      isEditMode,
      setEditMode,
      isDirty,
      setDirty,
      breadcrumbRootNode,
      setBreadcrumbRootNode,
      breadcrumbRoute,
      setBreadcrumbRoute,
      isContentIdsSelected,
      setIsContentIdsSelected,
    }),
    [
      isEditMode,
      isDirty,
      breadcrumbRootNode,
      breadcrumbRoute,
      isContentIdsSelected,
    ]
  );
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export default PageContextProvider;
