import React from "react";
import { Router, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./OktaLogin/auth/PrivateRoute";
import { createBrowserHistory } from "history";
import Loader from "./common/Loader/Loader";
import LoaderContextProvider from "./contexts/LoaderContext";
import PageContextProvider from "./contexts/PageContext";
import { ToastContainer } from "react-toastify";

const App = (props) => {
  const history = createBrowserHistory();

  return (
    <div>
      <LoaderContextProvider>
        <PageContextProvider>
          <Loader />
          <Router history={history}>
            <Route path="/" component={PrivateRoute} />
            <div>
              <ToastContainer closeButton={true} position="top-right" />
            </div>
          </Router>
        </PageContextProvider>
      </LoaderContextProvider>
    </div>
  );
};

export default App;
