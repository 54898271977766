import React from "react";
import getClassNames from "./classnames";

function Tag(props) {
  const { text, remove, disabled, className } = props;

  const handleOnRemove = (e) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <span className={getClassNames("rti--tag", className)}>
      <span>{text}</span>
      {!disabled && (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
        >
          &#10005;
        </button>
      )}
    </span>
  );
}

export default Tag;
