import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListSubheader,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import CheckboxList from "../CheckboxList/CheckboxList";

function Filter(props) {
  const {
    id,
    anchorEl,
    open,
    options,
    onApplyFilter,
    onClose,
    dynamicclass,
    selected,
  } = props;
  const [expanded, setExpanded] = useState("");
  const [selectedItem, setSelectedItem] = useState(selected || {});

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onFilterChangeHandler = (field, values) => {
    const item = { ...selectedItem };
    item[field] = values;
    setSelectedItem(item);
  };

  const clearFilterHandler = () => {
    setSelectedItem({});
  };

  const handleClose = () => {
    onClose();
  };

  const onApplyHandler = () => {
    onApplyFilter(selectedItem);
  };

  return (
    <div className="filter-container">
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={dynamicclass}
      >
        <div className="expanded-filter">
          <div className="filter-options">
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Filters will be applied to your grid
                </ListSubheader>
              }
            >
              {options?.map((option) => (
                <Accordion
                  key={option.field}
                  expanded={expanded === option.field}
                  onChange={filterAccordianHandleChange(option.field)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      {option.label}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {option.field === "contentOriginator" ? (
                      <TextField
                        id={option.field}
                        label="Enter Content Originator"
                        variant="outlined"
                        fullWidth
                        value={selectedItem[option.field] || ""}
                        onChange={(e) =>
                          onFilterChangeHandler(option.field, e.target.value)
                        }
                      />
                    ) : (
                      <CheckboxList
                        id={option.field}
                        options={option.options}
                        onChange={(values) => {
                          onFilterChangeHandler(option.field, values);
                        }}
                        selectedValues={selectedItem[option.field]}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </div>
          <div className="modal-footer">
            <Button
              variant="outlined"
              className="action-button mr-4"
              onClick={clearFilterHandler}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              className="action-button"
              data-testid="addx-add-button"
              onClick={() => {
                onApplyHandler();
                handleClose();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default Filter;
