import { useContext } from "react";
import { LoaderContext } from "../contexts/LoaderContext";

export function useLoading() {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider.");
  }
  return context;
}
