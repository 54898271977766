import { COLUMN_TYPES } from "../../constants/common";

const ActionColumnsAdmin = [
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: false,
    width: 190,
    rightPadding: 0,
    isFixed: true,
  },
];

export const DefaultDataColumns = [
  {
    fieldName: "userId",
    label: "USER ID",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.CHECKBOX,
    showInSelection: false,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    exportLabel: "FirstName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    exportLabel: "LastName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "email",
    label: "Email",
    exportLabel: "Email",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "roles",
    label: "Roles",
    exportLabel: "Roles",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "annualRevenueText",
    label: "Annual Revenue",
    exportLabel: "AnnualRevenue",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingAddressLine1",
    label: "Billing Address 1",
    exportLabel: "BillingAddressLine1",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingAddressLine2",
    label: "Billing Address 2",
    exportLabel: "BillingAddressLine2",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingContact",
    label: "Billing First Name",
    exportLabel: "BillingContact",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingContactLastName",
    label: "Billing Last Name",
    exportLabel: "BillingContactLastName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingPhoneNumber",
    label: "Billing Phone Number",
    exportLabel: "BillingPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessName",
    label: "Business Name",
    exportLabel: "BusinessName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessYear",
    label: "Business Year",
    exportLabel: "YearsInBusiness",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile",
    label: "Mobile",
    exportLabel: "Mobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile1",
    label: "Alternate Mobile",
    exportLabel: "AlternateMobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax",
    label: "Fax",
    exportLabel: "Fax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax1",
    label: "Alternate Fax",
    exportLabel: "AlternateFax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEmployees",
    label: "Number Of Employees",
    exportLabel: "NumberOfEmployees",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfOwners",
    label: "Number of Owners",
    exportLabel: "NumberOfPrincipals/Owners",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "phoneNumber",
    label: "Business Phone Number",
    exportLabel: "BusinessPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 250,
  },
  {
    fieldName: "secondaryPhoneNumber",
    label: "Business Phone Number2",
    exportLabel: "Business2PhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "producers",
    label: "Producers",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "secondaryEmail",
    label: "Secondary Email",
    exportLabel: "SecondaryEmailAddress",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "zipCode",
    label: "ZipCode",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "city",
    label: "City",
    exportLabel: "City",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "state",
    label: "State",
    exportLabel: "State",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "systemGeneratedId",
    label: "System Generated Id",
    exportLabel: "SystemGeneratedId",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "taxID",
    label: "Tax ID",
    exportLabel: "TaxID",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "websiteUrl",
    label: "Website URL",
    exportLabel: "WebsiteURL",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "areas",
    label: "Areas",
    exportLabel: "Areas",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "carriers",
    label: "Carrier",
    exportLabel: "Carrier",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "carrierRepresents",
    label: "Carrier Represents",
    exportLabel: "CarrierRepresents",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "technologies",
    label: "Technologies",
    exportLabel: "Technologies",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "industryGroups",
    label: "Industry Groups",
    exportLabel: "IndustryGroups/Membership",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
];

export const AdminColumns = [
  {
    fieldName: "userId",
    label: "USER ID",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.CHECKBOX,
    showInSelection: false,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    exportLabel: "FirstName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    exportLabel: "LastName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "email",
    label: "Email",
    exportLabel: "Email",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "secondaryEmail",
    label: "Secondary Email",
    exportLabel: "SecondaryEmailAddress",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "phoneNumber",
    label: "Business Phone Number",
    exportLabel: "BusinessPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "secondaryPhoneNumber",
    label: "Business Phone Number 2",
    exportLabel: "Business2PhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "mobile",
    label: "Mobile",
    exportLabel: "Mobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile1",
    label: "Alternate Mobile",
    exportLabel: "AlternateMobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax",
    label: "Fax",
    exportLabel: "Fax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax1",
    label: "Alternate Fax",
    exportLabel: "AlternateFax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "roles",
    label: "Roles",
    exportLabel: "Roles",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "systemGeneratedId",
    label: "System Generated Id",
    exportLabel: "SystemGeneratedId",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "isLocked",
    label: "Account Status",
    exportLabel: "AccountStatus",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.UNBLOCK_TOGGLE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
  },
  ...ActionColumnsAdmin,
];

export const DistributorColumns = [
  {
    fieldName: "userId",
    label: "USER ID",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.CHECKBOX,
    showInSelection: false,
  },
  {
    fieldName: "brandName",
    label: "Brand Name",
    exportLabel: "BrandName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },

  {
    fieldName: "firstName",
    label: "First Name",
    exportLabel: "FirstName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    exportLabel: "LastName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "email",
    label: "Email",
    exportLabel: "Email",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "roles",
    label: "Roles",
    exportLabel: "Roles",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "annualRevenueText",
    label: "Annual Revenue",
    exportLabel: "AnnualRevenue",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingAddressLine1",
    label: "Billing Address 1",
    exportLabel: "BillingAddressLine1",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingAddressLine2",
    label: "Billing Address 2",
    exportLabel: "BillingAddressLine2",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingContact",
    label: "Billing First Name",
    exportLabel: "BillingFirstName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingContactLastName",
    label: "Billing Last Name",
    exportLabel: "BillingContactLastName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingPhoneNumber",
    label: "Billing Phone Number",
    exportLabel: "BillingPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessName",
    label: "Business Name",
    exportLabel: "BusinessName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessYear",
    label: "Business Year",
    exportLabel: "YearsInBusiness",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile",
    label: "Mobile",
    exportLabel: "Mobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile1",
    label: "Alternate Mobile",
    exportLabel: "AlternateMobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax",
    label: "Fax",
    exportLabel: "Fax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax1",
    label: "Alternate Fax",
    exportLabel: "AlternateFax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEmployees",
    label: "Number Of Employees",
    exportLabel: "NumberOfEmployees",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfOwners",
    label: "Number of Owners",
    exportLabel: "NumberOfPrincipals/Owners",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "phoneNumber",
    label: "Business Phone Number",
    exportLabel: "BusinessPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "secondaryPhoneNumber",
    label: "Business Phone Number2",
    exportLabel: "Business2PhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "producers",
    label: "Producers",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "secondaryEmail",
    label: "Secondary Email",
    exportLabel: "SecondaryEmailAddress",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "zipCode",
    label: "ZipCode",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "city",
    label: "City",
    exportLabel: "City",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "state",
    label: "State",
    exportLabel: "State",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "systemGeneratedId",
    label: "System Generated Id",
    exportLabel: "SystemGeneratedId",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "taxID",
    label: "Tax ID",
    exportLabel: "TaxID",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "websiteUrl",
    label: "Website URL",
    exportLabel: "WebsiteURL",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "areas",
    label: "Areas",
    exportLabel: "Areas",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "carriers",
    label: "Carrier",
    exportLabel: "Carrier",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "carrierRepresents",
    label: "Carrier Represents",
    exportLabel: "CarrierRepresents",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "technologies",
    label: "Technologies",
    exportLabel: "Technologies",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "industryGroups",
    label: "Industry Groups",
    exportLabel: "IndustryGroups/Membership",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "distributorTypes",
    label: "Distributors Types",
    exportLabel: "DistributorsTypes",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "isLocked",
    label: "Account Status",
    exportLabel: "AccountStatus",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.UNBLOCK_TOGGLE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
  },
  ...ActionColumnsAdmin,
];

export const EnterpriseColumns = [
  {
    fieldName: "brandName",
    label: "Brand Name",
    exportLabel: "BrandName",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "userId",
    label: "USER ID",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.CHECKBOX,
    showInSelection: false,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    exportLabel: "FirstName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    exportLabel: "LastName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "email",
    label: "Email",
    exportLabel: "Email",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "roles",
    label: "Roles",
    exportLabel: "Roles",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "annualRevenueText",
    label: "Annual Revenue",
    exportLabel: "AnnualRevenue",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingAddressLine1",
    label: "Billing Address 1",
    exportLabel: "BillingAddressLine1",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "billingAddressLine2",
    label: "Billing Address 2",
    exportLabel: "BillingAddressLine2",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "billingContact",
    label: "Billing First Name",
    exportLabel: "BillingContact",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "billingContactLastName",
    label: "Billing Last Name",
    exportLabel: "BillingContactLastName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "billingPhoneNumber",
    label: "Billing Phone Number",
    exportLabel: "BillingPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessName",
    label: "Business Name",
    exportLabel: "BusinessName",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "businessYear",
    label: "Business Year",
    exportLabel: "YearsInBusiness",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile",
    label: "Mobile",
    exportLabel: "Mobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "mobile1",
    label: "Alternate Mobile",
    exportLabel: "AlternateMobile",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax",
    label: "Fax",
    exportLabel: "Fax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fax1",
    label: "Alternate Fax",
    exportLabel: "AlternateFax",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEmployees",
    label: "Number Of Employees",
    exportLabel: "NumberOfEmployees",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfOwners",
    label: "Number of Owners",
    exportLabel: "NumberOfPrincipals/Owners",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "phoneNumber",
    label: "Business Phone Number",
    exportLabel: "BusinessPhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "secondaryPhoneNumber",
    label: "Business Phone Number2",
    exportLabel: "Business2PhoneNumber",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "producers",
    label: "Producers",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "secondaryEmail",
    label: "Secondary Email",
    exportLabel: "SecondaryEmailAddress",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 300,
  },
  {
    fieldName: "zipCode",
    label: "ZipCode",
    exportLabel: "",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "city",
    label: "City",
    exportLabel: "City",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "state",
    label: "State",
    exportLabel: "State",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "systemGeneratedId",
    label: "System Generated Id",
    exportLabel: "SystemGeneratedId",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "taxID",
    label: "Tax ID",
    exportLabel: "TaxID",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "websiteUrl",
    label: "Website URL",
    exportLabel: "WebsiteURL",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "areas",
    label: "Areas",
    exportLabel: "Areas",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "carrierRepresents",
    label: "Carrier Represents",
    exportLabel: "CarrierRepresents",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "technologies",
    label: "Technologies",
    exportLabel: "Technologies",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "industryGroups",
    label: "Industry Groups",
    exportLabel: "IndustryGroups/Membership",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "enterpriseTypes",
    label: "Enterprise Types",
    exportLabel: "EnterpriseTypes",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "isLocked",
    label: "Account Status",
    exportLabel: "AccountStatus",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.UNBLOCK_TOGGLE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
  },
  ...ActionColumnsAdmin,
];

export const SubscriberColumns = [
  ...DefaultDataColumns,
  {
    fieldName: "isLocked",
    label: "Account Status",
    exportLabel: "AccountStatus",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.UNBLOCK_TOGGLE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "subscriberTypes",
    label: "Subscriber Types",
    exportLabel: "SubscriberTypes",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "associatedStatus",
    label: "Associate Status",
    exportLabel: "Associate Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
  },
  ...ActionColumnsAdmin,
];

export const DistributorInternalUserColumns = [
  ...AdminColumns,
  // {
  //   fieldName: "isLocked",
  //   label: "Account Status",
  //   exportLabel: "AccountStatus",
  //   sort: true,
  //   isSelected: true,
  //   type: COLUMN_TYPES.UNBLOCK_TOGGLE,
  //   showInSelection: true,
  // },
  // {
  //   fieldName: "parentUserId",
  //   label: "Parent User Id",
  //   exportLabel: "Distributor/EnterpriseId",
  //   sort: true,
  //   isSelected: false,
  //   type: COLUMN_TYPES.BASIC,
  //   showInSelection: true,
  // },
  // ...ActionColumnsAdmin,
];

export const EnterpriseInternalUserColumns = [
  ...AdminColumns,
  // {
  //   fieldName: "isLocked",
  //   label: "Account Status",
  //   exportLabel: "AccountStatus",
  //   sort: true,
  //   isSelected: true,
  //   type: COLUMN_TYPES.UNBLOCK_TOGGLE,
  //   showInSelection: true,
  // },
  // {
  //   fieldName: "parentUserId",
  //   label: "Parent User Id",
  //   exportLabel: "Distributor/EnterpriseId",
  //   sort: true,
  //   isSelected: false,
  //   type: COLUMN_TYPES.BASIC,
  //   showInSelection: true,
  // },
  // ...ActionColumnsAdmin,
];

export const ManageDocumentsListColumns = [
  {
    fieldName: "fileName",
    label: "File Name",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    showInSelection: false,
  },
  {
    fieldName: "userDocumentId",
    label: "Doc ID",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "createdOn",
    label: "Created On",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
  },
  {
    fieldName: "uploadedBy",
    label: "Uploaded By",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: true,
    isFixed: true,
    rightPadding: 0,
    options: [
      {
        label: "Delete",
        testId: "delete",
        status: "publishStatus",
        iconPath: "/images/delete_disable.png",
        iconPathInactive: "/images/delete_enable.png",
        disabled: false,
        testIdText: "DeleteIcon",
        activeTitle: "Delete Content",
        deactiveTitle: "Published content cannot be deleted",
      },
    ],
    width: 60,
  },
];
export const ManageDocumentsSuccessOrErrorListColumns = [
  {
    fieldName: "fileName",
    label: "File Name",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    showInSelection: false,
  },
  {
    fieldName: "userDocumentId",
    label: "Doc ID",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "createdOn",
    label: "Created On",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
  },
  {
    fieldName: "uploadedBy",
    label: "Uploaded By",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "massUploadedBy",
    label: "Mass Uploaded by",
    exportLabel: "",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: true,
    isFixed: true,
    rightPadding: 0,
    options: [
      {
        label: "Delete",
        testId: "delete",
        status: "publishStatus",
        iconPath: "/images/delete_disable.png",
        iconPathInactive: "/images/delete_enable.png",
        disabled: false,
        testIdText: "DeleteIcon",
        activeTitle: "Delete Content",
        deactiveTitle: "Published content cannot be deleted",
      },
    ],
    width: 60,
  },
];

export const EGRenewalHistoryColumns = [
  {
    fieldName: "distributorBrandName",
    label: "Distributor Brand Name",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 200,
  },
  {
    fieldName: "associationId",
    label: "Membership/Acc number",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 250,
  },
  {
    fieldName: "subscriptionName",
    label: "Subscription Type",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 150,
  },
  {
    fieldName: "frequencyText",
    label: "Frequency",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "startDate",
    label: "Start Date",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "expirationDate",
    label: "Expiration Date",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
    width: 150,
  },
  {
    fieldName: "subscriptionFees",
    label: "Subscription Amount",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 200,
  },
  {
    fieldName: "isRecurring",
    label: "Auto Renewal",
    exportLabel: "",
    sort: false,
    isSelected: true,
    YesLabelText: "Yes",
    NoLabelText: "No",
    type: COLUMN_TYPES.BOOLEAN,
    showInSelection: false,
    width: 150,
  },
  {
    fieldName: "statusText",
    label: "Status",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
];

export const SubscriberRenewalHistoryColumns = [
  {
    fieldName: "brandName",
    label: "Enterprise Name",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "subscriptionName",
    label: "Subscription Type",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "frequencyText",
    label: "Frequency",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "isRecurring",
    label: "Recurring",
    exportLabel: "",
    sort: false,
    isSelected: true,
    YesLabelText: "Yes",
    NoLabelText: "No",
    type: COLUMN_TYPES.BOOLEAN,
    showInSelection: false,
  },
  {
    fieldName: "subscriptionFees",
    label: "Subscription Fees",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "startDate",
    label: "Start Date",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
  },
  {
    fieldName: "endDate",
    label: "End Date",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: false,
  },
];

export const ErrorRecordsColumns = [
  {
    fieldName: "errors",
    label: "Errors",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 200,
  },
  {
    fieldName: "uploadedFileName",
    label: "Uploaded File Name",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 220,
  },
  {
    fieldName: "typeOfContent",
    label: "Type of Content",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "contentName",
    label: "Content Name",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "typesOfForms",
    label: "Types of Forms",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "lob",
    label: "LOB",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "country",
    label: "Country",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "state",
    label: "State/Province",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 120,
  },
  {
    fieldName: "latestVersion",
    label: "Edition Date",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "searchKeywords",
    label: "Search Keywords",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "tags",
    label: "Content Tags/Labels",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "fillable",
    label: "Fillable",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "fileName",
    label: "FileName",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 200,
  },
  {
    fieldName: "availableToPublic",
    label: "Available to Public",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "displayToPublic",
    label: "Display to Public",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "isPreviewAvailable",
    label: "Is Preview Available",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "previewAvailable",
    label: "Preview Available",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "webLink",
    label: "Web Link",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "contentType",
    label: "Content Type(Free/Paid)",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 150,
  },
  {
    fieldName: "oneTimeCost",
    label: "One Time Cost",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "subscriptionsType",
    label: "Subscriptions Type",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 120,
  },
  {
    fieldName: "baseValue",
    label: "Base Value",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "frequency",
    label: "Frequency",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "recurringType",
    label: "Recurring Type",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
  {
    fieldName: "publish",
    label: "Publish",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
    width: 100,
  },
];
