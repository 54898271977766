import React from "react";

function RadioButton(props) {
  const { item } = props;
  return (
    <input
      type="radio"
      disabled={props.isDisabled}
      title={item.ToolTip?.Content || ""}
      style={{
        borderColor: "black",
        backgroundColor: "white",
        width: item.Width,
        height: item.Height,
        position: "absolute",
        top: item.SetTop,
        left: item.SetLeft + 10,
      }}
    />
  );
}

export default RadioButton;
