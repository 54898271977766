import { Box, Grid } from "@mui/material";
import React from "react";
import "./Reports.css";

const Reports = () => {
  return (
    <Grid spacing={2} container className="reports-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Reports</h5>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pr={4} pl={4} pb={0}>
          To be developed.
        </Box>
      </Grid>
    </Grid>
  );
};

export default Reports;
