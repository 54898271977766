import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import ApiServices from "../../services/ApiServices";
import { MODULES_PERMISSION_API } from "../../constants/api-constants";
import "./LandingPage.css";

const LandingPage = () => {
  useState(() => {
    ApiServices.httpGet(MODULES_PERMISSION_API);
  });

  const auth = useAuth();
  const currentUser = auth.getCurrentUser();

  const toPascalCase = (str) =>
    (str.match(/[a-zA-Z0-9]+/g) || [])
      .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
      .join("");

  return (
    <Grid spacing={2} container className="landing-page-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Dashboard</h5>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pr={4} pl={4} pb={0} className="content">
          Hello{" "}
          {/* <span
            style={{ textTransform: "capitalize" }}
          > */}
          {`${toPascalCase(currentUser.firstName)} ${toPascalCase(
            currentUser.lastName
          )}`}
          {/* </span> */}, Welcome to Inchanted!
        </Box>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
