import { ARGB_To_RGBA_Color } from "../constants";

function Rectangle(props) {
  const { rectangle, fType } = props;
  return (
    <div
      data-testid="rectangle"
      style={{
        width: rectangle.Width,
        height: rectangle.Height,
        position: "absolute",
        top: rectangle.SetTop,
        left: rectangle.SetLeft + 10,
        border: rectangle.StrokeThickness,
        borderStyle: "solid",
        borderColor:
          fType === "NonAcord"
            ? ARGB_To_RGBA_Color(rectangle.Stroke.Color)
            : rectangle.Stroke.Color,
      }}
    ></div>
  );
}

export default Rectangle;
