import { COLUMN_TYPES } from "../../constants/common";
const ActionColumnsAdmin = [
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: false,
    isFixed: true,
    width: 200,
    rightPadding: 0,
  },
];

export const ContentDataColumns = [
  {
    fieldName: "formNumber",
    label: "Form Number",
    exportLabel: "Form Number",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 250,
  },
  {
    fieldName: "name",
    label: "Content Name",
    exportLabel: "Content Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "typeOfContentText",
    label: "Type Of Content",
    exportLabel: "Type Of Content",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },

  {
    fieldName: "userName",
    label: "Distributor",
    exportLabel: "Distributor",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "contentOriginator",
    label: "Content Originator",
    exportLabel: "Content Originator",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "typeOfFormList",
    label: "Type of Forms",
    exportLabel: "Type of Forms",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "lineOfBusinessList",
    label: "LOB",
    exportLabel: "LOB",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "states",
    label: "State/Province",
    exportLabel: "State/Province",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "latestVersion",
    label: "Edition Date",
    exportLabel: "Edition Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "Marketing Description",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "keywords",
    label: "Keywords",
    exportLabel: "Keywords",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "tages",
    label: "Content Tags/Labels",
    exportLabel: "Content Tags/Labels",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "fillableText",
    label: "Fillable",
    exportLabel: "Fillable",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "filePath",
    label: "File Name",
    exportLabel: "File Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },

  {
    fieldName: "createdOn",
    label: "Created Date",
    exportLabel: "Created Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "createdByName",
    label: "Created By",
    exportLabel: "Created By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedOn",
    label: "Last Updated Date",
    exportLabel: "Last Updated Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "updatedByName",
    label: "Last Updated By",
    exportLabel: "Last Updated By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEnrolledUser",
    label: "Number of Enrolled Users",
    exportLabel: "Number of Enrolled Users",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "isPublic",
    label: "Available to Public",
    exportLabel: "Available to Public",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Yes",
    NoLabelText: "No",
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "contentTypeText",
    label: "Content Type(Free/Paid)",
    exportLabel: "Content Type(Free/Paid)",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "publishStatus",
    label: "Published",
    exportLabel: "Published",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Published",
    NoLabelText: "Draft",
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    isFixed: true,
    width: 200,
    rightPadding: 190,
  },
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    isFixed: true,
    width: 190,
    rightPadding: 0,
    showInSelection: true,
    options: [
      {
        label: "Add to Favourite",
        testId: "favourite",
        status: "isFavorite",
        iconPath: "/images/favorite_enable.png",
        iconPathInactive: "/images/unfavorite_enable.png",
        disabled: false,
        testIdText: "favorite",
        activeTitle: "Add to Favorite",
        deactiveTitle: "Remove Favorite",
      },
      {
        label: "Delete",
        testId: "delete",
        status: "publishStatus",
        iconPath: "/images/delete_disable.png",
        iconPathInactive: "/images/delete_enable.png",
        disabled: false,
        testIdText: "DeleteIcon",
        activeTitle: "Delete Content",
        deactiveTitle: "Published content cannot be deleted",
      },
      {
        label: "Copy",
        testId: "copy",
        // status: "publishStatus",
        iconPath: "/images/copy-icon.png",
        iconPathInactive: "/images/copy-icon.png",
        disabled: false,
        testIdText: "CopyIcon",
        activeTitle: "Copy Content",
        deactiveTitle: "Copy Content",
      },
    ],
  },
  // {
  //   fieldName: "dynamicAction",
  //   label: "",
  //   exportLabel: "",
  //   sort: false,
  //   isSelected: true,
  //   type: COLUMN_TYPES.DYNAMIC_ACTION,
  //   showInSelection: true,
  //   options: [
  //     {
  //       label: "Favourite",
  //       testId: "favourite",
  //       status: "isFavorite",
  //       iconPath: "/images/favorite_enable.png",
  //       iconPathInactive: "/images/unfavorite_enable.png",
  //       disabled: false,
  //       testIdText: "favorite",
  //       activeTitle: "Favorite",
  //       deactiveTitle: "Favorite",
  //     },
  //     {
  //       label: "Delete",
  //       testId: "delete",
  //       status: "publishStatus",
  //       iconPath: "/images/delete_disable.png",
  //       iconPathInactive: "/images/delete_enable.png",
  //       disabled: false,
  //       testIdText: "DeleteIcon",
  //       activeTitle: "Delete Content",
  //       deactiveTitle: "Published content cannot be deleted",
  //     },
  //     {
  //       label: "Copy",
  //       testId: "copy",
  //       // status: "publishStatus",
  //       iconPath: "/images/copy-icon.png",
  //       iconPathInactive: "/images/copy-icon.png",
  //       disabled: false,
  //       testIdText: "CopyIcon",
  //       activeTitle: "Copy Content",
  //       deactiveTitle: "Copy Content",
  //     },
  //   ],
  //   width: 120,
  // },
];

export const ListOfContentDataColumns = [
  {
    fieldName: "name",
    label: "Content Name",
    exportLabel: "Content Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "typeOfContentText",
    label: "Type Of Content",
    exportLabel: "Type Of Content",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 160,
  },

  {
    fieldName: "userName",
    label: "Distributor",
    exportLabel: "Distributor",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "typeOfFormList",
    label: "Type of Forms",
    exportLabel: "Type of Forms",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 120,
  },
  {
    fieldName: "lineOfBusinessList",
    label: "LOB",
    exportLabel: "LOB",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "stateName",
    label: "State/Province",
    exportLabel: "State/Province",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "latestVersion",
    label: "Edition Date",
    exportLabel: "Edition Date",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 120,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "Marketing Description",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "keywords",
    label: "Keywords",
    exportLabel: "Keywords",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "tages",
    label: "Content Tags/Labels",
    exportLabel: "Content Tags/Labels",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fillableText",
    label: "Fillable",
    exportLabel: "Fillable",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },

  {
    fieldName: "filePath",
    label: "File Name",
    exportLabel: "File Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "createdOn",
    label: "Created Date",
    exportLabel: "Created Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "createdByName",
    label: "Created By",
    exportLabel: "Created By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedOn",
    label: "Last Updated Date",
    exportLabel: "Last Updated Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedByName",
    label: "Last Updated By",
    exportLabel: "Last Updated By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEnrolledUser",
    label: "Number of Enrolled Users",
    exportLabel: "Number of Enrolled Users",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 230,
  },
  {
    fieldName: "isPublic",
    label: "Available to Public",
    exportLabel: "Available to Public",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Yes",
    NoLabelText: "No",
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "contentTypeText",
    label: "Content Type(Free/Paid)",
    exportLabel: "Content Type(Free/Paid)",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 220,
  },
  {
    fieldName: "publishStatus",
    label: "Published",
    exportLabel: "Published",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Published",
    NoLabelText: "Draft",
    showInSelection: true,
    width: 120,
  },
  {
    fieldName: "status",
    label: "Status",
    exportLabel: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
    width: 150,
    cssClass: "status-change",
  },
];

export const ContentModalDataColumns = [
  {
    fieldName: "name",
    label: "Content Name",
    exportLabel: "Content Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "documentType",
    label: "Type",
    exportLabel: "Type",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.DOCTYPE,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "typeOfContentText",
    label: "Type Of Content",
    exportLabel: "Type Of Content",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "userName",
    label: "Distributor",
    exportLabel: "Distributor",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "ownerId",
    label: "Owner ID",
    exportLabel: "Owner ID",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "typeOfFormList",
    label: "Type of Forms",
    exportLabel: "Type of Forms",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 120,
  },
  {
    fieldName: "lineOfBusinessList",
    label: "LOB",
    exportLabel: "LOB",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "stateName",
    label: "State/Province",
    exportLabel: "State/Province",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "latestVersion",
    label: "Edition Date",
    exportLabel: "Edition Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "Marketing Description",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "keywords",
    label: "Keywords",
    exportLabel: "Keywords",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "tages",
    label: "Content Tags/Labels",
    exportLabel: "Content Tags/Labels",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fillableText",
    label: "Fillable",
    exportLabel: "Fillable",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },

  {
    fieldName: "filePath",
    label: "File Name",
    exportLabel: "File Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "createdOn",
    label: "Created Date",
    exportLabel: "Created Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "createdByName",
    label: "Created By",
    exportLabel: "Created By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedOn",
    label: "Last Updated Date",
    exportLabel: "Last Updated Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedByName",
    label: "Last Updated By",
    exportLabel: "Last Updated By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEnrolledUser",
    label: "Number of Enrolled Users",
    exportLabel: "Number of Enrolled Users",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 230,
  },
  {
    fieldName: "isPublic",
    label: "Available to Public",
    exportLabel: "Available to Public",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Yes",
    NoLabelText: "No",
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "contentTypeText",
    label: "Content Type(Free/Paid)",
    exportLabel: "Content Type(Free/Paid)",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 220,
  },
  {
    fieldName: "publishStatus",
    label: "Published",
    exportLabel: "Published",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Published",
    NoLabelText: "Draft",
    showInSelection: true,
    width: 120,
  },
];

export const ListOfContentCatalogDataColumns = [
  {
    fieldName: "contentId",
    label: "Content Id",
    exportLabel: "Content Id",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "name",
    label: "Content Name",
    exportLabel: "Content Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "typeOfContentText",
    label: "Type Of Content",
    exportLabel: "Type Of Content",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "userName",
    label: "Distributor",
    exportLabel: "Distributor",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 170,
  },
  {
    fieldName: "ownerId",
    label: "Owner ID",
    exportLabel: "Owner ID",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "typeOfFormList",
    label: "Type of Forms",
    exportLabel: "Type of Forms",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 160,
  },
  {
    fieldName: "lineOfBusinessList",
    label: "LOB",
    exportLabel: "LOB",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "fillableText",
    label: "Fillable",
    exportLabel: "Fillable",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "filePath",
    label: "File Name",
    exportLabel: "File Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 280,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "stateName",
    label: "State/Province",
    exportLabel: "State/Province",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "latestVersion",
    label: "Edition Date",
    exportLabel: "Edition Date",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "keywords",
    label: "Keywords",
    exportLabel: "Keywords",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "tags",
    label: "Content Tags/Labels",
    exportLabel: "Content Tags/Labels",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "fillable",
    label: "Fillable",
    exportLabel: "Fillable",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "createdDate",
    label: "Created Date",
    exportLabel: "Created Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "lastUpdatedDate",
    label: "Last Updated Date",
    exportLabel: "Last Updated Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "lastUpdatedDate",
    label: "Number of Enrolled Users",
    exportLabel: "Number of Enrolled Users",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 220,
  },
  {
    fieldName: "lastUpdatedDate",
    label: "Available to Public",
    exportLabel: "Available to Public",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "lastUpdatedDate",
    label: "Catalog Type(Free/Paid)",
    exportLabel: "Catalog Type(Free/Paid)",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "oneTimeCost",
    label: "One Time Cost",
    exportLabel: "One Time Cost",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "IsRecurring",
    label: "IsRecurring",
    exportLabel: "IsRecurring",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "Subscription",
    label: "Subscription",
    exportLabel: "Subscription",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "frequency",
    label: "Frequency",
    exportLabel: "Frequency",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "baseValue",
    label: "BaseValue",
    exportLabel: "BaseValue",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "actualValue",
    label: "ActualValue",
    exportLabel: "ActualValue",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "Marketing Description",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "published",
    label: "Published",
    exportLabel: "Published",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "IsDisplayPublic",
    label: "IsDisplayPublic",
    exportLabel: "IsDisplayPublic",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "contentAssociationStatus",
    label: "Associated",
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    showInSelection: true,
    width: 150,
    activeLabelText: "Yes",
    inactiveLabelText: "No",
  },
  {
    fieldName: "status",
    label: "Status",
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    showInSelection: true,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    width: 150,
    rightPadding: 200,
    isFixed: true,
    cssClass: "status-change",
  },
  ...ActionColumnsAdmin,
];
