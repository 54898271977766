import { useContext, createContext } from "react";
import { TABS } from "../../constants/common";

export const DistributorProfileInit = {
  selectedTab: localStorage.getItem("selectedTab") || TABS.BASIC,
  setSelectedTab(mode) {
    localStorage.setItem("selectedTab", mode);
    this.selectedTab = mode;
  },
};

export const DistributorProfileContext = createContext(DistributorProfileInit);

export const useDistributorProfileContext = () =>
  useContext(DistributorProfileContext);
