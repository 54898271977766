import { useContext, createContext } from "react";
import { MODE, TABS } from "../../../constants/common";
/*istanbul ignore next */
export const BundleInit = {
  userId: 0,
  selectedTab: TABS.BUNDLE_INFORMATION,
  subSelectedTab: TABS.BUNDLE_INFORMATION,
  bundleId: 0,
  bundleMode: MODE.LIST,
  sourceBundleId: 0,
  setSelectedTab(mode) {
    this.selectedTab = mode;
  },
  setSubSelectedTab(mode) {
    this.subSelectedTab = mode;
  },
  setBundleId(bundleId) {
    this.bundleId = bundleId;
  },
  setBundleMode(mode) {
    this.bundleMode = mode;
  },
  setUserId(userId) {
    this.userId = userId;
  },
  copyBundle(val) {
    this.copy = true;
    this.sourceBundleId = val.contentId;
  },
  resetCopyBundle() {
    this.copy = false;
    this.sourceBundleId = 0;
  },
};

export const BundleContext = createContext(BundleInit);

export const useBundleContext = () => useContext(BundleContext);
