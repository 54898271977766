import { DistributorService } from "./DistributorService";
import { EnterpriseGroupUserService } from "./EnterpiseGroupUserService";
import { SubscriberService } from "./SubscriberService";

export class UserServiceCeator {
  factoryMethod = () => {
    return null;
  };
}

export class DistributorServiceCeator extends UserServiceCeator {
  factoryMethod = () => {
    return new DistributorService();
  };
}

export class EnterpriseGroupUserServiceCeator extends UserServiceCeator {
  factoryMethod = () => {
    return new EnterpriseGroupUserService();
  };
}

export class SubscriberServiceCeator extends UserServiceCeator {
  factoryMethod = () => {
    return new SubscriberService();
  };
}
