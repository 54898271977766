import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Confirmation.css";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Confirmation(props) {
  const { showReason, customDescriptionStyle } = props;
  const [reason, setReason] = useState("");

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" sx={{ mb: 2 }}>
          {props.title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mb: 2, mt: 2, ...customDescriptionStyle }}
        >
          {props.description}
        </Typography>

        {showReason && (
          <div className="reason-div mt-3">
            <TextField
              multiline
              value={reason}
              rows={3}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              data-testid="reason_textbox"
              style={{ width: "100%", padding: 1 }}
            />
          </div>
        )}

        <div className="action-btn-div mt-3">
          {!props.cancelButtonNotRequired && (
            <Button
              className="action-button"
              variant="outlined"
              onClick={(e) => {
                setReason("");
                props.onCancel(e);
              }}
              data-testid="cancel-action"
            >
              {props.cancelButtonLabel}
            </Button>
          )}
          {!props.confirmButtonNotRequired && (
            <Button
              className={
                !props.cancelButtonNotRequired
                  ? "action-button ml-4"
                  : "action-button"
              }
              variant="contained"
              onClick={(e) => {
                setReason("");
                props.onConfirm(e, reason);
              }}
              data-testid="confirm-action"
              disabled={showReason && reason === ""}
            >
              {props.confirmButtonLabel}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default Confirmation;
