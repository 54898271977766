export const REGULAR_EXPRESSION = {
  PHONE_NUMBER: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  ZIP_CODE: /^[0-9]+$/,
  ZIP_CODE_USA: /^\d{5}(-\d{4})?$/,
  ZIP_CODE_CANADA: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/,
  NUMBER: /^[0-9]+$/,
  URL: /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
  NAME: /^[A-Za-z'-]*$/,
  ALPHANUMERIC: /^[A-Za-z0-9]*$/,
  ALPHANUMERICREGEX: /^[a-zA-Z0-9]*$/,
};
