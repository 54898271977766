import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

function BusinessPhoneNumberTextbox(props) {
  const numRef = useRef();
  const [phoneData, setPhoneData] = useState({ number: "", ext: "" });

  useEffect(() => {
    const numWithExt = props.value?.split(",");
    if (numWithExt?.length) {
      setPhoneData({ number: numWithExt[0], ext: numWithExt[1] });
    } else {
      setPhoneData({ number: numWithExt, ext: "" });
    }
  }, [props.value]);
  /*istanbul ignore next */
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    if (input.match(/^[A-Za-z]+$/)) {
      return;
    }
    const formattedInput = formatPhoneNumber(input);
    setPhoneData({ ...phoneData, number: formattedInput });
    const numberWE = phoneData.ext
      ? `${formattedInput},${phoneData.ext}`
      : formattedInput;
    props.onChange(numberWE);
  };
  /*istanbul ignore next */
  const handleExtChange = (e) => {
    const input = e.target.value;
    if (!/^\d{0,6}$/.test(input)) {
      const numericInput = input.replace(/\D/g, "").substring(0, 6);
      setPhoneData({ ...phoneData, ext: numericInput });
      const numberWE = phoneData.number
        ? `${phoneData.number},${numericInput}`
        : "";
      props.onChange(numberWE);
    } else {
      setPhoneData({ ...phoneData, ext: input });
      const numberWE = phoneData.number ? `${phoneData.number},${input}` : "";
      props.onChange(numberWE);
    }
  };

  const formatPhoneNumber = (unSanitizedInput) => {
    if (unSanitizedInput) {
      const input = unSanitizedInput.replace(/\D/g, "");
      const match = input.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      if (!match[2]) {
        return match[1];
      }
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    } else {
      return unSanitizedInput;
    }
  };

  return (
    <div className="businessPhone">
      <TextField
        id={props.id}
        className="form-label form-control cus-phn-border"
        type="tel"
        ref={numRef}
        name={props.name}
        placeholder={props.placeholder}
        onChange={handlePhoneChange}
        value={formatPhoneNumber(phoneData.number)}
        variant="standard"
        disabled={props.disabled}
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
          className: "add",
          startAdornment: (
            <InputAdornment position="start">
              {props.phoneCode?.length && props.phoneCode[0]}
            </InputAdornment>
          ),
        }}
      />
      <input
        className={`${props.className} ext`}
        type="tel"
        value={phoneData.ext}
        minLength={2}
        maxLength={6}
        name={props.name}
        placeholder="Ext"
        disabled={props.disabled}
        autoComplete="off"
        onChange={handleExtChange}
      />
    </div>
  );
}

export default BusinessPhoneNumberTextbox;
