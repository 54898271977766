import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./searchabledropdown.css";
import uniqBy from "lodash/uniqBy";

export default function SearchableDropDown(props) {
  const { isEditMode } = props;
  const index = props.index ? props.index : 0;
  return (
    <Autocomplete
      clearIcon={props.clearIcon}
      disablePortal={!props.multiple}
      multiple={props.multiple}
      options={props.options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      value={!props.multiple && props.value ? props.value[0] : props.value}
      onChange={(event, newValue) => {
        let values =
          props.multiple && props.value
            ? uniqBy(newValue, (obj) => obj.id)
            : newValue;
        props.setData(values, index);
      }}
      disabled={props.disabled}
      filterSelectedOptions
      id={props.id}
      className={
        !isEditMode
          ? `disable-searchable-dropdown  ${props.className} ${props?.classNameValue}`
          : `searchable-dropdown ${props.className}`
      }
      data-testid={props.id}
      sx={{ width: props.width }}
      renderInput={(params) => (
        <TextField {...params} placeholder={props.placeholder} />
      )}
    />
  );
}
