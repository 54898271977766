import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../common/Modal/Modal.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ApiServices from "../../services/ApiServices";
import {
  ADD_ROLE,
  ROLE_DETAILS,
  UPDATE_ROLE,
} from "../../constants/api-constants";
import { useFormik } from "formik";
import { UserSignupSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";
import Confirmation from "../../common/Confirmation/Confirmation";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { useHistory } from "react-router";
import { usePageMode } from "../../hooks/usePageMode";
import { exitConfirmationMessage } from "../Distributors/constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import { useAuth } from "../../hooks/useAuth";
import { PermissionEnum } from "../../constants/permissionService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

/*istanbul ignore next */
const AddEditRoleModal = (props) => {
  const [roleDetails, setRoleDetails] = useState({});
  const page = usePageMode();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [type, setType] = React.useState("Select Type");
  const [confirmation, setConfirmation] = React.useState(false);
  const handleConfirmationClose = () => setConfirmation(false);
  const [isEdit, setIsEdtit] = useState(false);
  const [featureListDetails1, setFeatureListDetails1] = useState(
    props.featureListDetails1
  );
  const [searchedText, setSearchedText] = useState("");
  const [selectedArrayNav, setSelectedArrayNav] = useState([]);
  const [selectedArrayMod, setSelectedArrayMod] = useState([]);
  const [valueTabs, setValueTabs] = React.useState(0);
  const [navRows, setNavRows] = useState([]);
  const [modRows, setModRows] = useState([]);
  const [autoChecked, setAutoChecked] = useState({});
  const [autoCheckedMod, setAutoCheckedMod] = useState({});
  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  const auth = useAuth();
  const { showLoader } = useLoading();
  const formik = useFormik({
    initialValues: {
      name: "",
      isInternalUserRole: null,
      roleTypes: "",
      dirty: false,
      selectedFeatures: [
        {
          featureId: null,
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: UserSignupSchema,
  });

  useEffect(() => {
    page.setDirty(formik.values.dirty);
  }, [formik.values.dirty]);

  useEffect(() => {
    if (selectedArrayMod) {
      setSelectedArrayMod((prevSelected) => {
        let selected_pModules = navRows?.map((x) => {
          if (selectedArrayNav?.includes(x.featureId)) {
            return x.moduleId;
          } else {
            return null;
          }
        });
        return prevSelected.filter((z) =>
          selected_pModules.includes(
            modRows?.find((y) => y.featureId === z)?.pModuleId
          )
        );
      });
    }
  }, [selectedArrayNav, modRows, navRows]);

  useEffect(() => {
    if (handleClose) {
      setSearchedText();
    }
  }, [handleClose]);

  const roleOptions = {
    [SystemUsersEnum.SUPERADMIN]: [
      { value: 2, label: "Admin" },
      { value: 1, label: "Super Admin" },
      { value: 3, label: "Distributor" },
      { value: 4, label: "Enterprise Group" },
    ],
    [SystemUsersEnum.ADMIN]: [
      { value: 2, label: "Admin" },
      { value: 3, label: "Distributor" },
      { value: 4, label: "Enterprise Group" },
    ],
    [SystemUsersEnum.DISTRIBUTOR]: [{ value: 3, label: "Distributor" }],
    [SystemUsersEnum.ENTERPRISE]: [{ value: 4, label: "Enterprise Group" }],
  };

  const subRoleOptions = {
    [SystemUsersEnum.SUPERADMIN]: [
      { value: 5, label: "Distributor - User/Contacts" },
      { value: 6, label: "Enterprise Group - User/Contacts" },
    ],
    [SystemUsersEnum.ADMIN]: [
      { value: 5, label: "Distributor - User/Contacts" },
      { value: 6, label: "Enterprise Group - User/Contacts" },
    ],
    [SystemUsersEnum.DISTRIBUTOR]: [
      { value: 5, label: "Distributor - User/Contacts" },
    ],
    [SystemUsersEnum.ENTERPRISE]: [
      { value: 6, label: "Enterprise Group - User/Contacts" },
    ],
  };

  const userRole = auth.getRole();
  const options = roleOptions[userRole] || [];

  const usersubRole = auth.getRole();
  const suboptions = subRoleOptions[usersubRole] || [];

  const isValid = () => {
    return (
      formik.values.name &&
      !formik.errors.name &&
      (formik.values.roleTypes == 1 ||
        formik.values.roleTypes == 2 ||
        formik.values.roleTypes == 3 ||
        formik.values.roleTypes == 4 ||
        formik.values.roleTypes == 5 ||
        formik.values.roleTypes == 6) &&
      !formik.errors.roleTypes &&
      (selectedArrayNav?.length > 0 || selectedArrayMod?.length > 0) &&
      (type === "Main Role" || type === "Sub Role")
    );
  };

  useEffect(() => {
    setFeatureListDetails1(props.featureListDetails1);
    setNavRows(props.featureListDetails?.filter((res) => res.pModuleId === 0));
    setModRows(props.featureListDetails?.filter((res) => res.pModuleId !== 0));
    setType("Select Type");
  }, [props.featureListDetails]);

  useEffect(() => {
    setNavRows(
      props.featureListDetails?.filter(
        (res) =>
          res.pModuleId === 0 &&
          (type !== "Sub Role" || res.moduleName !== "Setup")
      )
    );
    let setup_nav = props.featureListDetails?.find(
      (res) => res.moduleName === "Setup"
    );
    setSelectedArrayNav((previousValues) => {
      return previousValues.filter(
        (x) => type !== "Sub Role" || x !== setup_nav?.featureId
      );
    });
    setFeatureListDetails1(
      props.featureListDetails?.filter(
        (res) =>
          type !== "Sub Role" ||
          (res.moduleName !== "Setup" && res.pModuleId !== setup_nav?.moduleId)
      )
    );
    setModRows(
      props.featureListDetails?.filter(
        (res) =>
          res.pModuleId !== 0 &&
          (type !== "Sub Role" || res.pModuleId !== setup_nav?.moduleId)
      )
    );
  }, [type]);

  /*istanbul ignore next */
  const searchHandler = (searchText, searchList) => {
    const filteredData = searchList.filter((featureDetail) =>
      featureDetail.moduleName.toUpperCase().includes(searchText.toUpperCase())
    );
    return filteredData;
  };

  const handleCancelClick = () => {
    if (formik.values.dirty) {
      setConfirmation(true);
    } else {
      setValueTabs(0);
      handleClose();
    }
  };

  const createRole = async (e) => {
    e.preventDefault();
    try {
      let navRoles = selectedArrayNav.map((data) => {
        return {
          featureId: data,
        };
      });
      let modRoles = selectedArrayMod.map((data) => {
        return {
          featureId: data,
        };
      });

      const response = await ApiServices.httpPost(ADD_ROLE, {
        name: formik.values.name,
        isInternalUserRole: type === "Main Role" ? false : true,
        roleTypes: [
          {
            userType: Number(formik.values.roleTypes),
          },
        ],
        selectedFeatures: [...navRoles, ...modRoles],
      });
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        props.adminDetails();
        handleClose();
        formik.resetForm();
        setSelectedArrayNav([]);
        setSelectedArrayMod([]);
        setSearchedText("");
        setType("Select Type");
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (err) {}
  };

  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push("/role-management");
        setOpen(false);
      }
    };
  }, [history]);
  /*istanbul ignore next */
  const editRoleDetails = async (e) => {
    e.preventDefault();
    let navRoles = selectedArrayNav.map((data) => {
      return {
        featureId: data,
      };
    });
    let modRoles = selectedArrayMod.map((data) => {
      return {
        featureId: data,
      };
    });
    const response = await ApiServices.httpPut(UPDATE_ROLE, {
      roleId: props.data.roleId,
      name: formik.values.name,
      isInternalUserRole: type === "Main Role" ? false : true,
      roleTypes: [
        {
          status: 1,
          userType: Number(formik.values.roleTypes),
        },
      ],
      selectedFeatures: [...navRoles, ...modRoles],
    });
    if (response.responseStatus.isSuccess) {
      toast.success(response.responseStatus.description);
      handleClose();
      props.adminDetails();
    } else {
      toast.error(response.responseStatus.error);
    }
  };
  const updateSelection = (prevSelected, val) => {
    if (prevSelected.includes(val)) {
      return prevSelected.filter((data) => data !== val);
    } else {
      return [...prevSelected, val];
    }
  };

  /*istanbul ignore next */
  const checkHandler = (e, res) => {
    let val = res.featureId;
    let featureName = res.featureName;
    let moduleId = res.moduleId;
    e.preventDefault();
    formik.setFieldValue("dirty", true);

    setSelectedArrayNav((prevSelected) => {
      let updatedSelection = updateSelection(prevSelected, val);

      if (featureName === "Create" || featureName === "Update") {
        if (updatedSelection.includes(val)) {
          handleAutoSelect(
            navRows,
            moduleId,
            featureName,
            updatedSelection,
            setAutoChecked
          );
        } else {
          removeAutoSelect(navRows, moduleId, updatedSelection, setAutoChecked);
        }
      } else if (featureName === "List") {
        updatedSelection = updateSelection(prevSelected, val);
      }
      return updatedSelection;
    });
  };

  const checkmodHandler = (e, res) => {
    let moduleId = res.moduleId;
    let featureName = res.featureName;
    let val = res.featureId;
    e.preventDefault();
    formik.setFieldValue("dirty", true);

    setSelectedArrayMod((prevSelected) => {
      let updatedSelection = updateSelection(prevSelected, val);
      if (featureName === "Create" || featureName === "Update") {
        if (updatedSelection.includes(val)) {
          handleAutoSelectMod(
            modRows,
            moduleId,
            featureName,
            updatedSelection,
            setAutoCheckedMod
          );
        } else {
          removeAutoSelectMod(
            modRows,
            moduleId,
            updatedSelection,
            setAutoCheckedMod
          );
        }
      } else if (featureName === "List") {
        updatedSelection = updateSelection(prevSelected, val);
      }
      return updatedSelection;
    });
  };
  /*istanbul ignore next */
  const selectAllHandler = (e, from) => {
    e.preventDefault();
    formik.setFieldValue("dirty", true);

    if (from === "navigation") {
      handleNavigationSelection(e);
    } else {
      handleModuleSelection(e);
    }
  };

  const handleNavigationSelection = (e) => {
    if (!e.target.checked) {
      setSelectedArrayNav([]);
    } else {
      const selectedNav = navRows?.map((data) => data.featureId) || [];
      setSelectedArrayNav(selectedNav);
    }
  };

  const handleModuleSelection = (e) => {
    if (!e.target.checked) {
      setSelectedArrayMod([]);
    } else {
      const selectedMod =
        modRows?.map((data) => {
          const isNavSelected = isNavigationSelectedForModule(data);
          return isNavSelected ? data.featureId : null;
        }) || [];
      setSelectedArrayMod(selectedMod);
    }
  };

  const isNavigationSelectedForModule = (data) => {
    return navRows
      ?.map((x) =>
        selectedArrayNav?.includes(x.featureId) ? x.moduleId : null
      )
      ?.includes(
        modRows?.find((y) => y.featureId === data.featureId)?.pModuleId
      );
  };

  /*istanbul ignore next */
  const searchTextHandler = (e) => {
    setSearchedText(e.target.value);
    let arr = searchHandler(e.target.value, featureListDetails1);
    setNavRows(arr.filter((res) => res.pModuleId === 0));
    setModRows(arr.filter((res) => res.pModuleId !== 0));
  };
  /*istanbul ignore next */
  const getRoleId = async () => {
    showLoader(true);
    const roleId = await ApiServices.httpGet(
      `${ROLE_DETAILS}${props.data.roleId}`
    );
    setRoleDetails(roleId);
    showLoader(false);
    roleId.result.isInternalUserRole
      ? setType("Sub Role")
      : setType("Main Role");

    selectDataHandler(roleId.result);
  };
  /*istanbul ignore next */
  const selectDataHandler = (roleData) => {
    const selectedNavs =
      roleData.selectedFeatures?.filter((item) => item.pModuleId === 0) || [];
    setSelectedArrayNav(selectedNavs.map((item) => item.featureId) || []);
    const selectedMods =
      roleData.selectedFeatures?.filter((item) => item.pModuleId !== 0) || [];
    setSelectedArrayMod(selectedMods.map((item) => item.featureId) || []);

    const selectNavModuleIds =
      selectedNavs
        .filter((x) => ["Create", "Update"].includes(x.featureName))
        ?.map((item) => item.moduleId) || [];
    const selectModModuleIds =
      selectedMods
        .filter((x) => ["Create", "Update"].includes(x.featureName))
        ?.map((item) => item.moduleId) || [];

    navRows.forEach((res) => {
      if (
        selectNavModuleIds.includes(res.moduleId) &&
        res.featureName === "List"
      ) {
        setAutoChecked((prev) => ({
          ...prev,
          [res.featureId]: true,
        }));
      }
    });
    modRows.forEach((res) => {
      if (
        selectModModuleIds.includes(res.moduleId) &&
        res.featureName === "List"
      ) {
        setAutoCheckedMod((prev) => ({
          ...prev,
          [res.featureId]: true,
        }));
      }
    });
    formik.setValues({
      ...formik.values,
      name: roleData.name,
      roleTypes: roleData.roleTypes[0].userType,
    });
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      {props.isEditMode &&
        props.permissions &&
        props.permissions.includes(PermissionEnum.UPDATE) &&
        props.data.isEditable && (
          <img
            width={20}
            src="/images/edit_enable.png"
            alt="Edit"
            title="Edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleOpen();
              getRoleId();
              setIsEdtit(true);
            }}
            data-testid={`EditIcon${props.testid}`}
          />
        )}
      {props.isEditMode && (
        <img
          src="/images/view_enable.png"
          width={20}
          alt="View"
          title="View"
          style={{ cursor: "pointer", marginLeft: 10 }}
          onClick={() => {
            handleOpen();
            getRoleId();
            setIsEdtit(false);
          }}
          data-testid={`ViewIcon${props.testid}`}
        />
      )}

      {props.isAdd && (
        <div className="add-welcome-widgets-breadcrumb-admin">
          <Tooltip placement="bottom" title="Add New Role">
            <AddOutlinedIcon
              onClick={() => {
                handleOpen();
                setValueTabs(0);
                setIsEdtit(true);
              }}
              name="add-icon"
              role="img"
            />
          </Tooltip>
        </div>
      )}

      <Modal
        open={open}
        onClose={() => {
          handleCancelClick();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roles-popup-modal"
      >
        <Box sx={style} className="custom-modal">
          <form>
            <div className="modal-header">
              <div
                className="modal-header-left"
                data-testid="internal-user-modal"
              >
                {!props.isAdd && isEdit && "Edit Role"}
                {props.isAdd && "Add New Role"}
                {!props.isAdd && !isEdit && "View Role"}
              </div>
              <div
                className="modal-header-right"
                onClick={() => {
                  handleCancelClick();
                  formik.resetForm();
                  setSelectedArrayNav([]);
                  setSelectedArrayMod([]);
                  setSearchedText("");
                  setType("Select Type");
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseOutlinedIcon />
              </div>
            </div>
            <div className="modal-body roles-popup-modal-body">
              <Grid container spacing={2} className="fix-header-roles-popup">
                <Grid item xs={4}>
                  <label htmlFor="name">Role Name</label>
                  <MandatoryField />
                  <input
                    name="name"
                    id="name"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Role Name"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.name}
                    disabled={isEdit ? false : true}
                  />
                  {formik.values.name.length < 1 ? (
                    <InlineMessage error="" />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="subscription">Type</label>
                  <MandatoryField />
                  <select
                    className="form-control form-select"
                    id="subscription"
                    data-testid="subscription"
                    name="subscription"
                    onChange={(e) => {
                      setType(e.target.value);
                      formik.setValues({
                        ...formik.values,
                        roleTypes: "",
                        dirty: true,
                      });
                    }}
                    value={type}
                    disabled={isEdit ? false : true}
                  >
                    <option selected disabled>
                      Select Type
                    </option>
                    <option value="Main Role">Main Role</option>
                    <option value="Sub Role">Sub Role</option>
                  </select>
                  {type === "Select Type" && type !== "Sub Role" ? (
                    <InlineMessage error="" />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="roleTypes">Roles</label>
                  <MandatoryField />

                  {type === "Select Type" && (
                    <>
                      <select
                        data-testid="roleTypes"
                        className="form-control form-select"
                        id="roleTypes"
                        name="roleTypes"
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          formik.handleChange(e);
                        }}
                        value={formik.values.roleTypes}
                        disabled={isEdit ? false : true}
                      >
                        <option selected>Select Roles</option>
                        <option disabled>Please Select Type First</option>
                      </select>
                    </>
                  )}
                  {type === "Main Role" && (
                    <>
                      <select
                        data-testid="roleTypes"
                        className="form-control form-select"
                        id="roleTypes"
                        name="roleTypes"
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          formik.handleChange(e);
                        }}
                        value={formik.values.roleTypes}
                        disabled={isEdit ? false : true}
                      >
                        <option selected>Select Roles</option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {type === "Sub Role" && (
                    <>
                      <select
                        data-testid="roleTypes"
                        className="form-control form-select"
                        id="roleTypes"
                        name="roleTypes"
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          formik.handleChange(e);
                        }}
                        value={formik.values.roleTypes}
                        disabled={isEdit ? false : true}
                      >
                        <option selected>Select Roles</option>
                        {suboptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {!formik.values.roleTypes && <InlineMessage error="" />}
                </Grid>
                <Grid item xs={4} style={{ marginTop: 25 }}>
                  Permissions
                  <MandatoryField />
                  {(selectedArrayNav?.length === 0 ||
                    selectedArrayMod?.length === 0) && (
                    <InlineMessage error="" />
                  )}
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <TextField
                    className="table-search-box"
                    onChange={searchTextHandler}
                    placeholder="Search Everything..."
                    data-testid="search-module"
                    name="roles"
                    type="text"
                    value={searchedText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="search-icon-div">
                            <img
                              src="/images/search-icon.png"
                              alt="search icon"
                              name="search-icon"
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="tabs-panel-roles-popup" sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "#e0e0e0" }}>
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs"
                  >
                    <Tab
                      label="Navigation"
                      data-testid="navigation"
                      className="tab-heading-text"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Modules"
                      data-testid="modules"
                      className="tab-heading-text"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <Grid
                  container
                  spacing={2}
                  className="fix-header-roles-popup-table-outer new-roles-table-outer"
                >
                  <Grid item xs={12}>
                    <CustomRow
                      valueTabs={valueTabs}
                      selectAllHandler={selectAllHandler}
                      selectedArrayNav={selectedArrayNav}
                      navRows={navRows}
                      isEdit={isEdit}
                      checkHandler={checkHandler}
                      checkmodHandler={checkmodHandler}
                      selectedArrayMod={selectedArrayMod}
                      modRows={modRows}
                      autoChecked={autoChecked}
                      autoCheckedMod={autoCheckedMod}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="modal-footer">
              <div className="modal-switch">
                <div className="hide-switch"></div>
              </div>
              <div>
                <Confirmation
                  open={confirmation}
                  handleClose={handleConfirmationClose}
                  title={`Confirmation`}
                  description={exitConfirmationMessage}
                  cancelButtonLabel="Cancel"
                  confirmButtonLabel="Exit"
                  onConfirm={() => {
                    handleClose();
                    handleConfirmationClose();
                    formik.resetForm();
                    setSelectedArrayNav([]);
                    setSelectedArrayMod([]);
                    setSearchedText("");
                    setType("Select Type");
                  }}
                  onCancel={() => {
                    handleConfirmationClose();
                  }}
                />

                {props.isAdd && (
                  <Button
                    variant="outlined"
                    className="action-button mr-4"
                    data-testid="close-internal-modal"
                    onClick={() => {
                      formik.dirty ||
                      type === "Sub Role" ||
                      type === "Main Role" ||
                      selectedArrayNav.length > 0 ||
                      selectedArrayMod.length > 0
                        ? handleCancelClick()
                        : handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {!props.isAdd && !isEdit && (
                  <Button
                    variant="outlined"
                    className="action-button mr-4"
                    data-testid="close-internal-modal"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {!props.isAdd && isEdit && (
                  <Button
                    variant="outlined"
                    className="action-button mr-4"
                    data-testid="close-internal-modal"
                    onClick={() => {
                      formik.values.name !== roleDetails.name ||
                      selectedArrayNav?.length !==
                        roleDetails?.selectedFeatures?.length ||
                      selectedArrayMod?.length !==
                        roleDetails?.selectedFeatures?.length ||
                      formik.values.roleTypes !==
                        roleDetails.roleTypes[0].userType
                        ? handleCancelClick()
                        : handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {!props.isEditMode && (
                  <Button
                    data-testid="add-edit-internal-btn"
                    type="submit"
                    variant="contained"
                    className="action-button"
                    disabled={!isValid() || !isEdit}
                    onClick={createRole}
                  >
                    Create
                  </Button>
                )}

                {!props.isAdd && isEdit && (
                  <Button
                    data-testid="add-edit-internal-btn"
                    type="submit"
                    variant="contained"
                    className="action-button"
                    disabled={!isValid()}
                    onClick={editRoleDetails}
                  >
                    Update
                  </Button>
                )}

                {!props.isAdd && !isEdit && (
                  <Button
                    data-testid="add-edit-internal-btn"
                    type="submit"
                    variant="contained"
                    className="action-button"
                    disabled={true}
                    onClick={editRoleDetails}
                  >
                    Update
                  </Button>
                )}
              </div>
            </div>
          </form>
          ;
        </Box>
      </Modal>
    </>
  );
};

export default AddEditRoleModal;

/*istanbul ignore next */
function removeAutoSelectMod(
  modRows,
  moduleId,
  updatedSelection,
  setAutoCheckedMod
) {
  modRows.forEach((res) => {
    if (res.moduleId === moduleId && res.featureName === "List") {
      let filterData = modRows.filter(
        (x) =>
          x.moduleId === moduleId &&
          ["Create", "Update"].includes(x.featureName)
      );
      if (
        !updatedSelection.some((x) => filterData.find((y) => y.featureId === x))
      ) {
        setAutoCheckedMod((prev) => ({
          ...prev,
          [res.featureId]: false,
        }));
      }
    }
  });
}

/*istanbul ignore next */
function handleAutoSelectMod(
  modRows,
  moduleId,
  featureName,
  updatedSelection,
  setAutoCheckedMod
) {
  modRows.forEach((res) => {
    if (res.moduleId === moduleId) {
      if (
        (featureName === "Create" &&
          (res.featureName === "List" || res.featureName === "Update")) ||
        (featureName === "Update" && res.featureName === "List")
      ) {
        if (!updatedSelection.includes(res.featureId)) {
          updatedSelection.push(res.featureId);
        }
        setAutoCheckedMod((prev) => ({
          ...prev,
          [res.featureId]: true,
        }));
      }
    }
  });
}

/*istanbul ignore next */
function removeAutoSelect(navRows, moduleId, updatedSelection, setAutoChecked) {
  navRows.forEach((res) => {
    if (res.moduleId === moduleId && res.featureName === "List") {
      let filterData = navRows.filter(
        (x) =>
          x.moduleId === moduleId &&
          ["Create", "Update"].includes(x.featureName)
      );
      if (
        !updatedSelection.some((x) => filterData.find((y) => y.featureId === x))
      ) {
        setAutoChecked((prev) => ({
          ...prev,
          [res.featureId]: false,
        }));
      }
    }
  });
}

/*istanbul ignore next */
function handleAutoSelect(
  navRows,
  moduleId,
  featureName,
  updatedSelection,
  setAutoChecked
) {
  navRows.forEach((res) => {
    if (res.moduleId === moduleId) {
      if (
        (featureName === "Create" &&
          (res.featureName === "List" || res.featureName === "Update")) ||
        (featureName === "Update" && res.featureName === "List")
      ) {
        if (!updatedSelection.includes(res.featureId)) {
          updatedSelection.push(res.featureId);
        }
        setAutoChecked((prev) => ({
          ...prev,
          [res.featureId]: true,
        }));
      }
    }
  });
}

/*istanbul ignore next */
function CustomRow(props) {
  const {
    valueTabs,
    selectAllHandler,
    selectedArrayNav,
    navRows,
    isEdit,
    checkHandler,
    selectedArrayMod,
    modRows,
    checkmodHandler,
    autoChecked,
    autoCheckedMod,
  } = props;
  return (
    <>
      <CustomTabPanel value={valueTabs} index={0}>
        <TableContainer component={Paper} className="roles-popup-table-outer">
          <Table aria-label="simple table" className="role-list-table ">
            <NavigationRows
              selectAllHandler={selectAllHandler}
              selectedArrayNav={selectedArrayNav}
              navRows={navRows}
              isEdit={isEdit}
              checkHandler={checkHandler}
              checkmodHandler={checkmodHandler}
              autoChecked={autoChecked}
            />
          </Table>
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={valueTabs} index={1}>
        <TableContainer component={Paper} className="roles-popup-table-outer">
          <Table aria-label="simple table" className="role-list-table ">
            <ModuleRows
              selectAllHandler={selectAllHandler}
              selectedArrayMod={selectedArrayMod}
              modRows={modRows}
              isEdit={isEdit}
              checkHandler={checkHandler}
              checkmodHandler={checkmodHandler}
              autoCheckedMod={autoCheckedMod}
              navRows={navRows}
              selectedArrayNav={selectedArrayNav}
            />
          </Table>
        </TableContainer>
      </CustomTabPanel>
    </>
  );
}

/*istanbul ignore next */
function CustomTabPanel(prop) {
  const { children, value, index, ...other } = prop;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

/*istanbul ignore next */
function ModuleRows(props) {
  const {
    selectAllHandler,
    selectedArrayMod,
    modRows,
    isEdit,
    checkmodHandler,
    autoCheckedMod,
    navRows,
    selectedArrayNav,
  } = props;

  return (
    <>
      <TableHead>
        <TableRow className="roles-popup-table-head">
          <TableCell>
            <Checkbox
              className="role-management-checkbox"
              data-testid="permissions-checkbox-modules"
              onChange={(e) => {
                selectAllHandler(e, "modules");
              }}
              checked={
                selectedArrayMod?.length === modRows?.length ? true : false
              }
              disableRipple
              disabled={
                !isEdit || !selectedArrayNav || !selectedArrayNav.length > 0
              }
            />
          </TableCell>
          <TableCell align="left">Module</TableCell>
          <TableCell align="left">Features</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {modRows?.map((res) => {
          const isDisabledMod =
            !isEdit ||
            (autoCheckedMod[res.featureId] && res.featureName === "List") ||
            !navRows
              ?.map((x) => {
                if (selectedArrayNav?.includes(x.featureId)) {
                  return x.moduleId;
                } else {
                  return null;
                }
              })
              ?.includes(res.pModuleId);
          return (
            <TableRow key={res.featureId}>
              <TableCell>
                <Checkbox
                  className={`role-management-checkbox ${
                    isDisabledMod ? "checkbox-disabled" : ""
                  }`}
                  data-testid={`select-perm-${res.featureId}`}
                  onChange={(e) => checkmodHandler(e, res)}
                  checked={
                    selectedArrayMod.indexOf(res.featureId) > -1 ? true : false
                  }
                  disabled={isDisabledMod}
                  disableRipple
                />
              </TableCell>
              <TableCell align="left">{res.moduleName}</TableCell>
              <TableCell align="left">{res.featureName}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
}

/*istanbul ignore next */
function NavigationRows(props) {
  const {
    selectAllHandler,
    selectedArrayNav,
    navRows,
    isEdit,
    checkHandler,
    autoChecked,
  } = props;

  return (
    <>
      <TableHead>
        <TableRow className="roles-popup-table-head">
          <TableCell>
            <Checkbox
              className="role-management-checkbox"
              data-testid="permissions-checkbox-navigation"
              onChange={(e) => {
                selectAllHandler(e, "navigation");
              }}
              checked={
                selectedArrayNav?.length === navRows?.length ? true : false
              }
              disableRipple
              disabled={isEdit ? false : true}
            />
          </TableCell>
          <TableCell align="left">Module</TableCell>
          <TableCell align="left">Features</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {navRows?.map((res) => {
          const isDisabledNav =
            !isEdit ||
            (autoChecked[res.featureId] &&
              res.featureName === "List" &&
              selectedArrayNav.includes(res.featureId) &&
              selectedArrayNav.some(
                (id) =>
                  id !== res.featureId ||
                  (["Create", "Update"].includes(res.featureName) &&
                    res.featureName === "List" &&
                    selectedArrayNav.includes(res.featureId))
              ));
          return (
            <TableRow key={res.featureId}>
              <TableCell>
                <Checkbox
                  className={`role-management-checkbox ${
                    isDisabledNav ? "checkbox-disabled" : ""
                  }`}
                  data-testid={`select-perm-${res.featureId}`}
                  onChange={(e) => checkHandler(e, res)}
                  checked={selectedArrayNav.includes(res.featureId)}
                  disabled={isDisabledNav}
                  disableRipple
                />
              </TableCell>
              <TableCell align="left">{res.moduleName}</TableCell>
              <TableCell align="left">{res.featureName}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
}
