import { InputAdornment, TextField } from "@mui/material";
import React from "react";

function PhoneNumberTextbox(props) {
  return (
    <TextField
      id={props.id}
      className="form-label form-control cus-phn-border"
      type="tel"
      name={props.name}
      placeholder={props.placeholder}
      onChange={(e) => {
        let x = e.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        const OnX2 = x[3] ? "-" + x[3] : "";
        e.target.value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + OnX2;
        props.onChange(e);
      }}
      value={props.value}
      variant="standard"
      disabled={props.disabled}
      autoComplete="off"
      InputProps={{
        disableUnderline: true,
        className: "add",
        startAdornment: (
          <InputAdornment position="start">
            {props?.phoneCode?.length && props?.phoneCode[0]}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PhoneNumberTextbox;
