import { useEffect, useState } from "react";
import "./InternalUsers.css";
import ApiServices from "../../../services/ApiServices";
import { CURRENT_USER } from "../../../constants/api-constants";
import { getServiceInstance } from "../../../services/UserServices/UserService";
import { useLoading } from "../../../hooks/useLoader";
import {
  ClearSelectionData,
  SelectionFilter,
  GetAllSelectedIds,
} from "../../../utilities/SelectionFilter";
import AdminDataTable from "../../Tables/AdminDataTable/AdminDataTable";
import TableColumnModal from "../../../common/TableColumnModal/TableColumnModal";
import {
  PermissionService,
  PermissionEnum,
  getModuleId,
} from "../../../constants/permissionService";
import { toast } from "react-toastify";

const InternalUsers = (props) => {
  const { routeData } = props;
  const { showLoader } = useLoading();
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const { internalUserTypeId, userTypeId, queryVar } = routeData;
  const internalUserService = getServiceInstance(userTypeId);

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      userTypes: [internalUserTypeId],
      userStatus: [0, 1],
      AccountStatus: ["false", "true"],
      roles: [],
    },
  });

  const [userRole, setUserRole] = useState("");
  const [curentUserId, setCurentUserId] = useState(null);

  const permissions = PermissionService(getModuleId(routeData?.userTypeId));

  useEffect(() => {
    if (props.id) {
      userDetails();
      getInternalUsers();
    }
  }, [props.id]);

  useEffect(() => {
    if (props.isRefresh) {
      getInternalUsers();
      props.onSuccess(false);
    }
  }, [props.isRefresh]);

  useEffect(() => {
    setQuery((prevState) => {
      return {
        ...prevState,
        filter: props.query.filter,
        PageNumber: 1,
      };
    });
  }, [props.query]);

  useEffect(() => {
    getInternalUsers();
  }, [query]);

  const onEditSuccess = (data) => {
    if (data) {
      getInternalUsers();
    }
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const userDetails = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(CURRENT_USER);
      showLoader(false);
      setUserRole(response?.result?.user?.userTypeName);
      setCurentUserId(response?.result?.user?.userId);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSuccessToggle = (val) => {
    getInternalUsers();
  };

  const getInternalUsers = async () => {
    try {
      showLoader(true);
      props.query.filter.userTypes = [routeData.internalUserTypeId];
      const adminData = await internalUserService.getInternalUsers(
        `?${queryVar}=${props.id}&query=${JSON.stringify(query)}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("userId");
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.userId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          return ele;
        });
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
      setSelectionColumns();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        searchText: event.target.value,
      };
    });
    props.searchCallback(event.target.value);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      return {
        ...prevState,
        SortColumn: sortBy,
        SortType: sortOrder === "asc" ? "desc" : "asc",
      };
    });
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols =
      routeData.internalUserTypeId === 5
        ? selectedCols.distInternalUser
        : selectedCols.entInternalUser;

    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };

  return (
    <div className="internal-user-main">
      <AdminDataTable
        onCheckboxChange={(e, value) => {
          const { checked } = e.target;
          if (value === "all") {
            setAdminTableData((items) => {
              SelectionFilter(items, "multiple", checked);
              return items.map((data) => {
                return {
                  ...data,
                  isChecked: checked,
                };
              });
            });
          } else {
            setAdminTableData((items) => {
              return items.map((user) => {
                let userRecord = { ...user };
                if (user.userId === value) {
                  userRecord.isChecked = checked;
                  SelectionFilter(user, "single", checked);
                }
                return userRecord;
              });
            });
          }
        }}
        currentUserId={curentUserId}
        query={query}
        handleChangePage={handleChangePageNumber}
        success={onSuccessToggle}
        adminTable={adminTable}
        onEditSuccess={onEditSuccess}
        totalPages={totalPages}
        pageSize={pageSize}
        onSearch={onSearchHandler}
        onSort={sortHandler}
        onPageSizeChange={pageSizeChangeHandler}
        userRole={userRole}
        userRolesDropdown={props.userRolesDropdown}
        distributorData={props.distributorData}
        routeData={routeData}
        columns={selectedColumns}
        tableType="internalTable"
        permissions={permissions}
        PermissionEnum={PermissionEnum}
      />
      {props.showColumnPopup && (
        <TableColumnModal
          componentName={
            internalUserTypeId === 5 ? "distInternalUser" : "entInternalUser"
          }
          isOpen={props.showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onSaveTableColumnSelection={onSaveTableColumnSelection}
          selection={selectedColumns}
        />
      )}
    </div>
  );
};

export default InternalUsers;
