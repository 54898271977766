import {
  DistributorServiceCeator,
  EnterpriseGroupUserServiceCeator,
  SubscriberServiceCeator,
} from "./UserServiceCreator";

export const getServiceInstance = (userTypeId) => {
  if (userTypeId === 3) {
    return new DistributorServiceCeator().factoryMethod();
  } else if (userTypeId === 4) {
    return new EnterpriseGroupUserServiceCeator().factoryMethod();
  } else if (userTypeId === 7) {
    return new SubscriberServiceCeator().factoryMethod();
  }
};
