import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";

export default function CheckboxList(props) {
  const { id, options, selectedValues, onChange } = props;
  const [all, setAll] = useState(false);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    const selected = selectedValues?.length > 0 ? selectedValues : [];
    setChecked(selected);
    setAll(selected.length === options.length);
  }, [selectedValues, options.length]);
  const handleAllToggle = () => {
    const selectedItems = all ? [] : options.map((opt) => opt.value);
    setChecked(selectedItems);
    setAll(!all);
    onChange(selectedItems);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setAll(newChecked.length === options.length);
    onChange(newChecked);
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        padding: 0,
        margin: 0,
      }}
    >
      <ListItem key="all-option" disablePadding>
        <ListItemButton role={undefined} onClick={handleAllToggle} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={all}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": `all-option-${id}` }}
            />
          </ListItemIcon>
          <ListItemText id={`all-option-${id}`} primary="All" />
        </ListItemButton>
      </ListItem>
      {options.map((option) => {
        const labelId = `checkbox-list-${id}-${option.value}`;
        return (
          <ListItem key={labelId} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(option.value)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(option.value) !== -1}
                  tabIndex={-1}
                  //   disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  data-testid={`checkbox-list-item-${id}-${option.value}`}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={option.label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
