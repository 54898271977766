import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React, { useEffect, useState } from "react";
function DocumentViewer(props) {
  const { documents, tempId } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [tempId]);

  return (
    <div>
      {show && (
        <DocViewer
          prefetchMethod="GET"
          documents={documents}
          pluginRenderers={DocViewerRenderers}
          theme={{
            primary: "#5296d8",
            secondary: "#ffffff",
            tertiary: "#5296d899",
            textPrimary: "#ffffff",
            textSecondary: "#5296d8",
            textTertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
          style={{ height: 1000 }}
        />
      )}
    </div>
  );
}
export default DocumentViewer;
