import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function TabPanel(props) {
  const { children, key, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={className}
      hidden={value !== index}
      id={`${key}panel-${index}`}
      aria-labelledby={`{key}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
