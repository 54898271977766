import React from "react";
import { TextField } from "@mui/material";
import { ARGB_To_RGBA_Color } from "../constants";

function TextBox(props) {
  const { item, defaultValue, isRequired, fType } = props;

  const textFieldStyle = {
    position: "absolute",
    top: `${item.SetTop}px`,
    left: `${item.SetLeft}px`,
    borderRadius: 0,
  };

  const { getColor, whenEnable } = getConstants(
    defaultValue,
    fType,
    item,
    isRequired
  );
  if (item.SetTop === 0 && item.SetLeft === 0) {
    return null;
  } else {
    return (
      <TextField
        type="text"
        className="share-text-box-xfdl"
        title={item.ToolTip?.Content || ""}
        name={item.Name}
        value={defaultValue}
        id={item.Name}
        placeholder={item.TextBoxType.includes("Date") ? "MM/DD/YYYY" : ""}
        onChange={(e) => {
          props.changeHandler(e, item.Name, isRequired);
        }}
        disabled={props.isDisabled}
        variant="outlined"
        InputProps={{
          inputProps: {
            style: getStyle(item, getColor, fType, props, whenEnable),
          },
        }}
        style={textFieldStyle}
      />
    );
  }
}

export default TextBox;
function getStyle(item, getColor, fType, props, whenEnable) {
  return {
    padding: "0 0px",
    fontFamily: item.FontFamily ? item.FontFamily : "Arial",
    fontSize: item.FontSize ? item.FontSize : 8,
    fontStyle: item.FontStyle,
    fontWeight: item.FontWeight ? item.FontWeight : 600,
    color: getColor,
    textAlign: item.TextAlignment,
    width: `${item.Width}px`,
    height: fType === "NonAcord" ? item.Height : Math.min(item.Height, 20),
    // height:
    //   item.Name === "Form_EditionIdentifier_A" ? 20 : item.Height - 6,
    backgroundColor: "white",
    borderRadius: 0,
    border: props.isDisabled ? "None" : whenEnable,
  };
}

function getConstants(defaultValue, fType, item, isRequired) {
  const whenNotReq = defaultValue ? "1px solid black" : "2px solid blue";
  const checkNonAcordColor =
    fType === "NonAcord"
      ? ARGB_To_RGBA_Color(item.Foreground)
      : item.Foreground;
  const getColor = item.Foreground ? checkNonAcordColor : "black";
  const whenEnable = isRequired ? "2px solid red" : whenNotReq;
  return { getColor, whenEnable };
}
