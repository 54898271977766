import { useContext, createContext } from "react";
import { MODE, TABS } from "../../../constants/common";
/*istanbul ignore next */
export const ContentInit = {
  selectedTab: TABS.CONTENT_INFORMATION,
  subSelectedTab: TABS.CONTENT_INFORMATION,
  contentId: 0,
  contentMode: MODE.LIST,
  copy: false,
  sourceContentId: 0,
  setSelectedTab(mode) {
    this.selectedTab = mode;
  },
  setSubSelectedTab(mode) {
    this.subSelectedTab = mode;
  },
  setContentId(contentId) {
    this.contentId = contentId;
  },
  setContentMode(mode) {
    this.contentMode = mode;
  },
  copyContent(val) {
    this.copy = true;
    this.sourceContentId = val.contentId;
  },
  resetCopyContent() {
    this.copy = false;
    this.sourceContentId = 0;
  },
};

export const ContentContext = createContext(ContentInit);

export const useContentContext = () => useContext(ContentContext);
