import React from "react";
import { Checkbox } from "@mui/material";

function CheckBox(props) {
  const { item, defaultValue, isRequired } = props;
  const onNotRequired = defaultValue
    ? "xfdlcheckbox-filled"
    : "xfdlcheckbox-empty";
  const onNotReq = defaultValue ? "black" : "blue";
  return (
    <Checkbox
      type="checkbox"
      title={item.ToolTip?.Content || ""}
      name={item.Name}
      checked={defaultValue === "true" ? true : false}
      onChange={(e) => {
        props.checkBoxHandler(e, item.Name, isRequired);
      }}
      className={isRequired ? "xfdlcheckbox-req" : onNotRequired}
      disableRipple
      sx={{
        color: isRequired ? "red" : onNotReq,
        // "&.Mui-disabled": {
        //   color: "transparent", // Custom disabled color
        //   "&.Mui-checked": {
        //     color: "transparent", // Custom color when checked and disabled
        //   },
        // },
      }}
      style={{
        borderRadius: "0px",
        backgroundColor: "white",
        width: item.Width,
        height: item.Height,
        position: "absolute",
        top: `${item.SetTop}px`,
        left: `${item.SetLeft}px`,
      }}
      disabled={props.isDisabled}
    />
  );
}

export default CheckBox;
