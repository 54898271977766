import { Button } from "@mui/material";
import React from "react";
import "../Modal/Distributor/CreateDistributorModal.css";
import { PermissionEnum } from "../../constants/permissionService";

export default function FooterButtons(props) {
  return (
    <div className="modal-footer">
      <div>
        {props.isEditMode && (
          <Button
            variant="outlined"
            className="action-button mr-4"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        )}
        {props.permissions &&
          props.permissions.includes(PermissionEnum.UPDATE) &&
          !props.isEditMode && (
            <Button
              variant={!props.editPermission ? "contained" : "outlined"}
              className="action-button mr-4"
              type="submit"
              disabled={!props.editPermission}
              onClick={props.onEdit}
            >
              Edit
            </Button>
          )}
        {props.showUpdateNext && (
          <Button
            variant="contained"
            type="submit"
            className="action-button mr-4"
            onClick={props.onUpdateNext}
            disabled={props.disableUpdateNext}
          >
            Update & Next
          </Button>
        )}
        {props.showSaveNext && (
          <Button
            variant="contained"
            type="submit"
            className="action-button mr-4"
            onClick={props.onSaveNext}
            disabled={props.disableSaveNext}
          >
            Save & Next
          </Button>
        )}
        {props.showUpdateDetail ? (
          <Button
            variant="contained"
            className="action-button"
            data-testId="update-details"
            type="submit"
            onClick={props.onUpdate}
            disabled={props.disableUpdateDetail}
          >
            Update Details
          </Button>
        ) : (
          <Button
            data-testid="save-button-1"
            variant="contained"
            className="action-button"
            type="submit"
            onClick={props.onSave}
            disabled={props.disableSaveDetail}
          >
            Save Details
          </Button>
        )}
      </div>
    </div>
  );
}
