import {
  Button,
  Checkbox,
  List,
  Modal,
  ListItemButton,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../Modal/Modal.css";
import "./TableColumnModal.css";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const TableColumnModal = (props) => {
  const [colsState, setColsState] = useState(props.selection);

  const handleToggle = (fieldName) => {
    let newColumns = colsState.map((el) => {
      if (el.fieldName === fieldName) {
        if (el.isSelected) {
          el.isSelected = false;
        } else {
          el.isSelected = true;
        }
      }
      return el;
    });
    setColsState(newColumns);
  };

  const isEmpty = () => {
    let selected =
      colsState && colsState.filter((el) => el.isSelected === true);
    return selected && selected.length > 1 ? false : true; // because action is bydefault in it
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={() => {
          props.onCancelTableColumnSelection();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <div className="modal-header">
            <div className="modal-header-left">Select columns to display</div>
            <div
              data-testid="cancel-selection-cross"
              className="modal-header-right"
              onClick={() => {
                props.onCancelTableColumnSelection();
              }}
              style={{ cursor: "pointer" }}
            >
              <CloseOutlinedIcon data-testid="CloseTableColumnSelectionPopup" />
            </div>
          </div>
          <div className="modal-body change-view-body">
            <List>
              {colsState &&
                colsState.map((el) => {
                  return (
                    el.showInSelection &&
                    el.label !== "" && (
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => handleToggle(el.fieldName)}
                          dense
                        >
                          <Checkbox
                            checked={el.isSelected}
                            inputProps={{
                              "data-testid": `tab-col-${el.fieldName}`,
                            }}
                          />
                          <ListItemText primary={el.label} />
                        </ListItemButton>
                      </ListItem>
                    )
                  );
                })}
            </List>
          </div>
          <div className="modal-footer">
            <div>
              <Button
                data-testid="cancel-selection"
                variant="outlined"
                className="action-button mr-4"
                role="button"
                name="cancel-table-selection-columns"
                onClick={() => {
                  props.onCancelTableColumnSelection();
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid="apply-selection"
                variant="contained"
                className="action-button"
                disabled={isEmpty()}
                onClick={() => {
                  props.onSaveTableColumnSelection(
                    props.componentName,
                    colsState
                  );
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TableColumnModal;
