import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ContentPublishStatus } from "../../screens/Distributors/constants";
export default function ThreeDotMenu(props) {
  const { options, actionHandler } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        data-testid="three-dots"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "auto",
          },
        }}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem
              key={option.res.contentId}
              disabled={
                option.isDistributor &&
                option.res.publishStatus === ContentPublishStatus.PUBLISHED &&
                option.res.status === 0
              }
              title={
                option.disabled ? option.deactiveTitle : option.activeTitle
              }
              onClick={() => {
                actionHandler(option.label, option.res, option.res.isFavorite);
                handleClose();
              }}
            >
              <img
                src={
                  option.disabled ? option.iconPath : option.iconPathInactive
                }
                height={20}
                alt="Delete"
                disabled={
                  option.isDistributor &&
                  option.res.publishStatus === ContentPublishStatus.PUBLISHED &&
                  option.res.status === 0
                }
                data-testid={`${option.testIdText + option.res.contentId}`}
              />
              <div
                style={
                  option.disabled && option.label === "Delete"
                    ? { marginLeft: 10, color: "darkgray" }
                    : { marginLeft: 10 }
                }
              >
                {option.label === "Add to Favourite" && option.res.isFavorite
                  ? "Remove Favourite"
                  : option.label}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
