import { Box, Grid } from "@mui/material";
import React from "react";
import "./ManageModules.css";

const ManageModule = () => {
  return (
    <Grid spacing={2} container className="module-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Module Management</h5>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pr={4} pl={4} pb={0}>
          To be developed.
        </Box>
      </Grid>
    </Grid>
  );
};

export default ManageModule;
