import { InputAdornment, TextField } from "@mui/material";
import React from "react";

function CurrencyTextbox(props) {
  const { id, name, value, onChange, disabled, type } = props;

  const decimalPlaces = 2;

  const onChangeHandler = (e) => {
    const values = e.target.value.split(".");
    if (values.length === 1 && values[0].length > 10) {
      return;
    }
    if (
      (parseFloat(e.target.value) >= 0 || e.target.value === "") &&
      (values.length === 1 ||
        (values.length === 2 && values[1].length <= decimalPlaces))
    ) {
      onChange(parseFloat(e.target.value));
    }
  };

  const onBlurHandler = (e) => {
    onChange(parseFloat(e.target.value).toFixed(decimalPlaces));
  };

  return (
    <div>
      <TextField
        type={type}
        id={id}
        name={name}
        value={value}
        size="small"
        className="currency-text-box customtextwidth"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          className: "input-border",
        }}
        min={0}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
      />
    </div>
  );
}

export default CurrencyTextbox;
