import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const CustomLoginWidget = ({ onSubmit, postSubmitLoading }) => {
  const [username, setUsername] = useState(Cookies.get("username") || "");
  const [password, setPassword] = useState(Cookies.get("password") || "");
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const emailRegex = /^(?=.{1,100}$)[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!#@$%&])[a-zA-Z\d#!@$%&-]{8,25}$/;
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(username, password, keepSignedIn);
  };
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <form onSubmit={handleSubmit} class="custom-auth-content">
      <div class="custom-fieldset">
        <label htmlFor="username">
          Username
          <MandatoryField />
        </label>
        <TextField
          placeholder="Enter username"
          disabled={postSubmitLoading}
          id="username"
          value={username}
          onChange={(e) => {
            if (e.target.value.length > 100) {
              return;
            }
            setUsername(e.target.value);
          }}
          variant="outlined"
          data-testid="email"
          maxLength="100"
        />
        <InlineMessage
          error={username && !emailRegex.test(username) ? "Invalid Email" : ""}
        />
      </div>
      <div class="custom-fieldset">
        <label htmlFor="password">
          Password
          <MandatoryField />
        </label>
        <TextField
          placeholder="Enter password"
          disabled={postSubmitLoading}
          type={showPassword ? "text" : "password"}
          id="password"
          value={password}
          onChange={(e) => {
            if (e.target.value.length > 25) {
              return;
            }
            setPassword(e.target.value);
          }}
          variant="outlined"
          data-testid="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={postSubmitLoading}
                  onClick={handleShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon
                      data-testid="hidePassword"
                      fontSize="small"
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      data-testid="showPassword"
                      fontSize="small"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InlineMessage
          error={
            password && !passwordRegex.test(password) ? "Invalid Password" : ""
          }
        />
      </div>
      <div class="custom-fieldset">
        <FormControlLabel
          control={
            <Checkbox
              data-testid="rememberme"
              disabled={postSubmitLoading}
              // disableRipple="true"
              checked={keepSignedIn}
              onChange={(e) => setKeepSignedIn(e.target.checked)}
              color="primary"
            />
          }
          class="remember-me"
          label="Remember Me"
        />
      </div>
      <div class="custom-fieldset">
        <Button
          disabled={
            !username ||
            !emailRegex.test(username) ||
            !password ||
            !passwordRegex.test(password) ||
            postSubmitLoading
          }
          disableRipple="true"
          class="sign-in"
          id="sign-in-id"
          type="submit"
          variant="contained"
          color="primary"
          data-testid="login"
        >
          Sign In
        </Button>
        <div class="custom-links">
          <Link class="clink" to="/reset-password">
            Forgot password?
          </Link>
        </div>
      </div>
    </form>
  );
};

export default CustomLoginWidget;
