import {
  ADD_DISTRIBUTOR,
  INTERNAL_USERS,
  ADD_EDIT_INTERNAL_USERS,
  INTERNAL_USER_CHANGE_STATUS,
  DISTRIBUTOR_EXPORT,
  INTERNAL_USERS_EXPORT,
  CONTENT_EXPORT,
  GET_ED_USERS,
  CATALOG_EXPORT,
  BUNDLE_EXPORT,
  CATALOG_ASSOCIATED_CONTENT_EXPORT,
  BUNDLE_ASSOCIATED_CONTENT_EXPORT,
} from "../../constants/api-constants";
import ApiServices from "../ApiServices";
import { IUserService } from "./IUserService";

export class DistributorService extends IUserService {
  getUsers = async (query) => {
    const response = await ApiServices.httpGet(query);
    return response;
  };
  getUser = async (userId) => {
    const response = await ApiServices.httpGet(`${ADD_DISTRIBUTOR}/${userId}`);
    return {
      ...response.result,
      userId: response.result.userId,
      userTypeId: response.result.userTypeId,
      userTypeName: response.result.userTypeName,
      systemGeneratedId: response.result.systemGeneratedId,
      createdOn: response.result.createdOn,
      firstName: response.result.firstName,
      lastName: response.result.lastName,
      brandName: response.result.brandName,
      carrier: response.result.carrier,
      primaryEmail: response.result.email,
      secondaryEmail: response.result.secondaryEmail,
      phoneNumber: response.result.phoneNumber,
      secondaryPhoneNumber: response.result.secondaryPhoneNumber,
      businessName: response.result.businessName,
      taxID: response.result.taxID,
      fax: response.result.fax,
      mobile: response.result.mobile,
      fax1: response.result.fax1,
      mobile1: response.result.mobile1,

      distributorType: response.result.distributorTypes.map((data) => {
        return {
          label: data.name,
          value: data.distributorTypeId,
        };
      }),
      areas: response.result.areas.map((data) => {
        return {
          label: data.name,
          value: data.areaId,
        };
      }),
      carrierRepresents: response.result.carrierRepresents.map((data) => {
        return {
          label: data.name,
          value: data.carrierRepresentId,
        };
      }),
      carriers: response.result.carriers.map((data) => {
        return {
          label: data.name,
          value: data.carrierId,
        };
      }),
      industryGroups: response.result.industryGroups.map((data) => {
        return {
          label: data.name,
          value: data.industryGroupId,
        };
      }),
      technologies: response.result.technologies.map((data) => {
        return {
          label: data.name,
          value: data.technologyId,
        };
      }),
      parentUser: {
        parentUserId: response.result.parentUser.parentUserId || 0,
        // parentUserId: 150,
        permissions: response.result.parentUser?.permissions,
      },

      annualRevenue: response.result.annualRevenueId,
      annualRevenueText: response.result.annualRevenueText,
      billingContact: response.result.billingContact,
      billingContactLastName: response.result.billingContactLastName,
      businessYear: response.result.businessYear || "",
      websiteUrl: response.result.websiteUrl,
      numberOfEmployees: response.result.numberOfEmployees || "",
      numberOfOwners: response.result.numberOfOwners || "",
      producers: response.result.producers || "",
      billingPhoneNumber: response.result.billingPhoneNumber,
      billingAddressLine1: response.result.billingAddressLine1,
      billingAddressLine2: response.result.billingAddressLine2,
      zip: response.result.zipCode,
      city: response.result.city,
      countryId: response.result.countryId,
      stateId: response.result.stateId,
      id: response.result.userId,
      status: response.result.status,
      roles: response.result.roles,
      distributorSubscriptionDomains:
        response.result.distributorSubscriptionDomains,
      logoGcpPath: response.result.logoGcpPath,
      logoGcpFileUrl: response.result.logoGcpFileUrl,
      signUpApproval: response.result.signUpApproval,
    };
  };
  createUser = async (values) => {
    const payload = {
      ...values,
      status: 1,
      userId: 0,
      enterpriseGroupId: 0,
      distributorUserId: 0,
      userTypeId: 3,
      firstName: this.getOrDefaultValue(values.firstName.trim()),
      lastName: this.getOrDefaultValue(values.lastName.trim()),
      brandName: values.brandName.trim(),
      email: this.getOrDefaultValue(values.primaryEmail.trim()),
      secondaryEmail: this.getOrDefaultValue(values.secondaryEmail),
      phoneNumber: this.getOrDefaultValue(values.phoneNumber),
      secondaryPhoneNumber: this.getOrDefaultValue(values.secondaryPhoneNumber),
      billingContact: this.getOrDefaultValue(values.billingContact),
      billingContactLastName: values.billingContactLastName,
      billingPhoneNumber: this.getOrDefaultValue(values.billingPhoneNumber),
      billingAddressLine1: this.getOrDefaultValue(values.billingAddressLine1),
      billingAddressLine2: this.getOrDefaultValue(values.billingAddressLine2),
      zipCode: this.getOrDefaultValue(values.zip),
      countryId: values.countryId ? Number(values.countryId) : null,
      stateId: values.stateId ? Number(values.stateId) : null,
      city: this.getOrDefaultValue(values.city),
      businessYear: this.getOrDefaultValue(values.businessYear),
      websiteUrl: this.getOrDefaultValue(values.websiteUrl),
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      businessName: values.businessName,
      taxID: values.taxID,
      mobile: values.mobile,
      fax1: values.fax1,
      mobile1: values.mobile1,
      fax: values.fax,
      parentUser: {
        parentUserId: values.parentUser.parentUserId || 0,
        permissions: values.parentUser?.permissions,
      },
      roles: values.roles,
      carrier: values.carrier,
      areas: values.areas.map((res) => {
        return {
          areaId: res.value,
          name: res.label,
        };
      }),

      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: res.value,
          name: res.label,
        };
      }),
      carriers: values.carriers.map((res) => {
        return {
          carrierId: res.value,
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: res.value,
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: res.value,
          name: res.label,
        };
      }),
      distributorTypes: values.distributorType.map((res) => {
        return {
          distributorTypeId: res.value,
          name: res.label,
        };
      }),
      distributorSubscriptionDomains: values.distributorSubscriptionDomains,
      logoGcpPath: values.logoGcpPath,
      logoGcpFileUrl: values.logoGcpFileUrl,
    };
    const response = await ApiServices.httpPost(ADD_DISTRIBUTOR, payload);
    return response;
  };
  updateUser = async (values) => {
    const payload = {
      ...values,
      status: values.status,
      userId: values.id,
      enterpriseGroupId: 0,
      distributorUserId: 0,
      userTypeId: 3,
      brandName: values.brandName.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.primaryEmail.trim(),
      secondaryEmail: values.secondaryEmail,
      phoneNumber: values.phoneNumber,
      secondaryPhoneNumber: values.secondaryPhoneNumber,
      billingContact: values.billingContact,
      billingContactLastName: values.billingContactLastName,
      billingPhoneNumber: values.billingPhoneNumber,
      billingAddressLine1: values.billingAddressLine1,
      billingAddressLine2: values.billingAddressLine2,
      zipCode: values.zip,
      countryId: values.countryId ? Number(values.countryId) : null,
      city: values.city,
      stateId: values.stateId ? Number(values.stateId) : null,
      businessYear: values.businessYear,
      websiteUrl: values.websiteUrl,
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      carrier: values.carrier,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      systemGeneratedId: values.systemGeneratedId,
      businessName: values.businessName,
      taxID: values.taxID,
      mobile: values.mobile,
      fax: values.fax,
      fax1: values.fax1,
      mobile1: values.mobile1,

      parentUser: {
        parentUserId: values.parentUser.parentUserId || 0,
        permissions: values.parentUser?.permissions,
      },

      roles: values.roles,
      areas: values.areas.map((res) => {
        return {
          areaId: Number(res.value),
          name: res.label,
        };
      }),
      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: Number(res.value),
          name: res.label,
        };
      }),
      carriers: values.carriers.map((res) => {
        return {
          carrierId: Number(res.value),
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: Number(res.value),
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: Number(res.value),
          name: res.label,
        };
      }),
      distributorTypes: values.distributorType.map((res) => {
        return {
          distributorTypeId: Number(res.value),
          name: res.label,
        };
      }),
      distributorSubscriptionDomains: values.distributorSubscriptionDomains,
      logoGcpPath: values.logoGcpPath,
      logoGcpFileUrl: values.logoGcpFileUrl,
    };
    const response = await ApiServices.httpPut(ADD_DISTRIBUTOR, payload);
    return this.setResponseData(response);
  };
  updateUserStatus = () => {
    return "updating distributor status";
  };

  getInternalUsers = (query) => {
    return ApiServices.httpGet(`${INTERNAL_USERS}${query}`);
  };
  getInternalUser = (query) => {
    return ApiServices.httpGet(`${ADD_EDIT_INTERNAL_USERS}${query}`);
  };
  createInternalUser = (payload) => {
    return ApiServices.httpPost(ADD_EDIT_INTERNAL_USERS, payload);
  };
  updateInternalUser = (payload) => {
    return ApiServices.httpPut(ADD_EDIT_INTERNAL_USERS, payload);
  };
  updateInternalUserStatus = (payload) => {
    return ApiServices.httpPut(INTERNAL_USER_CHANGE_STATUS, payload);
  };

  populateDropdownOptions = async () => {
    const response = JSON.parse(localStorage.getItem("masterData"));
    if (response.responseStatus.isSuccess) {
      return {
        userTypeOptions: response.result.distributorTypes.map((res) => {
          return {
            value: res.distributorTypeId,
            label: res.name,
          };
        }),
        countries: response.result.countries,
        states: response.result.states,
        annualRevenues: response.result.annualRevenues,
        areas: response.result.areas.map((res) => {
          return {
            value: res.areaId,
            label: res.name,
          };
        }),
        carrierRepresents: response.result.carrierRepresents.map((res) => {
          return {
            value: res.carrierRepresentId,
            label: res.name,
          };
        }),
        carriers: response.result.carriers.map((res) => {
          return {
            value: res.carrierId,
            label: res.name,
          };
        }),
        industryGroups: response.result.industryGroups.map((res) => {
          return {
            value: res.industryGroupId,
            label: res.name,
          };
        }),
        technologies: response.result.technologies.map((res) => {
          return {
            value: res.technologyId,
            label: res.name,
          };
        }),
        roles: response.result.roles,
        parentPermissions: response.result.parentPermissions,
      };
    } else {
      return {
        userTypeOptions: [],
        countries: [],
        states: [],
        annualRevenues: [],
        areas: [],
        carrierRepresents: [],
        carriers: [],
        industryGroups: [],
        technologies: [],
        roles: [],
      };
    }
  };

  setResponseData = (response) => {
    //if you are changing here change this in the enterprise service one too if required
    let newRespone = {
      responseStatus: { ...response.responseStatus },
      result: {
        ...response.result,
        id: response.result.userId,
        primaryEmail: response.result.email,
        zip: response.result.zipCode,
        distributorType:
          response.result.distributorTypes &&
          response.result.distributorTypes.map((res) => {
            return {
              value: res.distributorTypeId,
              label: res.name,
            };
          }),
        industryGroups:
          response.result.industryGroups &&
          response.result.industryGroups.map((res) => {
            return {
              value: res.industryGroupId,
              label: res.name,
            };
          }),
      },
    };
    return newRespone;
  };

  exportData = (query) => {
    return ApiServices.httpGet(`${DISTRIBUTOR_EXPORT}${query}`);
  };

  exportDataInternalUsers = (query) => {
    return ApiServices.httpGet(`${INTERNAL_USERS_EXPORT}${query}`);
  };

  exportDataContent = (query) => {
    return ApiServices.httpGet(`${CONTENT_EXPORT}/${query}`);
  };

  exportDataCatalog = (query) => {
    return ApiServices.httpGet(`${CATALOG_EXPORT}/${query}`);
  };

  exportDataBundle = (query) => {
    return ApiServices.httpGet(`${BUNDLE_EXPORT}/${query}`);
  };

  exportAssociatedDataCatalog = (query) => {
    return ApiServices.httpGet(`${CATALOG_ASSOCIATED_CONTENT_EXPORT}/${query}`);
  };

  exportAssociatedDataBundle = (query) => {
    return ApiServices.httpGet(`${BUNDLE_ASSOCIATED_CONTENT_EXPORT}/${query}`);
  };

  /*istanbul ignore next */
  populateEDDropdown = async () => {
    const response = await ApiServices.httpGet(GET_ED_USERS);
    if (response.responseStatus.isSuccess) {
      return {
        allEDUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.name,
          };
        }),
      };
    } else {
      return {
        allEDUsers: [],
      };
    }
  };

  getOrDefaultValue(value, defaultValue = "") {
    return value || defaultValue;
  }
}
