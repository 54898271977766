import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../Modal/Modal.css";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InternalUserSchema } from "../../ValidationSchema/ValidationSchema";
import { toast } from "react-toastify";
import { formatDate } from "../../../utilities/formatter";
import PhoneNumberTextbox from "../../PhoneNumberTextbox/PhoneNumberTextbox";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InlineMessage from "../../InlineMessage/InlineMessage";
import { getServiceInstance } from "../../../services/UserServices/UserService";
import { MandatoryField } from "../../MandatoryFieldsIcon/MandatoryField";
import Confirmation from "../../Confirmation/Confirmation";
import { usePageMode } from "../../../hooks/usePageMode";
import { exitConfirmationMessage } from "../../../screens/Distributors/constants";
import { useAuth } from "../../../hooks/useAuth";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { SystemUsersEnum } from "../../../constants/SystemUsers";

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const AddEditInternalUserModal = (props) => {
  const auth = useAuth();
  const { id } = useParams();
  const page = usePageMode();
  const { data, userRolesDropdown, routeData, permissions } = props;
  const [open, setOpen] = React.useState(true);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const handleClose = () => {
    setOpen(false);
    props.handleCloseModalCallback();
  };
  const [state, setState] = useState({
    addMobile: false,
    addFax: false,
    addForm: false,
    registrationDate: new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
  });

  const handleCancel = () => {
    setState({
      addFax: false,
      addMobile: false,
      addForm: false,
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const {
    internalUserTypeId,
    userTypeId,
    addEditInternalUserKey,
    internalUserFieldLabel,
  } = routeData;
  const internalUserService = getServiceInstance(userTypeId);
  const options = userRolesDropdown?.map((item) => item?.name);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue(
        "roles",
        selected.length === options.length ? [] : options
      );
      return;
    }
    setSelected(value);
    formik.setFieldValue("roles", value);
    formik.setFieldValue("dirty", true);
  };

  const setRoles = () => {
    if (data.roles) {
      const userData = data.roles.map((item) => item.name);
      const roles = userRolesDropdown
        .filter((element) => userData.includes(element.name))
        .map((item) => item.name);
      setSelected(roles);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      primaryEmail: "",
      secondaryEmail: "",
      phoneNumber: "",
      secondaryPhoneNumber: "",
      userTypeId: 5,
      userId: null,
      systemGeneratedId: "",
      createdOn: "",
      mobile: "",
      mobile1: "",
      fax: "",
      fax1: "",
      dirty: false,
      [addEditInternalUserKey]: Number(
        id || props.distributorData?.systemGeneratedId
      ),
      roles: [],
    },
    enableReinitialize: true,
    validationSchema: InternalUserSchema,
  });

  useEffect(() => {
    page.setDirty(formik.values.dirty);
  }, [formik.values.dirty]);

  const formatPhoneNumber = (number) => {
    return number.replace(/\D/g, "");
  };

  const handleCancelClick = () => {
    if (formik.values.dirty) {
      setIsVisibleDialog(true);
    } else {
      handleCancel();
      handleClose();
    }
  };

  const onUpdate = async (e) => {
    e.preventDefault();
    let roles = userRolesDropdown.filter((element) =>
      selected.includes(element.name)
    );
    try {
      const response = await internalUserService.updateInternalUser({
        firstName: formik.values.firstName.trim(),
        lastName: formik.values.lastName.trim(),
        email: formik.values.primaryEmail.trim(),
        secondaryEmail: formik.values.secondaryEmail,
        phoneNumber: formatPhoneNumber(formik.values.phoneNumber),
        mobile: formik.values.mobile,
        fax1: formik.values.fax1,
        mobile1: formik.values.mobile1,
        fax: formik.values.fax,
        secondaryPhoneNumber: formatPhoneNumber(
          formik.values.secondaryPhoneNumber
        ),
        userTypeId: internalUserTypeId,
        userId: formik.values.userId,
        roles: roles,
        [addEditInternalUserKey]: Number(
          id || props.distributorData?.systemGeneratedId
        ),
      });
      if (response.responseStatus.isSuccess) {
        page.setDirty(false);
        handleClose();
        clearAll();
        toast.success(response.responseStatus.description);
        props.handleSuccessAddEdit(true);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    let roles = userRolesDropdown.filter((element) =>
      selected.includes(element.name)
    );
    try {
      const response = await internalUserService.createInternalUser({
        firstName: formik.values.firstName.trim(),
        lastName: formik.values.lastName.trim(),
        email: formik.values.primaryEmail.trim(),
        secondaryEmail: formik.values.secondaryEmail,
        phoneNumber: formatPhoneNumber(formik.values.phoneNumber),
        mobile: formik.values.mobile,
        fax1: formik.values.fax1,
        mobile1: formik.values.mobile1,
        fax: formik.values.fax,
        secondaryPhoneNumber: formatPhoneNumber(
          formik.values.secondaryPhoneNumber
        ),
        userTypeId: internalUserTypeId,
        userId: formik.values.userId,
        roles: roles,
        [addEditInternalUserKey]: Number(
          id || props.distributorData?.systemGeneratedId
        ),
      });
      if (response.responseStatus.isSuccess) {
        page.setDirty(false);
        handleClose();
        clearAll();
        toast.success(response.responseStatus.description);
        props.onSuccess(true);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  useEffect(() => {
    setRoles();
    formik.setValues({
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      primaryEmail: data.email || "",
      secondaryEmail: data.secondaryEmail || "",
      phoneNumber: data.phoneNumber || "",
      secondaryPhoneNumber: data.secondaryPhoneNumber || "",
      userTypeId: data.userTypeId || 5,
      userId: data.userId || 0,
      systemGeneratedId: data.systemGeneratedId || "",
      createdOn: formatDate(data.createdOn),
      mobile: data.mobile || "",
      fax1: data.fax1 || "",
      mobile1: data.mobile1 || "",
      fax: data.fax || "",
      [addEditInternalUserKey]: Number(
        id || props.distributorData?.systemGeneratedId
      ),
      internalUserRoleId: data.internalUserRoleId || 0,
      roles: data.roles,
    });
    setRoles();
  }, []);
  const clearAll = () => {
    formik.values.firstName = "";
    formik.values.lastName = "";
    formik.values.primaryEmail = "";
    formik.values.secondaryEmail = "";
    formik.values.phoneNumber = "";
    formik.values.secondaryPhoneNumber = "";
  };

  const isValid = () => {
    return (
      formik.values.firstName &&
      !formik.errors.firstName &&
      formik.values.lastName &&
      !formik.errors.lastName &&
      !formik.errors.primaryEmail &&
      formik.values.primaryEmail &&
      formik.values.phoneNumber &&
      !formik.errors.phoneNumber &&
      !formik.errors.secondaryPhoneNumber &&
      !formik.errors.mobile &&
      !formik.errors.mobile1 &&
      !formik.errors.fax &&
      !formik.errors.fax1
    );
  };

  const phoneNumberRegex = (value) => {
    if (!value) {
      return value;
    }
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const isX2 = x[3] ? "-" + x[3] : "";
    x = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + isX2;
    return x;
  };

  const renderMultiSelectRolesDropdown = () => {
    return (
      // className={classes.multiSelect}
      <div sx={{ width: "100%" }}>
        <label htmlFor="userTypeId">Sub Roles</label>
        <MandatoryField />
        <Select
          displayEmpty
          data-testid="userTypeId"
          labelId="mutiple-select-label"
          className="form-select form-control select-user roles-dropdown"
          multiple
          value={selected}
          title={selected}
          onChange={handleChange}
          renderValue={(selectedItem) => {
            if (selected.length === 0) {
              return "Select Sub Roles";
            }
            return selectedItem.join(", ");
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        <InlineMessage error={formik.errors.roles} />
      </div>
    );
  };

  return (
    <>
      <Confirmation
        open={isVisibleDialog}
        title={`Confirmation`}
        description={exitConfirmationMessage}
        onCancel={() => {
          setIsVisibleDialog(false);
        }}
        onConfirm={() => {
          setIsVisibleDialog(false);
          handleCancel();
          handleClose();
          page.setDirty(false);
        }}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Exit"
      />
      <Modal
        open={open}
        onClose={() => {
          handleCancelClick();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <form>
            <div className="modal-header">
              <div
                className="modal-header-left"
                data-testid="internal-user-modal"
              >
                {addOrEditUser_Contact(data)}
              </div>
              <div
                className="modal-header-right"
                onClick={() => {
                  handleCancelClick();
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseOutlinedIcon />
              </div>
            </div>
            <div className="modal-body">
              <Grid container spacing={2}>
                {data.systemGeneratedId && (
                  <Grid item xs={4}>
                    <label htmlFor="uniqueId">System Generated ID</label>
                    <MandatoryField />
                    <input
                      name="uniqueId"
                      className="form-label form-control"
                      type="text"
                      disabled
                      value={formik.values.systemGeneratedId}
                    />
                  </Grid>
                )}
                {data.systemGeneratedId && (
                  <Grid item xs={4}>
                    <label htmlFor="registrationDate">Registration Date</label>
                    <MandatoryField />
                    <input
                      name="registrationDate"
                      className="form-label form-control"
                      type="text"
                      disabled
                      value={formik.values.createdOn}
                    />
                  </Grid>
                )}
                {data.systemGeneratedId && <Grid item xs={4}></Grid>}
                <Grid item xs={4}>
                  <label htmlFor="firstName">First Name</label>
                  <MandatoryField />
                  <input
                    id="firstName"
                    name="firstName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter first name"
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        firstName: e.target.value,
                        dirty: true,
                      });
                    }}
                    value={formik.values.firstName}
                    maxLength={25}
                  />
                  <InlineMessage error={formik.errors.firstName} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="lastName">Last Name</label>
                  <MandatoryField />
                  <input
                    id="lastName"
                    name="lastName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter last name"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.lastName}
                    maxLength={25}
                  />
                  <InlineMessage error={formik.errors.lastName} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor={[addEditInternalUserKey]}>
                    {internalUserFieldLabel}
                  </label>
                  <input
                    name={[addEditInternalUserKey]}
                    className="form-label form-control"
                    type="text"
                    disabled
                    value={props.distributorData?.systemGeneratedId}
                  />
                </Grid>
                <Grid item xs={4}>
                  {renderMultiSelectRolesDropdown()}
                </Grid>
                {!data.systemGeneratedId && <Grid item xs={4}></Grid>}

                {[SystemUsersEnum.ADMIN, SystemUsersEnum.SUPERADMIN].includes(
                  auth.getRole()
                ) ? (
                  <Grid item xs={8}>
                    <label htmlFor="primaryEmail">Primary Email Address</label>
                    <MandatoryField />
                    {props.isEditMode && (
                      <span onClick={handleEditClick}>
                        <CreateOutlinedIcon className="cus-height-createOutlinedIcon" />
                      </span>
                    )}
                    <>
                      {editMode ? (
                        <div>
                          <input
                            id="primaryEmail"
                            name="primaryEmail"
                            value={formik.values.primaryEmail}
                            onChange={formik.handleChange}
                            className="form-label form-control"
                            type="text"
                            placeholder="Enter Primary Email Address"
                            data-testid="primaryEmail"
                          />
                          <InlineMessage error={formik.errors.primaryEmail} />
                        </div>
                      ) : (
                        <>
                          {formik.values.id ? (
                            <>
                              <a
                                className="primary-email-url"
                                href={`mailto:${formik.values.primaryEmail}`}
                              >
                                {formik.values.primaryEmail}
                              </a>
                            </>
                          ) : (
                            <>
                              <input
                                id="primaryEmail"
                                name="primaryEmail"
                                value={formik.values.primaryEmail}
                                onChange={formik.handleChange}
                                className="form-label form-control"
                                type="text"
                                placeholder="Enter Primary Email Address"
                                disabled={props.isEditMode}
                                data-testid="primaryEmail"
                              />
                              <InlineMessage
                                error={formik.errors.primaryEmail}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <label htmlFor="primaryEmail">Primary Email Address</label>
                    <MandatoryField />
                    {formik.values.id ? (
                      <>
                        <a
                          className="primary-email-url"
                          href={`mailto:${formik.values.primaryEmail}`}
                        >
                          {formik.values.primaryEmail}
                        </a>
                      </>
                    ) : (
                      <>
                        <input
                          id="primaryEmail"
                          name="primaryEmail"
                          value={formik.values.primaryEmail}
                          onChange={formik.handleChange}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Primary Email Address"
                          disabled={props.isEditMode}
                          data-testid="primaryEmail"
                        />
                        <InlineMessage error={formik.errors.primaryEmail} />
                      </>
                    )}
                  </Grid>
                )}
                <Grid item xs={8}>
                  <label htmlFor="secondaryEmail">
                    Secondary Email Address
                  </label>
                  <input
                    id="secondaryEmail"
                    className="form-label form-control"
                    type="email"
                    name="secondaryEmail"
                    placeholder="Enter secondary email address"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.secondaryEmail}
                  />
                  <InlineMessage error={formik.errors.secondaryEmail} />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <label htmlFor="phoneNumber">Business Phone Number</label>
                  <MandatoryField />
                  <PhoneNumberTextbox
                    id="phoneNumber"
                    data-testid="phoneNumber"
                    className="form-label form-control"
                    name="phoneNumber"
                    placeholder="Enter Business Phone Number"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={phoneNumberRegex(formik.values.phoneNumber)}
                    phoneCode={["+1"]}
                  />
                  <InlineMessage error={formik.errors.phoneNumber} />
                </Grid>

                {!data.secondaryPhoneNumber && (
                  <Grid item xs={4}>
                    <div
                      data-testid="addPhoneNumber"
                      className="add-item"
                      onClick={() => {
                        if (state.addForm) {
                          formik.setValues({
                            ...formik.values,
                            secondaryPhoneNumber: "",
                          });
                        }
                        setState({ ...state, addForm: !state.addForm });
                      }}
                    >
                      <h5>
                        <span>{addOrRemoveAnotherNum(state)}</span>
                      </h5>
                    </div>
                  </Grid>
                )}

                {!state.addForm && !data.secondaryPhoneNumber && (
                  <Grid item xs={4}></Grid>
                )}
                {loadBusiness2Phone(state, data) && (
                  <Grid item xs={4}>
                    <label htmlFor="secondaryPhoneNumber">
                      Business 2 Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="secondaryPhoneNumber"
                      className="form-label form-control"
                      name="secondaryPhoneNumber"
                      placeholder="Enter Business 2 Phone Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={phoneNumberRegex(
                        formik.values.secondaryPhoneNumber
                      )}
                      phoneCode={["+1"]} // Country Code for USA and Canada
                    />
                    <InlineMessage error={formik.errors.secondaryPhoneNumber} />
                  </Grid>
                )}

                {/* Mobile  */}
                <Grid item xs={4}>
                  <label htmlFor="mobile">Mobile</label>
                  <PhoneNumberTextbox
                    id="mobile"
                    data-testid="mobile"
                    className="form-label form-control"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={phoneNumberRegex(formik.values.mobile)}
                    phoneCode={["+1"]} // Country Code for USA and Canada
                  />
                  <InlineMessage error={formik.errors.mobile} />
                </Grid>
                {!data.mobile1 && (
                  <Grid item xs={4}>
                    <div
                      data-testid="addPhoneMobile"
                      className="add-item"
                      onClick={() => {
                        if (state.addMobile) {
                          formik.setValues({
                            ...formik.values,
                            mobile1: "",
                          });
                        }
                        setState({ ...state, addMobile: !state.addMobile });
                      }}
                    >
                      <h5>{addOrRemoveMobile(state)}</h5>
                    </div>
                  </Grid>
                )}

                {!state.addMobile && !props?.isEditMode && (
                  <Grid item xs={4}></Grid>
                )}
                {loadAltMobile(state, data) && (
                  <Grid item xs={4}>
                    <label htmlFor="mobile1">Alternate Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile1"
                      className="form-label form-control"
                      name="mobile1"
                      placeholder="Enter Another Mobile Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={phoneNumberRegex(formik.values.mobile1)}
                      phoneCode={["+1"]} // Country Code for USA and Canada
                    />
                    <InlineMessage error={formik.errors.mobile1} />
                  </Grid>
                )}

                {/* Mobile */}

                {/* Fax */}
                <Grid item xs={4}>
                  <label htmlFor="fax">Fax</label>
                  <PhoneNumberTextbox
                    id="fax"
                    data-testid="fax"
                    className="form-label form-control"
                    name="fax"
                    placeholder="Enter Fax"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={phoneNumberRegex(formik.values.fax)}
                    phoneCode={["+1"]} // Country Code for USA and Canada
                  />
                  <InlineMessage error={formik.errors.fax} />
                </Grid>
                {!data.fax1 && (
                  <Grid item xs={4}>
                    <div
                      data-testid="addAnotherFax"
                      className="add-item"
                      onClick={() => {
                        if (state.addFax) {
                          formik.setValues({
                            ...formik.values,
                            fax1: "",
                          });
                        }
                        setState({ ...state, addFax: !state.addFax });
                      }}
                    >
                      <h5>{addOrRemoveFax(state)}</h5>
                    </div>
                  </Grid>
                )}

                {!state.addFax && !props?.isEditMode && (
                  <Grid item xs={4}></Grid>
                )}

                {loadAltFax(state, data) && (
                  <Grid item xs={4}>
                    <label htmlFor="fax1">Alternate Fax</label>
                    <PhoneNumberTextbox
                      id="fax1"
                      className="form-label form-control"
                      name="fax1"
                      placeholder="Enter Alternate Fax"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={phoneNumberRegex(formik.values.fax1)}
                      phoneCode={["+1"]} // Country Code for USA and Canada
                    />
                    <InlineMessage error={formik.errors.fax1} />
                  </Grid>
                )}
                {/* Fax */}
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="modal-buttons">
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  data-testid="close-internal-modal"
                  onClick={() => {
                    handleCancelClick();
                    handleCancel();
                    page.setDirty(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="add-edit-internal-btn"
                  type="submit"
                  variant="contained"
                  className="action-button"
                  disabled={
                    !isValid() ||
                    !permissions?.includes("Update") ||
                    selected.length === 0
                  }
                  onClick={callSaveOrUpdate(data, onUpdate, onSave)}
                >
                  {saveOrUpdate(data)}
                </Button>
              </div>
            </div>
          </form>
          ;
        </Box>
      </Modal>
    </>
  );
};

export default AddEditInternalUserModal;
function addOrEditUser_Contact(data) {
  return !data.systemGeneratedId ? "Add User/Contacts" : "Edit User/Contacts";
}

function addOrRemoveAnotherNum(state) {
  return !state.addForm ? "+ Add Another Number" : "- Remove Another Number";
}

function loadBusiness2Phone(state, data) {
  return state.addForm || data.secondaryPhoneNumber;
}

function addOrRemoveMobile(state) {
  return !state.addMobile ? "+ Add Another Mobile" : "- Remove Another Mobile";
}

function loadAltMobile(state, data) {
  return state.addMobile || data.mobile1;
}

function addOrRemoveFax(state) {
  return !state.addFax ? "+ Add Another Fax" : "- Remove Another Fax";
}

function loadAltFax(state, data) {
  return state.addFax || data.fax1;
}

function callSaveOrUpdate(data, onUpdate, onSave) {
  return data.systemGeneratedId ? onUpdate : onSave;
}

function saveOrUpdate(data) {
  return data.systemGeneratedId ? "Update Details" : "Save Details";
}
