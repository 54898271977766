export class IUserService {
  getUsers = () => {};
  getUser = () => {};
  createUser = () => {};
  updateUser = () => {};
  updateUserStatus = () => {};

  getInternalUsers = () => {};
  getInternalUser = () => {};
  createInternalUser = () => {};
  updateInternalUser = () => {};
  updateInternalUserStatus = () => {};

  populateDropdownOptions = () => {};
}
