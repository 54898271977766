import { useEffect, useState, useMemo } from "react";
import "../../../Dashboard/Dashboard.css";
import ApiServices from "../../../../services/ApiServices";
import {
  CATALOG,
  CATALOG_FAVORITE,
  CATALOG_STATUS,
} from "../../../../constants/api-constants";
import { toast } from "react-toastify";
import { useLoading } from "../../../../hooks/useLoader";
import {
  GetAllSelectedIds,
  ClearSelectionData,
  SelectionFilter,
} from "../../../../utilities/SelectionFilter";
import DataTable from "../../../Tables/DataTable/DataTable";
import TableColumnModal from "../../../../common/TableColumnModal/TableColumnModal";
import {
  ContentPublishStatus,
  DocumentType,
  contentTypeOptions,
  typeOfContentOptions,
} from "../../constants";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { useHistory } from "react-router";
import { useCatalogContext } from "../Catalog.context";
import AssociateSubscribers from "../../AssociateSubscribers/AssociateSubscribers";
import { Button } from "@mui/material";
import { COLUMN_TYPES } from "../../../../constants/common";
import { useAuth } from "../../../../hooks/useAuth";

const CatalogList = (props) => {
  const history = useHistory();
  const { showLoader } = useLoading();
  const auth = useAuth();
  const catalogContext = useCatalogContext();
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const query = useMemo(() => {
    return props.query;
  }, [props.query]);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showEditAction, setShowEditAction] = useState(true);
  const [showFavoriteAction, setShowFavoriteAction] = useState(true);
  const [showAssociateSubscriberModal, setShowAssociateSubscriberModal] =
    useState(false);
  const [catalog, setCatalog] = useState(null);
  const [action, setAction] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  /*istanbul ignore next */
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setAction(actionName);
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  /*istanbul ignore next */
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  useEffect(() => {
    ClearSelectionData();
  }, []);

  useEffect(() => {
    setTableColumnModalVisibility(props.showColumnPopup);
  }, [props.showColumnPopup]);

  useEffect(() => {
    getCatalogList();
    setSelectionColumns();
    if (history.location.pathname.includes("enterprisegroup")) {
      setShowEditAction(false);
      setShowFavoriteAction(false);
    }
  }, [query]);

  useEffect(() => {
    setShowEditAction(
      props.permissions?.includes("Update") &&
        !history.location.pathname.includes("enterprisegroup")
    );
  }, [props.permissions]);

  const onEditSuccess = (data) => {};

  const handleChangePageNumber = (event, newPage) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  /*istanbul ignore next */
  const onSuccessToggle = (val) => {
    getCatalogList();
  };
  /*istanbul ignore next */
  const getCatalogList = async () => {
    try {
      showLoader(true);
      let firstParam = 0;
      let secondParam = 0;
      if (
        window.location.pathname.includes("distributor") ||
        auth.getRole() === 3
      ) {
        firstParam = props.id;
      } else if (
        window.location.pathname.includes("enterprisegroup") ||
        auth.getRole() === 5
      ) {
        firstParam = -1;
        secondParam = props.id;
      }
      const adminData = await ApiServices.httpGet(
        `${CATALOG}/${firstParam}/${secondParam}/${encodeURIComponent(
          JSON.stringify(query)
        )}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("contentId");
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.contentId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          ele.typeOfContentName =
            typeOfContentOptions.find(
              (item) => item.value === ele.typeOfContent
            )?.name || "";

          ele.contentTypeName =
            contentTypeOptions.find((item) => item.value === ele.contentType)
              ?.name || "";

          return ele;
        });
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      props.setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
          PageNumber: 1,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    props.setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    } else {
      let newData = { [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(newData));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
    props.onCancelTableColumnSelection(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let catalogCols = selectedCols?.catalog || [];
    if (catalogCols && !history.location.pathname.includes("/distributor")) {
      if (!catalogCols.some((col) => col.fieldName === "userName")) {
        catalogCols.splice(2, 0, {
          exportLabel: "Distributor",
          fieldName: "userName",
          label: "Distributor",
          isSelected: true,
          type: COLUMN_TYPES.BASIC,
          showInSelection: true,
          sort: true,
          width: 145,
        });
      }
    }
    setSelectedColumns(catalogCols);
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setAdminTableData((items) => {
        SelectionFilter(items, "multiple", checked, "contentId");
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setAdminTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };
  /*istanbul ignore next */
  const onDeleteHandler = (e, contentName) => {
    setCatalog(contentName);
    handleConfirmationOpen(
      "Delete",
      "Are you sure you want to delete this catalog?"
    );
  };
  /*istanbul ignore next */
  const actionHandler = async (actionName, val, isFavorite) => {
    switch (actionName) {
      case "Add to Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Remove Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Delete":
        if (val.publishStatus === ContentPublishStatus.DRAFT) {
          setCatalog(val);
          handleConfirmationOpen(
            "Delete",
            "Are you sure you want to delete this catalog?"
          );
        }
        break;
      case "Copy":
        props.onEdit(val, DocumentType.CATALOG);
        catalogContext.copyCatalog(val);
        break;
      default:
    }
  };
  /*istanbul ignore next */
  const deleteCatalog = async (e) => {
    try {
      const response = await ApiServices.httpDelete(CATALOG, {
        contentId: catalog.contentId,
        userId: catalog.userId,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getCatalogList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    contentName
  ) => {
    setCatalog(contentName);
    if (contentName.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the catalog as Inactive?"
      );
    } else {
      // No confirmation while Activate user
      changeContentStatus(contentName, "", 1);
    }
  };
  /*istanbul ignore next */
  const changeContentStatus = async (contentObj, reason, status) => {
    try {
      const response = await ApiServices.httpPut(CATALOG_STATUS, {
        contentId: contentObj.contentId,
        userId: contentObj.userId,
        status: status,
        reason: reason,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getCatalogList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onFavoriteHandler = async (val, isFavorite) => {
    try {
      const response = await ApiServices.httpPut(CATALOG_FAVORITE, {
        contentId: val.contentId,
        isFavorite: !isFavorite,
      });

      if (response.responseStatus.isSuccess) {
        getCatalogList();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  return (
    <div className="dashboard-main">
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          {!props.hideSubscriberAssociationOption && (
            <div className="associate-subscribers">
              <Button
                disabled={!GetAllSelectedIds("contentId")?.length}
                data-testid="associate-save-button-catalog"
                className="action-button"
                type="button"
                variant="contained"
                onClick={() => {
                  setShowAssociateSubscriberModal(true);
                }}
              >
                Associate Subscribers
              </Button>
            </div>
          )}

          {showAssociateSubscriberModal && (
            <AssociateSubscribers
              selectedContentIds={GetAllSelectedIds("contentId")}
              clearSelection={() => {
                ClearSelectionData();
                getCatalogList();
              }}
              setClose={() => {
                setShowAssociateSubscriberModal(false);
              }}
              contentId={props.contentId}
              id={props.id}
            />
          )}

          <DataTable
            uniqueKey="contentId"
            onCheckboxChange={onCheckboxChangeHandler}
            columns={selectedColumns}
            query={query}
            handleChangePage={handleChangePageNumber}
            success={onSuccessToggle}
            adminTable={adminTable}
            onEditSuccess={onEditSuccess}
            totalPages={totalPages}
            pageSize={pageSize}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            actionHandler={actionHandler}
            showFavorite={showFavoriteAction}
            showView={true}
            showDelete={true}
            onDelete={onDeleteHandler}
            onView={props.onView}
            onEdit={props.onEdit}
            onFavorite={onFavoriteHandler}
            onToggle={onToggleHandler}
            showEdit={showEditAction}
            permissions={props.permissions}
          />
          {tableColumnModalVisibility && (
            <TableColumnModal
              componentName="catalog"
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            showReason={action === "Inactivate"}
            onConfirm={async (e, reason) => {
              if (action === "Delete") {
                deleteCatalog(e);
              } else if (action === "Activate" || action === "Inactivate") {
                changeContentStatus(catalog, reason);
              }

              handleConfirmationClose();
            }}
            onCancel={() => {
              handleConfirmationClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CatalogList;
