import { Switch } from "@mui/material";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {
  const { activeLabelText, inactiveLabelText, state, onChange, disabled, id } =
    props;
  return (
    <div className="toggle-switch-root">
      <Switch
        checkedIcon={
          disabled ? (
            <img src="/images/active_greyed.png" height={28} alt="active" />
          ) : (
            <img src="/images/active.png" height={28} alt="active" />
          )
        }
        icon={
          disabled ? (
            <img src="/images/inactive_greyed.png" height={28} alt="active" />
          ) : (
            <img src="/images/inactive.png" height={28} alt="inactive" />
          )
        }
        checked={state}
        onChange={onChange}
        disabled={disabled || props.coloredDisable}
        name={id}
        id={id}
        data-testid={props.dataTestId ? props.dataTestId : "activeCheckbox"}
      />
      <div className="status-label">
        {state ? activeLabelText : inactiveLabelText}
      </div>
    </div>
  );
};

export default ToggleSwitch;
