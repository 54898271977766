import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import "../../..//Dashboard/Dashboard.css";
import ApiServices from "../../../../services/ApiServices";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CATALOG,
  CATALOG_ASSOCIATED_CONTENT,
  CATALOG_LIST_CONTENT_CHANGE_STATUS,
} from "../../../../constants/api-constants";
import { toast } from "react-toastify";
import { useAuth } from "../../../../hooks/useAuth";
import {
  GetAllSelectedIds,
  SelectionFilter,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import { useLoading } from "../../../../hooks/useLoader";
import DataTable from "../../../Tables/DataTable/DataTable";
import AddContentDialog from "../AddContentDialog/AddContentDialog";
import { useCatalogContext } from "../Catalog.context";
import { useContentContext } from "../../Content/Content.context";
import { TABS, MODE, COLUMN_TYPES } from "../../../../constants/common";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { useHistory } from "react-router";
import { SystemUsersEnum } from "../../../../constants/SystemUsers";
import TableColumnModal from "../../../../common/TableColumnModal/TableColumnModal";
import { useDistributorProfileContext } from "../../DistributorProfile.context";
import {
  getModuleId,
  PermissionEnum,
  PermissionService,
} from "../../../../constants/permissionService";

const ListContent = (props) => {
  const history = useHistory();
  const { userId, contentId, anchorEl, setAnchorEl, catalogPublishStatus } =
    props;
  const { showLoader } = useLoading();
  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: true,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    filter: {},
  });
  const distributorProfileContext = useDistributorProfileContext();
  const catalogContext = useCatalogContext();
  const contentContext = useContentContext();
  const [listContentRecords, setListContentRecords] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [isRemove, setIsRemove] = useState(false);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const auth = useAuth();
  const [subscriberId, setSubscriberId] = useState(null);
  const [associateStatus, setAssociateStatus] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const handleConfirmationOpen = () => setConfirmation(true);
  const handleConfirmationClose = () => setConfirmation(false);
  const permissions = PermissionService(
    getModuleId(props.routeData?.userTypeId)
  );
  useEffect(() => {
    setSelectionColumns();
    listContentDetails();
    ClearSelectionData();
  }, [query]);

  useEffect(() => {
    if (TABS.CONTENT_LIST) {
      setQuery({ ...query, filter: props?.query?.filter });
      setSelectionColumns();
    }
  }, [props.query]);

  useEffect(() => {
    setTableColumnModalVisibility(props.showColumnPopup);
  }, [props.showColumnPopup]);
  /*istanbul ignore next */
  const listContentDetails = async () => {
    try {
      showLoader(true);
      let distId = 0;
      if (history.location.pathname.includes("enterprisegroup")) {
        distId = catalogContext.userId ? catalogContext.userId : props.distId;
      } else {
        distId = userId;
      }
      const listContentResponse = await ApiServices.httpGet(
        `${CATALOG_ASSOCIATED_CONTENT}/${distId}/${contentId}/${JSON.stringify(
          query
        )}`
      );
      showLoader(false);
      setListContentRecords(
        JSON.parse(JSON.stringify(listContentResponse?.result?.records))
      );
      setTotalPages(listContentResponse?.result?.totalPages);
      setPageSize(listContentResponse?.result?.pageSize);
    } catch (e) {
      showLoader(false);
    }
  };

  const onViewHandler = (content) => {
    distributorProfileContext.setSelectedTab(TABS.CONTENT);
    props.setSelectedSubTab(TABS.CONTENT_INFORMATION);
    contentContext.setContentMode(MODE.VIEW);
    props.setContentMode(MODE.VIEW);
    contentContext.setContentId(content.contentId);
    props.setContentId(content.contentId);
    let location = window.location.pathname.includes("enterprisegroup")
      ? "enterprisegroup"
      : "distributor";
    history.push(`/${location}/${userId}/content/${TABS.CONTENT_INFORMATION}`);
  };

  const onEditHandler = (content) => {
    distributorProfileContext.setSelectedTab(TABS.CONTENT);
    props.setSelectedSubTab(TABS.CONTENT_INFORMATION);
    contentContext.setContentMode(MODE.EDIT);
    props.setContentMode(MODE.EDIT);
    contentContext.setContentId(content.contentId);
    props.setContentId(content.contentId);
    let location = window.location.pathname.includes("enterprisegroup")
      ? "enterprisegroup"
      : "distributor";
    history.push(`/${location}/${userId}/content/${TABS.CONTENT_INFORMATION}`);
  };
  /*istanbul ignore next */
  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let listOfContentCols = selectedCols?.listOfContentCatalogDataColumns || [];
    if (
      listOfContentCols &&
      history.location.pathname.includes("/ContentList")
    ) {
      listOfContentCols = selectedCols?.listOfContentCatalogDataColumns || [];
    } else if (
      listOfContentCols &&
      !history.location.pathname.includes("/distributor")
    ) {
      if (!listOfContentCols.some((col) => col.fieldName === "distributor")) {
        listOfContentCols.splice(2, 0, {
          exportLabel: "Distributor",
          fieldName: "distributor",
          label: "Distributor",
          isSelected: true,
          type: COLUMN_TYPES.BASIC,
          showInSelection: true,
          sort: true,
          width: 145,
        });
      }
    }
    setSelectedColumns(listOfContentCols);
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    } else {
      let newData = { [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(newData));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const listContentDeleteHandler = async (id) => {
    try {
      let payload = {
        contentId: contentId,
        userId: userId,
        associateContentIds: [...id],
      };
      const response = await ApiServices.httpDelete(
        `${CATALOG}/content`,
        payload
      );
      if (response?.responseStatus?.isSuccess) {
        ClearSelectionData();
        listContentDetails();
        toast.success(response?.responseStatus?.description);
      } else {
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      toast.success("Internal server error.");
    }
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setListContentRecords((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setListContentRecords((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };
  /*istanbul ignore next */
  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };
  /*istanbul ignore next */
  const handleCancelClick = () => {
    catalogContext.setSelectedTab(TABS.CATALOG);
    catalogContext.setCatalogMode(MODE.LIST);
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("catalog")
      )}${TABS.CATALOG}`
    );
  };
  /*istanbul ignore next */
  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  /*istanbul ignore next */
  const onToggle = async (
    columName,
    subscribersId,
    contentSubscriberStatus,
    event,
    res
  ) => {
    event.preventDefault();
    setSubscriberId(res.contentId);
    setAssociateStatus(res.contentAssociationStatus);
    handleConfirmationOpen();
  };
  /*istanbul ignore next */
  const changeStatus = async (subscribersId, contentSubscriberStatus) => {
    const toggle = await ApiServices.httpPut(
      CATALOG_LIST_CONTENT_CHANGE_STATUS,
      {
        status: contentSubscriberStatus ? 0 : 1,
        contentId: contentId, // catalog id
        mapContentId: subscribersId, // List of content -> content id
        userId: userId, // dist id
      }
    );
    if (toggle.responseStatus?.isSuccess) {
      toast.success(toggle.responseStatus.description);
      listContentDetails();
    } else {
      toast.error(toggle.responseStatus?.description);
    }
  };
  return (
    <div className="dashboard-main">
      <div>
        <Confirmation
          open={confirmation}
          handleClose={handleConfirmationClose}
          title={`Are you sure you want to ${
            associateStatus ? "dis-associate" : "associate"
          } the Content?`}
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onConfirm={() => {
            changeStatus(subscriberId, associateStatus);
            handleConfirmationClose();
          }}
          onCancel={() => {
            handleConfirmationClose();
          }}
        />
      </div>
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          <Grid display={"flex"} justifyContent={"space-between"}>
            <div className="welcome-main">
              <div className="welcome-message">
                <h5>List of Content for {props.catalogName || "NA"}</h5>
              </div>
            </div>
            <div className="welcome-main">
              <div className="action-items">
                {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
                  ((permissions &&
                    permissions.includes(PermissionEnum.CREATE)) ||
                    (permissions &&
                      permissions.includes(PermissionEnum.UPDATE))) && (
                    <Button
                      data-testid="removeContentBtn"
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setIsRemove(true);
                      }}
                      disabled={
                        GetAllSelectedIds("contentId").length < 1 ||
                        (catalogContext.selectedTab === TABS.CONTENT_LIST &&
                          catalogContext.catalogMode === MODE.VIEW)
                      }
                    >
                      Remove Content
                    </Button>
                  )}
                {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
                  ((permissions &&
                    permissions.includes(PermissionEnum.CREATE)) ||
                    (permissions &&
                      permissions.includes(PermissionEnum.UPDATE))) && (
                    <Button
                      data-testid="addContentBtn"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                      disabled={
                        (catalogContext.selectedTab === TABS.CONTENT_LIST &&
                          catalogContext.catalogMode === MODE.VIEW) ||
                        catalogPublishStatus === 2
                      }
                    >
                      Add Content
                    </Button>
                  )}
                <Button
                  data-testid="cancel-action-catalog"
                  variant="outlined"
                  className="action-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>

              <div className="welcome-widget-main">
                <div className="welcome-widgets"></div>
              </div>
            </div>
          </Grid>
          <div className="manage-subscriber-table">
            {openDialog && (
              <AddContentDialog
                userId={userId}
                open={openDialog}
                contentId={contentId}
                listContentData={listContentRecords}
                id={userId}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                closeDialogHandler={(status) => {
                  setOpenDialog(status);
                  listContentDetails();
                  ClearSelectionData();
                }}
                publishStatus={props.publishStatus}
              />
            )}
            <DataTable
              uniqueKey="contentId"
              adminDetails={listContentDetails}
              onCheckboxChange={onCheckboxChangeHandler}
              columns={selectedColumns}
              query={query}
              adminTable={listContentRecords}
              onSearch={onSearchHandler}
              onSort={sortHandler}
              onPageSizeChange={pageSizeChangeHandler}
              handleChangePage={handleChangePageNumber}
              totalPages={totalPages}
              pageSize={pageSize}
              publishStatus={props.publishStatus}
              onToggle={onToggle}
              showEdit={true}
              showView={true}
              onView={onViewHandler}
              onEdit={onEditHandler}
            />
            {tableColumnModalVisibility && (
              <TableColumnModal
                componentName="listOfContentCatalogDataColumns"
                isOpen={tableColumnModalVisibility}
                onCancelTableColumnSelection={onCancelTableColumnSelection}
                onSaveTableColumnSelection={onSaveTableColumnSelection}
                selection={selectedColumns}
              />
            )}
            <Confirmation
              open={isRemove}
              handleClose={() => {
                setIsRemove(false);
              }}
              title={`Are you sure you want to remove the content${
                GetAllSelectedIds("contentId").length > 1 ? "s" : ""
              } from the catalog?`}
              cancelButtonLabel="No"
              confirmButtonLabel="Yes"
              onConfirm={async () => {
                listContentDeleteHandler(GetAllSelectedIds("contentId"));

                setIsRemove(false);
              }}
              onCancel={() => {
                setIsRemove(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListContent;
