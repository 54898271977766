import React, { useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../DataTable/DataTable.css";
import {
  Checkbox,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import ToggleSwitch from "../../../common/Switch/ToggleSwitch";
import { toast } from "react-toastify";
import { COLUMN_TYPES, decision, status } from "../../../constants/common";
import AddEditRoleModal from "../../ManageRoles/AddEditRoleModal";
import moment from "moment";
import ThreeDotMenu from "../../../common/ThreeDotMenu/ThreeDotMenu";
import { SystemUsersEnum } from "../../../constants/SystemUsers";
import {
  ContentPublishStatus,
  DocumentType,
} from "../../Distributors/constants";
import { UNBLOCK_USER } from "../../../constants/api-constants";
import ApiServices from "../../../services/ApiServices";
import { useLoading } from "../../../hooks/useLoader";
import Confirmation from "../../../common/Confirmation/Confirmation";
import { useAuth } from "../../../hooks/useAuth";
import { PermissionEnum } from "../../../constants/permissionService";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const DataTable = (props) => {
  const isdistributorContent = window.location.pathname.includes("distributor");
  const isenterpriseContent =
    window.location.pathname.includes("enterprisegroup");
  const isManageDocument =
    window.location.pathname.includes("manage-documents");
  const ent = window.location.pathname.endsWith("content");
  const entr = window.location.pathname.endsWith("catalog");
  const entrs = window.location.pathname.endsWith("bundle");
  const toggleContent = window.location.pathname.endsWith(
    "content/ManageSubscribers"
  );
  const toggleCatalog = window.location.pathname.endsWith(
    "catalog/ManageSubscribers"
  );
  const toggleBundle = window.location.pathname.endsWith(
    "bundle/ManageSubscribers"
  );
  const toggleApproval = window.location.pathname.includes("confirm-approval");
  const userSelectedRoleId = Number(localStorage.getItem("selectedRole"));
  const isPathEnt = ent;
  const isPathEntr = entr;
  const isPathEntrs = entrs;
  const isPathContentManageSubscribers = toggleContent;
  const isPathCatalogManageSubscribers = toggleCatalog;
  const isPathBundleManageSubscribers = toggleBundle;
  const isPathApprovalSubscribers = toggleApproval;
  const auth = useAuth();
  const tableContainerRef = useRef(null);

  const {
    columns,
    showFavorite,
    showView,
    showEdit,
    uniqueKey,
    onView,
    onEdit,
    onReject,
    actionHandler,
    adminDetails,
    showDeleteDocument,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const contentSubscriberFieldName = "contentSubscriberStatus";
  const contentAssociationFieldName = "contentAssociationStatus";
  const [sortColumn, setSortColumn] = React.useState(""); // To track the currently sorted column
  const [sortOrder, setSortOrder] = React.useState(""); // To track the sorting order (ascending or descending)
  const [openUnBlockUserPopup, setOpenUnBlockUserPopup] = React.useState(false);
  const { showLoader } = useLoading();
  const [ids, setIds] = React.useState(null);

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorting column in ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [props.adminTable]);

  const getSortImage = (column) => {
    if (sortColumn === column) {
      if (sortOrder === "asc") {
        return "/images/Frame 2252422.png";
      } else {
        return "/images/Frame 2252421.png";
      }
    } else {
      return "/images/Frame 2252255.png";
    }
  };
  /*istanbul ignore next */
  const onUnBlockUserHandler = async () => {
    try {
      showLoader(true);
      const res = await ApiServices.httpPost(UNBLOCK_USER, {
        userId: ids,
      });
      showLoader(false);
      if (res.responseStatus?.isSuccess) {
        toast.success(res.responseStatus.description);
        props.success(true);
        setOpenUnBlockUserPopup(false);
      } else {
        toast.error(res.responseStatus.error);
        props.success(false);
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const renderTableCell = (column, res) => {
    let cellElement;
    switch (column.type) {
      case COLUMN_TYPES.DATE:
        cellElement = columnTypeDate(res, column);
        break;
      case COLUMN_TYPES.EMAIL:
        cellElement = columnTypeEmail(res, column);
        break;
      case COLUMN_TYPES.MULTI_VALUE:
        cellElement = columnTypeMultiValue(res, column);
        break;
      case COLUMN_TYPES.UNBLOCK_TOGGLE:
        cellElement = columnTypeUnblockToggle(
          res,
          props,
          setOpenUnBlockUserPopup,
          setIds,
          column
        );

        break;
      case COLUMN_TYPES.STATUS_CHANGE:
        let dataObj2 = {
          auth: auth,
          isPathEnt: isPathEnt,
          isPathEntr: isPathEntr,
          isPathEntrs: isPathEntrs,
          isPathContentManageSubscribers: isPathContentManageSubscribers,
          isPathApprovalSubscribers: isPathApprovalSubscribers,
          isPathCatalogManageSubscribers: isPathCatalogManageSubscribers,
          isPathBundleManageSubscribers: isPathBundleManageSubscribers,
          column: column,
          props: props,
          res: res,
          contentSubscriberFieldName: contentSubscriberFieldName,
          contentAssociationFieldName: contentAssociationFieldName,
          uniqueKey: uniqueKey,
          handleOpen: handleOpen,
          open: open,
        };
        cellElement = columnTypeStatusChange(dataObj2);
        break;
      case COLUMN_TYPES.ACTIVE_INACTIVE_LABEL:
        cellElement = columnTypeActiveInactiveLabel(res, column);
        break;
      case COLUMN_TYPES.BOOLEAN:
        const fieldName = res[column.fieldName];
        let resultString;
        switch (fieldName) {
          case ContentPublishStatus.DRAFT:
            resultString = "Draft";
            break;
          case ContentPublishStatus.PUBLISHED:
            resultString = "Published";
            break;
          case ContentPublishStatus.MODIFIED:
            resultString = "Modified";
            break;
          case true:
            resultString = column.YesLabelText;
            break;
          case false:
            resultString = column.NoLabelText;
            break;
          default:
            resultString = "";
        }

        cellElement = <TableCell>{resultString}</TableCell>;
        break;

      case COLUMN_TYPES.ACTION:
        let dataObj = {
          showEdit: showEdit,
          props: props,
          res: res,
          adminDetails: adminDetails,
          uniqueKey: uniqueKey,
          userSelectedRoleId: userSelectedRoleId,
          onEdit: onEdit,
          showView: showView,
          onView: onView,
          showFavorite: showFavorite,
          column: column,
          auth: auth,
          actionHandler: actionHandler,
          showDeleteDocument: showDeleteDocument,
          permissions: props.permissions,
        };
        cellElement = columnTypeAction(dataObj);
        break;

      case COLUMN_TYPES.DOCTYPE:
        cellElement = columnTypeDocType(res, column);
        break;

      case COLUMN_TYPES.STYLED_BASIC:
        let dataObj4 = {
          showView: showView,
          onReject: onReject,
        };
        cellElement = columnTypeStyledBasicWithViewIcon(dataObj4, res, column);
        break;
      case COLUMN_TYPES.ENUM:
        cellElement = (
          <TableCell>{column.mapping[res[column.fieldName]]}</TableCell>
        );
        break;
      default:
        cellElement = <TableCell>{res[column.fieldName]}</TableCell>;
    }
    return cellElement;
  };
  const checkMngDoc = isManageDocument ? "resp-table-managedoc" : "resp-table";
  const tableClassByScreen =
    isdistributorContent || isenterpriseContent
      ? "resp-table-dist-content"
      : checkMngDoc;
  return (
    <div className="admin-table">
      <Confirmation
        open={openUnBlockUserPopup}
        handleClose={() => {
          setOpenUnBlockUserPopup(false);
        }}
        title="Are you sure you want to unlock the account?"
        onCancel={() => {
          setOpenUnBlockUserPopup(false);
          setIds(null);
        }}
        onConfirm={() => {
          onUnBlockUserHandler();
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />
      <TableContainer component={Paper} className="table-container">
        {!props.paginationNotRequired && !props.serachBarNotRequired && (
          <div className="table-content">
            {!props.paginationNotRequired && (
              <p>
                <span className="mr-2">Show</span>
                <select
                  onChange={(e) => props.onPageSizeChange(e.target.value)}
                  className="select"
                  id="cars"
                  name="cars"
                  data-testid="pageSizeCombo"
                >
                  <option value="10" selected>
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="ml-2">entries</span>
              </p>
            )}
            {!props.serachBarNotRequired && (
              <div className="table-search-boxx">
                <TextField
                  className="table-search-box"
                  onChange={props.onSearch}
                  type="text"
                  placeholder="Search Everything..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className="search-icon-div">
                          <img
                            src="/images/search-icon.png"
                            alt="search icon"
                            name="search-icon"
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div className={tableClassByScreen} ref={tableContainerRef}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            id={props.uniqueKey}
          >
            <TableHead>
              <TableRow>
                {!props.checkboxesNotRequired && (
                  <TableCell style={{ width: "56px" }}>
                    <div className="col-details">
                      <Checkbox
                        role={"checkbox"}
                        name="selectUser"
                        data-testid="select-all"
                        checked={
                          props.adminTable?.length > 0 &&
                          props.adminTable?.filter((user) => user.isChecked)
                            .length === props.adminTable?.length
                        }
                        onChange={(event) =>
                          props.onCheckboxChange(event, "all")
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        style={{ paddingLeft: 0 }}
                        disableRipple
                      />
                    </div>
                  </TableCell>
                )}
                {columns?.map(
                  (column) =>
                    column.isSelected && (
                      <TableCell
                        className={column.isFixed ? "sticky-header" : ""}
                        style={
                          column.isFixed
                            ? {
                                minWidth: column.width + "px",
                                maxWidth: column.width + "px",
                                right: column.rightPadding + "px",
                              }
                            : {}
                        }
                      >
                        <div className="col-details">
                          <h6
                            className="col-details-heading table-column"
                            style={{ width: column.width }}
                          >
                            {column.label}
                            {column.sort && (
                              <img
                                className="sort-icon"
                                data-testid={`sort-by-${column.fieldName}`}
                                src={getSortImage(column.fieldName)}
                                alt="sort"
                                onClick={() => {
                                  handleSort(column.fieldName);
                                  props.onSort(
                                    props.query.SortType,
                                    column.fieldName
                                  );
                                }}
                              />
                            )}
                          </h6>
                        </div>
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.adminTable?.map((res, index) => {
                return (
                  <TableRow
                    key={res[uniqueKey]}
                    className={
                      index % 2 === 0 ? "table-row-grey" : "table-row-white"
                    }
                  >
                    {!props.checkboxesNotRequired && (
                      <TableCell style={{ width: "56px" }}>
                        <Checkbox
                          role="checkbox"
                          name={`selectuser-${res[uniqueKey]}`}
                          data-testid={`select-${res[uniqueKey]}`}
                          onChange={(event) =>
                            props.onCheckboxChange(event, res[uniqueKey])
                          }
                          checked={res.isChecked || false}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          style={{ paddingLeft: 0 }}
                          disableRipple
                        />
                      </TableCell>
                    )}

                    {columns.map((column) => {
                      return column.isSelected && renderTableCell(column, res);
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {props.totalPages > 1 && (
          <div className="table-pagination">
            <Pagination
              page={props.page}
              onChange={props.handleChangePage}
              rowsPerPage={10}
              count={props.totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        )}
      </TableContainer>
    </div>
  );
};
export default DataTable;

function columnTypeDocType(res, column) {
  const whenNotContent =
    res[column.fieldName] === DocumentType.CATALOG ? "Catalog" : "";
  let cellElement = (
    <TableCell>
      {res[column.fieldName] === DocumentType.CONTENT
        ? "Content"
        : whenNotContent}
    </TableCell>
  );
  return cellElement;
}
function classWhenMultiValue(column) {
  return column.isFixed ? " approval-roles-visible " : "";
}
/*istanbul ignore next */
function columnTypeAction(dataObj) {
  const whenNotRoleMgmt =
    (dataObj.res.publishStatus === ContentPublishStatus.MODIFIED &&
      dataObj.res.status === 0) ||
    (dataObj.userSelectedRoleId === SystemUsersEnum.DISTRIBUTOR &&
      dataObj.res.publishStatus === ContentPublishStatus.PUBLISHED &&
      dataObj.res.status === 0) ? (
      <img
        src="/images/edit_disable.png"
        height={20}
        alt="Edit"
        title="Edit"
        style={{
          cursor: "default",
        }}
        data-testid={`EditIcon${dataObj.res[dataObj.uniqueKey]}`}
      />
    ) : (
      dataObj.permissions &&
      dataObj.permissions.includes(PermissionEnum.UPDATE) && (
        <img
          src={"/images/edit_enable.png"}
          height={20}
          alt="Edit"
          title="Edit"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            dataObj.onEdit(dataObj.res, dataObj.res.documentType);
          }}
          data-testid={`EditIcon${dataObj.res[dataObj.uniqueKey]}`}
        />
      )
    );
  /*istanbul ignore next */
  const OnNotRoleMgmt =
    dataObj.userSelectedRoleId === SystemUsersEnum.DISTRIBUTOR &&
    dataObj.res.publishStatus === ContentPublishStatus.PUBLISHED &&
    dataObj.res.status === 0 ? (
      <img
        src="/images/view_disable.png"
        height={20}
        alt="View"
        title="View"
        style={{ cursor: "normal" }}
        data-testid={`ViewIcon${dataObj.res[dataObj.uniqueKey]}`}
      />
    ) : (
      <img
        src="/images/view_enable.png"
        height={20}
        alt="View"
        title="View"
        style={{ cursor: "pointer" }}
        onClick={() => dataObj.onView(dataObj.res, dataObj.res.documentType)}
        data-testid={`ViewIcon${dataObj.res[dataObj.uniqueKey]}`}
      />
    );
  const ifNotRoleMgmt = dataObj.res.isFavorite ? (
    <img
      src="/images/favorite_enable.png"
      height={20}
      alt="Favorite"
      title="Favorite"
      style={{ cursor: "pointer" }}
      data-testid={`favorite${dataObj.res[dataObj.uniqueKey]}`}
    />
  ) : (
    <img
      src="/images/unfavorite_enable.png"
      height={20}
      alt="Favorite"
      title="Favorite"
      style={{ cursor: "pointer" }}
      data-testid={`favorite${dataObj.res[dataObj.uniqueKey]}`}
    />
  );
  let cellElement = (
    <TableCell
      align="right"
      className={
        dataObj.column.isFixed ? "sticky-col action-cell" : "action-cell"
      }
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      <Grid container className="action-item-icons">
        {dataObj.showEdit && (
          <Grid item p={1}>
            {window.location.pathname === "/role-management" ? (
              <AddEditRoleModal
                isEventListner={true}
                featureListDetails={dataObj.props.featureListDetails}
                featureListDetails1={dataObj.props.featureListDetails1}
                isEditMode={true}
                isAdd={false}
                isView={true}
                onClick={dataObj.props.onClick}
                data={dataObj.res}
                onEditSuccess={dataObj.props.onEditSuccess}
                adminDetails={dataObj.adminDetails}
                testid={dataObj.res[dataObj.uniqueKey]}
                permissions={dataObj.permissions}
              />
            ) : (
              whenNotRoleMgmt
            )}
          </Grid>
        )}
        {dataObj.showView && (
          <Grid item p={1}>
            {window.location.pathname === "/role-management" ? (
              <AddEditRoleModal
                isEventListner={true}
                adminDetails={dataObj.adminDetails}
                featureListDetails={dataObj.props.featureListDetails}
                featureListDetails1={dataObj.props.featureListDetails1}
                isEditMode={false}
                onClick={dataObj.props.onClick}
                data={dataObj.res}
                onEditSuccess={dataObj.props.onEditSuccess}
                testid={dataObj.res[dataObj.uniqueKey]}
              />
            ) : (
              OnNotRoleMgmt
            )}
          </Grid>
        )}
        {dataObj.showFavorite && (
          <Grid item p={1}>
            {dataObj.showFavorite && dataObj.column.options && (
              <Grid item p={1}>
                {window.location.pathname === "/role-management" ? (
                  <AddEditRoleModal
                    isEventListner={true}
                    adminDetails={dataObj.adminDetails}
                    featureListDetails={dataObj.props.featureListDetails}
                    featureListDetails1={dataObj.props.featureListDetails1}
                    isEditMode={false}
                    onClick={dataObj.props.onClick}
                    data={dataObj.res}
                    onEditSuccess={dataObj.props.onEditSuccess}
                    testid={dataObj.res[dataObj.uniqueKey]}
                  />
                ) : (
                  ifNotRoleMgmt
                )}
              </Grid>
            )}
          </Grid>
        )}
        {dataObj.showFavorite && dataObj.column.options && (
          <Grid item ml={1}>
            {dataObj.auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
              dataObj.permissions &&
              dataObj.permissions.includes(PermissionEnum.UPDATE) && (
                <ThreeDotMenu
                  actionHandler={dataObj.actionHandler}
                  options={dataObj.column?.options?.map((data) => {
                    return {
                      ...data,
                      disabled: dataObj.res[data.status],
                      res: dataObj.res,
                      isDistributor:
                        dataObj.userSelectedRoleId ===
                        SystemUsersEnum.DISTRIBUTOR,
                    };
                  })}
                ></ThreeDotMenu>
              )}
          </Grid>
        )}
        {dataObj.showDeleteDocument && (
          <img
            src="/images/delete_enable.png"
            height={20}
            alt="Delete"
            title=""
            style={{ marginRight: "25px", cursor: "pointer" }}
            data-testid={`DeleteDocument${dataObj.res[dataObj.uniqueKey]}`}
            onClick={() => {
              dataObj.props.onDelete(dataObj.res);
            }}
          />
        )}
      </Grid>
    </TableCell>
  );
  return cellElement;
}
/*istanbul ignore next */
function columnTypeActiveInactiveLabel(res, column) {
  let cellElement = (
    <TableCell>{res[column.fieldName] ? "Active" : "Inactive"}</TableCell>
  );
  return cellElement;
}

function columnTypeStatusChange(dataObj) {
  const whenNotToggleSwitch = dataObj.res[dataObj.column.fieldName]
    ? "Active"
    : "Inactive";
  let cellElement = (
    <TableCell
      className={dataObj.column.isFixed ? "sticky-col" : ""}
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      {dataObj.auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
      isToggleSwitch(
        dataObj.isPathEnt,
        dataObj.isPathEntr,
        dataObj.isPathEntrs,
        dataObj.isPathContentManageSubscribers,
        dataObj.isPathCatalogManageSubscribers,
        dataObj.isPathBundleManageSubscribers,
        dataObj.isPathApprovalSubscribers
      ) ? (
        <ToggleSwitch
          activeLabelText={dataObj.column.activeLabelText}
          inactiveLabelText={dataObj.column.inactiveLabelText}
          success={dataObj.props.success}
          disabled={disableToggleSwitch(
            dataObj.column,
            dataObj.auth,
            dataObj.isPathEnt,
            dataObj.isPathEntr,
            dataObj.isPathEntrs,
            dataObj.res
          )}
          associate={associateToggleSwitch(
            dataObj.column,
            dataObj.contentSubscriberFieldName,
            dataObj.contentAssociationFieldName
          )}
          notassociate={notAssociateToggleSwitch(
            dataObj.column,
            dataObj.contentSubscriberFieldName,
            dataObj.contentAssociationFieldName
          )}
          id={`${dataObj.res[dataObj.uniqueKey]}${dataObj.column.fieldName}`}
          state={dataObj.res[dataObj.column.fieldName]}
          onChange={(e) => {
            onChangeToggleSwitch(
              dataObj.props,
              dataObj.column,
              dataObj.res,
              dataObj.contentAssociationFieldName,
              e,
              dataObj.uniqueKey,
              dataObj.handleOpen
            );
          }}
          open={dataObj.open}
        />
      ) : (
        whenNotToggleSwitch
      )}
    </TableCell>
  );
  return cellElement;
}
/*istanbul ignore next */
function onChangeToggleSwitch(
  props,
  column,
  res,
  contentAssociationFieldName,
  e,
  uniqueKey,
  handleOpen
) {
  props.onToggle(
    column.fieldName,
    res.userId,
    column.fieldName === contentAssociationFieldName
      ? res.contentAssociationStatus
      : res.contentSubscriberStatus,
    e,
    res
  );
  if (
    res[uniqueKey] &&
    res[uniqueKey] === props.currentUserId &&
    uniqueKey !== "contentId"
  ) {
    toast.error("You are not allowed to conduct this action.");
  }
  handleOpen();
}
/*istanbul ignore next */
function notAssociateToggleSwitch(
  column,
  contentSubscriberFieldName,
  contentAssociationFieldName
) {
  return column.fieldName === contentSubscriberFieldName ||
    column.fieldName === contentAssociationFieldName
    ? decision[2]
    : status[2];
}

function associateToggleSwitch(
  column,
  contentSubscriberFieldName,
  contentAssociationFieldName
) {
  return column.fieldName === contentSubscriberFieldName ||
    column.fieldName === contentAssociationFieldName
    ? decision[1]
    : status[1];
}
/*istanbul ignore next */
function disableToggleSwitch(
  column,
  auth,
  isPathEnt,
  isPathEntr,
  isPathEntrs,
  res
) {
  return `${column.cssClass}` === "status-change" ||
    column.fieldName === status ||
    (auth.getRole() === SystemUsersEnum.ENTERPRISE &&
      (isPathEnt || isPathEntr || isPathEntrs)) ||
    res.publishStatus === ContentPublishStatus.MODIFIED ||
    res.disableToggleButton
    ? true
    : false;
}

function isToggleSwitch(
  isPathEnt,
  isPathEntr,
  isPathEntrs,
  isPathContentManageSubscribers,
  isPathApprovalSubscribers,
  isPathCatalogManageSubscribers,
  isPathBundleManageSubscribers
) {
  return (
    isPathEnt ||
    isPathEntr ||
    isPathEntrs ||
    isPathContentManageSubscribers ||
    isPathCatalogManageSubscribers ||
    isPathBundleManageSubscribers ||
    isPathApprovalSubscribers
  );
}
/*istanbul ignore next */
function columnTypeUnblockToggle(
  res,
  props,
  setOpenUnBlockUserPopup,
  setIds,
  column
) {
  let cellElement = (
    <TableCell>
      <ToggleSwitch
        dataTestId="accountStatus"
        activeLabelText="Unlocked"
        inactiveLabelText="Locked"
        state={!res.isLocked}
        id={res.userId}
        onChange={() => {
          if (res.userId === props.currentUserId) {
            toast.error("You are not allowed to conduct this action.");
            return;
          }
          if (res.isLocked) {
            setOpenUnBlockUserPopup(true);
            setIds(res.userId);
          } else {
            //we can block user is not written on story so we are not doing any action on active/unblocked user
          }
        }}
      />
    </TableCell>
  );
  return cellElement;
}

function columnTypeMultiValue(res, column) {
  let cellElement = (
    <TableCell
      className={classWhenMultiValue(column)}
      title={
        res[column.fieldName] &&
        res[column.fieldName].flatMap((r) => r.name).join(", ")
      }
    >
      {res[column.fieldName] &&
        res[column.fieldName].flatMap((r) => r.name).join(", ")}
    </TableCell>
  );
  return cellElement;
}

function columnTypeEmail(res, column) {
  let cellElement = (
    <TableCell>
      <a className="primary-email-url" href={`mailto:${res[column.fieldName]}`}>
        {res[column.fieldName]}
      </a>
    </TableCell>
  );
  return cellElement;
}
function columnTypeStyledBasicWithViewIcon(dataObj, res, column) {
  const showViewIcon =
    window.location.pathname.endsWith("approvals") && res.status === 2 ? (
      <InfoOutlinedIcon
        src="/images/view_enable.png"
        height={18}
        alt="View"
        title="View"
        style={{ cursor: "pointer" }}
        onClick={() => dataObj.onReject(res)}
        data-testid={`ViewIcon${res[dataObj.uniqueKey]}`}
        className="custom-info-icon-datatable help-text-color"
      />
    ) : (
      <></>
    );

  let cellElement = (
    <TableCell>
      <Grid container alignItems="center">
        <Grid item>
          <span
            className={`styled-basic-cell-${res[
              column.fieldName
            ].toLowerCase()}`}
          >
            {res[column.fieldName]}
          </span>
        </Grid>
        {dataObj.showView && (
          <Grid item ml={1}>
            {showViewIcon}
          </Grid>
        )}
      </Grid>
    </TableCell>
  );

  return cellElement;
}
/*istanbul ignore next */
function columnTypeDate(res, column) {
  let cellElement =
    res[column.fieldName] === null ? (
      <TableCell></TableCell>
    ) : (
      <TableCell>
        {moment(res[column.fieldName]).format("MM/DD/YYYY")}
      </TableCell>
    );
  return cellElement;
}

export { DataTable, columnTypeDate, columnTypeEmail };
