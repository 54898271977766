import { createContext, useState, useMemo } from "react";

export const MasterDataInit = {
  masterDataValue: localStorage.getItem("masterData")
    ? JSON.parse(localStorage.getItem("masterData"))
    : {},
  setMasterData(data) {
    localStorage.setItem("masterData", JSON.stringify(data));
    this.masterDataValue = data;
  },
};

export const MasterDataContext = createContext(MasterDataInit);

const MasterDataContextProvider = ({ children }) => {
  const [masterDataValue, setMasterData] = useState({});
  const value = useMemo(() => ({
    masterDataValue,
    setMasterData,
  }), [masterDataValue]); 
  return (
    <MasterDataContext.Provider value={value}>
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataContextProvider;
