import React, { useState, useEffect } from "react";
import ApiServices from "../../services/ApiServices";
import { MASTER_SETTINGS } from "../../constants/api-constants";
import { SETTING_KEYS } from "../../constants/common";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";

const OtpTimer = ({ resendOTP, postSubmitLoading, handleOtpExpiry }) => {
  const { showLoader } = useLoading();
  const initialTime = 0;
  const [timer, setTimer] = useState(initialTime);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    settings();
    const countdown = setInterval(() => {
      setTimer((prevTimer) => Math.max(0, prevTimer - 1));
    }, 1000);

    window.onbeforeunload = () => {
      clearInterval(countdown);
    };

    return () => {
      clearInterval(countdown);
      window.onbeforeunload = null;
    };
  }, []);

  const settings = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(MASTER_SETTINGS);
      if (response.responseStatus?.isSuccess && response.result) {
        showLoader(false);
        const otpObj = response.result.filter(
          (el) => el.key === SETTING_KEYS.OTP_VALID_IN_SECONDS
        );
        setTimer(otpObj?.[0].value);
        setIsInitial(false);
      } else {
        showLoader(false);
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (timer === 0 && !isInitial) {
      handleOtpExpiry(true);
    }
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div class="otp-timer">
      {timer > 0 ? (
        <>
          <span>
            Resend One-Time Password in{" "}
            <span style={{ fontWeight: "bold", color: "#07bc0c" }}>
              {minutes}:{formattedSeconds}
            </span>{" "}
          </span>
        </>
      ) : (
        <span
          data-testid="resendOtp"
          class="resend-otp"
          disabled={postSubmitLoading}
          onClick={() => {
            settings();
            handleOtpExpiry(false);
            resendOTP();
          }}
        >
          Resend One-Time Password
        </span>
      )}
    </div>
  );
};

export default OtpTimer;
