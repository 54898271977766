import React from "react";
import { MODE } from "../../../constants/common";
import AddEditCatalog from "./AddEditCatalog/AddEditCatalog";
import CatalogList from "./CatalogList/CatalogList";

function Catalog(props) {
  const {
    id,
    distributorId,
    catalogId,
    mode,
    onSave,
    onCancel,
    onEdit,
    onNext,
    query,
    onView,
    showColumnPopup,
    onQueryChange,
    anchorEl,
    setAnchorEl,
    signUpApproval,
    permissions,
    setContentId,
    setContentMode,
  } = props;

  return (
    <div>
      {mode === MODE.LIST ? (
        <CatalogList
          id={id}
          query={query}
          showColumnPopup={showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onView={onView}
          onEdit={onEdit}
          setQuery={onQueryChange}
          setAnchorEl={setAnchorEl}
          signUpApproval={signUpApproval}
          permissions={permissions}
        />
      ) : (
        <AddEditCatalog
          id={id}
          anchorEl={anchorEl}
          query={query}
          distributorId={distributorId}
          catalogId={catalogId}
          setQuery={onQueryChange}
          setSelectedSubTab={props.setSelectedSubTab}
          mode={mode}
          onEdit={onEdit}
          onSave={onSave}
          onView={onView}
          onCancel={onCancel}
          onNext={onNext}
          showColumnPopup={showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onSaveTableColumnSelection={props.onSaveTableColumnSelection}
          setSelectionColumns={props.setSelectionColumns}
          setAnchorEl={setAnchorEl}
          signUpApproval={signUpApproval}
          permissions={permissions}
          setContentId={setContentId}
          setContentMode={setContentMode}
          routeData={props.routeData}
        />
      )}
    </div>
  );
}

export default Catalog;
