export const getFileExtension = (fname) => {
  return fname
    .slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1)
    .toLowerCase();
};

export const amountFormat = (amount) => {
  const doller = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return doller.format(amount);
};

export const getFrequency = (frequencyValue) => {
  frequencyValue = Number(frequencyValue);

  if (frequencyValue === 1) {
    return 1;
  } else if (frequencyValue === 2) {
    return 3;
  } else if (frequencyValue === 3) {
    return 6;
  } else if (frequencyValue === 4) {
    return 12;
  } else if (frequencyValue === 99) {
    return 1;
  } else {
    return 0;
  }
};

export const handleStateChange = (
  event,
  stateNames,
  selected,
  setSelected,
  states
) => {
  const NA_VAL = "Not Applicable";
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    let sNames = stateNames.filter((e) => e !== NA_VAL);
    setSelected(selected.length === stateNames.length - 1 ? [] : sNames);

    return selected.length === stateNames.length - 1 ? [] : sNames;
  } else if (value.includes(NA_VAL) || states?.includes(NA_VAL)) {
    if (selected.includes(NA_VAL)) {
      setSelected(value.filter((e) => e !== NA_VAL));
      return value.filter((e) => e !== NA_VAL);
    } else {
      setSelected([NA_VAL]);
      return [NA_VAL];
    }
  } else {
    setSelected(value);
    return value;
  }
};

export const fetchImageAsBase64 = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const base64Data = reader.result.split(",")[1]; // Get the Base64 data portion
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

export const getPhoneCode = (countryOptions, countryId) => {
  if (Number(countryId) > 0) {
    return countryOptions
      ?.filter((el) => el.countryId === Number(countryId))
      ?.map((item) => item.phoneCode);
  } else {
    return ["+1"];
  }
};
