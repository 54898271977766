import * as Yup from "yup";
import { baseSubscriptionSchema } from "../ValidationSchema/ContentSchema";

const schema = {
  name: Yup.string().required("").min(2, "Too Short!").max(150, "Too Long!"),
  latestVersion: Yup.string()
    .required("")
    .min(1, "Too Short!")
    .max(25, "Too Long!"),
  subscriptions: Yup.array().of(baseSubscriptionSchema),
};

export const BundleSchema = Yup.object().shape(schema);
