import FileSaver from "file-saver";

const ExportToExcelHandler = (fileContents, fileName) => {
  let sliceSize = 1024 * 1024 * 2; // file size upto 2mb
  let byteCharacters = window.atob(fileContents);
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);
    let bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  FileSaver.saveAs(
    new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
    fileName
  );
};

export default ExportToExcelHandler;
