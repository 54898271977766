import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Filter from "../../common/Filter/Filter";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import "./BundlePage.css";
import { FILTER_OPTION } from "../../constants/filterOptions";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  GetSelectedColumns,
} from "../../utilities/SelectionFilter";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { useLoading } from "../../hooks/useLoader";
import ApiServices from "../../services/ApiServices";
import { BUNDLE_EXPORT, DISTRIBUTORS } from "../../constants/api-constants";
import { MODE, TABS } from "../../constants/common";
import { useDistributorProfileContext } from "../Distributors/DistributorProfile.context";
import { useHistory } from "react-router";
import BundleList from "../Distributors/Bundle/BundleList/BundleList";
import { useBundleContext } from "../Distributors/Bundle/Bundle.context";
import { usePageMode } from "../../hooks/usePageMode";
import { useAuth } from "../../hooks/useAuth";
import { useMasterData } from "../../hooks/useMasterData";
import { PermissionService } from "../../constants/permissionService";
import {
  SystemModulesEnum,
  SystemUsersEnum,
} from "../../constants/SystemUsers";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";

const BundlePage = () => {
  const auth = useAuth();
  const id =
    auth.getRole() === 3 || auth.getRole() === 5
      ? auth.getCurrentUser()?.userId
      : 0;

  const bundleContext = useBundleContext();
  const page = usePageMode();
  const distributorProfileContext = useDistributorProfileContext();
  const history = useHistory();
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { showLoader } = useLoading();
  const [contentQuery, setContentQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "bundleID",
    PageNumber: 1,
    PageSize: 10,
    IsShowChildUserData: false,
    filter: {
      lineOfBusinessList: [],
      typeOfFormList: [],
      documentType: [],
    },
  });
  const [bundleFilterOptions, setBundleFilterOptions] = React.useState(
    FILTER_OPTION.BUNDLE_LIST
  );
  const [tabPermissions, setTabPermissions] = useState([]);
  const masterDataContext = useMasterData();

  useEffect(() => {
    loadDropndownOptions();
    let moduleId = MenuListItems.find(
      (item) => item.name === SystemModulesEnum.BUNDLE
    ).moduleId;
    const currentTabPermissions = PermissionService(moduleId);
    setTabPermissions(currentTabPermissions);
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCancelTableColumnSelection = (value) => {
    setTableColumnModalVisibility(value);
  };

  const loadDropndownOptions = async () => {
    try {
      const response = masterDataContext.masterDataValue;
      const bundleOptions = [...bundleFilterOptions];
      // LOB
      bundleOptions[3].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Countries
      bundleOptions[1].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      bundleOptions[5].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      // DISTRIBUTORS
      {
        const queryString = encodeURIComponent(
          JSON.stringify({
            filter: { documentType: 3, filterFrom: "CONTENTLIST" },
          })
        );
        const filteredResponses = await ApiServices.httpGet(
          `${DISTRIBUTORS}?query=${queryString}`
        );
        bundleOptions[2].options = filteredResponses.result.map((r) => {
          return {
            value: r.userId,
            roleId: r.userId,
            label: r.name,
          };
        });
      }
      setBundleFilterOptions(bundleOptions);
    } catch (e) {}
  };

  const applyContentFilterHandler = (filter) => {
    ClearSelectionData();
    setContentQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        filter: {
          lineOfBusiness:
            filter.lineOfBusiness?.map((item) => {
              return { lineOfBusinessId: item };
            }) || [],
          typeOfForm:
            filter.typeOfFormList?.map((item) => {
              return { typeOfFormId: item };
            }) || [],
          countries:
            filter.countries?.map((item) => {
              return { countryId: item };
            }) || [],
          states:
            filter.states?.map((item) => {
              return { stateId: item };
            }) || [],
          contentType:
            filter.contentType?.map((item) => {
              return item;
            }) || [],
          status:
            filter.status?.map((item) => {
              return item;
            }) || [],
          publishStatus:
            filter.publishStatus?.map((item) => {
              return item;
            }) || [],
          userIds:
            filter.userIds?.map((item) => {
              return item;
            }) || [],
        },
      };
    });
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("contentId");
      let queryState = { ...contentQuery };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.filter.selectedColumns = GetSelectedColumns(11);
      if (
        !queryState.filter.selectedColumns.some((col) => col === "Distributor")
      ) {
        queryState.filter.selectedColumns.splice(2, 0, "Distributor");
      }
      let exportedContent;
      exportedContent = await ApiServices.httpGet(
        `${BUNDLE_EXPORT}/0/0/${encodeURIComponent(JSON.stringify(queryState))}`
      );

      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      }
      showLoader(false);
    } catch (e) {
      console.error("error");
    }
  };

  const onViewHandler = (content) => {
    distributorProfileContext.setSelectedTab(TABS.BUNDLE);
    bundleContext.setSelectedTab(TABS.CONTENT_LIST);
    bundleContext.setBundleMode(MODE.VIEW);
    bundleContext.setBundleId(content.contentId);
    page.setBreadcrumbRootNode({
      title: "Bundle",
      path: "/bundle",
    });
    let location =
      page.breadcrumbRoute && auth.getRole() === SystemUsersEnum.ENTERPRISE
        ? "enterprisegroup"
        : "distributor";
    history.push(`/${location}/${content.userId}/bundle/${TABS.CONTENT_LIST}`);
  };

  const onEditHandler = (content) => {
    distributorProfileContext.setSelectedTab(TABS.BUNDLE);
    bundleContext.setSelectedTab(TABS.BUNDLE_INFORMATION);
    bundleContext.setBundleMode(MODE.EDIT);
    bundleContext.setBundleId(content.contentId);
    page.setBreadcrumbRootNode({
      title: "Bundle",
      path: "/bundle",
    });
    let location =
      page.breadcrumbRoute && auth.getRole() === SystemUsersEnum.ENTERPRISE
        ? "enterprisegroup"
        : "distributor";
    history.push(
      `/${location}/${content.userId}/bundle/${TABS.BUNDLE_INFORMATION}`
    );
  };

  const onQueryChange = (updatedQuery) => {
    setContentQuery(updatedQuery);
  };

  return (
    <Grid spacing={2} container className="content-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Bundle</h5>
            {[SystemUsersEnum.DISTRIBUTOR].includes(auth.getRole()) && (
              <Typography style={{ fontSize: "12px" }}>
                Bundles below does not include Sub Distributor Group Bundles.
              </Typography>
            )}
            {[SystemUsersEnum.ENTERPRISE].includes(auth.getRole()) && (
              <Typography style={{ fontSize: "12px" }}>
                Bundles below does not include Sub Enterprise Group Bundles.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className="top-right-actions">
            <div className="add-welcome-widgets">
              <Tooltip placement="bottom" title="Filter">
                <FilterAltOutlinedIcon
                  onClick={handleClick}
                  title="filter-icon"
                />
              </Tooltip>
              <Filter
                anchorEl={anchorEl}
                id={id}
                open={open}
                options={bundleFilterOptions}
                onApplyFilter={applyContentFilterHandler}
                onClose={handleClose}
              />
              <Tooltip placement="bottom" title="Change View">
                <BorderColorIcon
                  data-testid="TableColumnSelectionIcon"
                  onClick={() => {
                    setTableColumnModalVisibility(true);
                  }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title="Export to Excel">
                <IosShareRoundedIcon
                  data-testid="IosShareRoundedIcon"
                  onClick={handleExport}
                />
              </Tooltip>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pr={1} pl={1} pb={0}>
          <BundleList
            id={id}
            query={contentQuery}
            showColumnPopup={tableColumnModalVisibility}
            onCancelTableColumnSelection={onCancelTableColumnSelection}
            onView={onViewHandler}
            onEdit={onEditHandler}
            setQuery={onQueryChange}
            permissions={tabPermissions}
            hideSubscriberAssociationOption={[
              SystemUsersEnum.ADMIN,
              SystemUsersEnum.SUPERADMIN,
            ].includes(auth.getRole())}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BundlePage;
