function authHeader(role, token) {
  return {
    "Content-Type": "application/json",
    role: role || "0",
    Authorization: token ? `Bearer ${token}` : null,
  };
}

const httpGet = (endpoint) => {
  return fetch(endpoint, {
    headers: authHeader(),
  })
    .then((response) => handleResponse(response))
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const httpPost = (endpoint, payload) => {
  return fetch(endpoint, {
    method: "post",
    body: JSON.stringify(payload),
    headers: authHeader(payload.password, payload.token),
  })
    .then((response) => handleResponse(response))
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const handleResponse = (response) => {
  return response.json();
};

export default {
  httpGet,
  httpPost,
};
