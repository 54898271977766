import { SystemUsersEnum } from "../constants/SystemUsers";

const filterRoles = (userRoleId, roles) => {
  let userFilteredRoles = [];
  if (!roles) {
    return userFilteredRoles;
  }
  if (userRoleId === SystemUsersEnum.SUPERADMIN) {
    userFilteredRoles = roles.filter((item) => item.roleTypes[0].userType); //all roles
  } else if (userRoleId === SystemUsersEnum.ADMIN) {
    userFilteredRoles = roles.filter(
      (item) => item.roleTypes[0].userType !== SystemUsersEnum.SUPERADMIN // all roles except super admin
    );
  } else if (userRoleId === SystemUsersEnum.DISTRIBUTOR) {
    userFilteredRoles = roles.filter(
      (item) => item.roleTypes[0].userType === SystemUsersEnum.DISTRIBUTOR
    );
  } else if (userRoleId === SystemUsersEnum.ENTERPRISE) {
    userFilteredRoles = roles.filter(
      (item) => item.roleTypes[0].userType === SystemUsersEnum.ENTERPRISE
    );
  } else if (userRoleId === SystemUsersEnum.SUBSCRIBER) {
    userFilteredRoles = roles.filter(
      (item) => item.roleTypes[0].userType === SystemUsersEnum.SUBSCRIBER
    );
  } else {
    userFilteredRoles = [];
  }
  return userFilteredRoles;
};

export default filterRoles;
