import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
  Tabs,
  Tab,
  Modal,
  Select,
} from "@mui/material";
import "./ConfirmApproval.css";
import React, { useEffect, useState, useRef } from "react";
import MultiSelectDropDown from "../../common/MultiSelectDropDown/MultiSelectDropDown";
import PhoneNumberTextbox from "../../common/PhoneNumberTextbox/PhoneNumberTextbox";
import SearchableDropDown from "../../common/SearchableDropDown/SearchableDropdown";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiServices from "../../services/ApiServices";
import {
  ACTION_STATUS,
  APPROVAL_ENTERPRISE_SUBSCRIBERS,
  APPROVAL_LIST,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
  ENTERPRISE_GROUP_USER,
  PARENT_DISTRIBUTOR,
  UPDATE_APPROVAL,
  SUBCRIPTION_DETAILS,
  ASSOCIATE_CONTENT,
} from "../../constants/api-constants";
import { formatDate } from "../../utilities/formatter";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { toast } from "react-toastify";
import { TABS, execeptionMessage } from "../../constants/common";
import { useLoading } from "../../hooks/useLoader";
import { AssociationStatusEnum } from "../../common/Modal/AssociationStatusEnum";
import ConfirmationWithReason from "../../common/ConfirmationWithReason/ConfirmationWithReason";
import { Link } from "react-router-dom";
import { useApprovalContext } from "../ApprovalRequests/Approval.context";
import { usePageMode } from "../../hooks/usePageMode";
import { useMasterData } from "../../hooks/useMasterData";
import CurrencyTextbox from "../../common/CurrencyTextbox/CurrencyTextbox";
import { FrequencyEnum } from "../../constants/SystemUsers";
import TabPanel from "../../common/TabPanel/TabPanel";
import DataTable from "../Tables/DataTable/DataTable";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ToggleSwitch from "../../common/Switch/ToggleSwitch";
import Confirmation from "../../common/Confirmation/Confirmation";
import FileUploader from "../../common/FileUploader/FileUploader";
import {
  getModuleId,
  PermissionEnum,
  PermissionService,
} from "../../constants/permissionService";

/*istanbul ignore next */
const ConfirmApproval = (props) => {
  const approvalContext = useApprovalContext();
  const { showLoader } = useLoading();
  const page = usePageMode();
  const [state, setState] = useState({});
  const [parentDropdown, setParentDropdown] = useState([]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([]);
  const [membershipId, setMembershipId] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [distSubPlanId, setDistSubPlanId] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [career, setCareer] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [isEditMode, setIsEditMode] = useState(approvalContext.isEditMode);
  const [frequency, setFrequency] = useState(null);
  const [associatonStatus, setAssociatonStatus] = useState(null);
  const [recurring, setRecurring] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [userData, setUserData] = useState({});
  const [expanded, setExpanded] = useState("panel1");
  const [isValid, setIsValid] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [distData, setDistData] = useState("");
  const { requestId } = useParams();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [subscriptionDetailsList, setSubscriptionDetailsList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TABS.BASIC);
  const [entData, setEntData] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [toggleData, setToggleData] = useState(null);

  const [showSubscriptionDetailsPopup, setShowSubscriptionDetailsPopup] =
    useState(false);
  const [expandedList, setExpandedList] = React.useState([]);
  const [subscriptionPopupDetailsList, setSubscriptionPopupDetailsList] =
    useState([]);
  const [popupSubscriptionDetails, setPopupSubscriptionDetails] = useState("");
  const modalSubsStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    padding: "0px",
  };

  const myRef = useRef(null);
  const masterDataContext = useMasterData();

  const [query] = useState({
    searchText: "",
    PageSize: 0,
    PageNumber: 1,
    IgnorePaging: true,
    SortColumn: "createdOn",
    SortType: "desc",
  });

  let distributorDetailsFiltered = parentDropdown.find(
    (el) => el?.userId == distData
  );

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const permissions = PermissionService(
    getModuleId(props.routeData?.userTypeId)
  );
  const handleToggleClick = (a, b, c, e, res) => {
    setToggleData(res);
    setShowConfirmation(true);
  };

  const frequencyOptions = [
    {
      value: 1,
      label: "Monthly",
      months: 1,
    },
    {
      value: 2,
      label: "Quarterly",
      months: 3,
    },
    {
      value: 3,
      label: "Semi-Annual",
      months: 6,
    },
    {
      value: 4,
      label: "Annual",
      months: 12,
    },
  ];
  const getApprovalDetail = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${APPROVAL_LIST}/${requestId}`
      );
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        setState(response.result);
        setMembershipId(response.result.associationId);
        setSubscriptionType(response.result.distributorSubscriptionId);
        setDistSubPlanId(response.result.distributorSubscriptionPlanId);
        setAssociatonStatus(response.result.associatonStatus);
        setFrequency(response.result.frequencyType);
        setRecurring(response.result.isRecurring);
        setExpirationDate(response.result.expirationDate);
        setDistData(response.result.distributorUserId);
        setEntData(response.result.requestId);
        setIsEditMode((prevState) => {
          return !response.result.isUserInactive && prevState;
        });
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (err) {
      toast.error(execeptionMessage);
    }
  };

  const updateApproval = async (associatonStatus, rejectReason) => {
    try {
      showLoader(true);
      const payload = {
        requestId: state.approvalId, //state.requestId,
        enterpriseUserId: state.enterpriseUserId,
        distributorUserId: state.distributorUserId,
        associationId: membershipId, // state.associationId,
        frequencyType: Number(frequency), // state.frequencyType,
        expirationDate: expirationDate,
        distributorSubscriptionId: subscriptionType, // state.distributorSubscriptionId,
        distributorSubscriptionPlanId: distSubPlanId, // state.distributorSubscriptionPlanId,
        rejectReason:
          associatonStatus === AssociationStatusEnum.Rejected
            ? rejectReason
            : "",
        isRecurring: recurring,
        isCreate: true,
        associatonStatus: associatonStatus,
      };
      const response = await ApiServices.httpPost(UPDATE_APPROVAL, payload);
      page.setDirty(false);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
      return response;
    } catch (err) {
      toast.error(execeptionMessage);
      return err;
    }
  };

  const adminDetails = async () => {
    try {
      showLoader(true);
      let payload = {
        Filter: {
          requestId: entData,
          ...query.filter,
        },
        ...query,
      };
      let response = await ApiServices.httpPost(
        APPROVAL_ENTERPRISE_SUBSCRIBERS,
        payload
      );
      if (response.responseStatus.isSuccess) {
        let subsdetails = response.result?.records?.map((x) => {
          return {
            firstName: x.firstName,
            lastName: x.lastName,
            email: x.email,
            status:
              associatonStatus === AssociationStatusEnum.Approved &&
              x.associationStatus,
            activitystatus: x.status,
            associateSubId: x.userId,
            disableToggleButton:
              !x.status > 0 ||
              !isEditMode ||
              !(associatonStatus === AssociationStatusEnum.Approved),
          };
        });
        subsdetails?.sort((a, b) => {
          if (!a.activitystatus > 0 && b.activitystatus > 0) return 1;
          if (a.activitystatus > 0 && !b.activitystatus > 0) return -1;
          return 0;
        });
        setSubscriptionDetailsList(subsdetails);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const populateDropdownParent = async () => {
    try {
      const response = await ApiServices.httpGet(PARENT_DISTRIBUTOR);
      setParentDropdown(response.result);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const populateDropdownSubscriptionType = async () => {
    try {
      const response = await ApiServices.httpGet(
        `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${state.distributorUserId}`
      );
      setSubscriptionTypeOptions(
        response.result.map((item) => {
          return {
            value: item.distributorSubscriptionId,
            subscriptionPlan: item.subscriptionPlan,
            label: item.name,
          };
        })
      );
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const handleConfirmToggle = async () => {
    try {
      const response = await ApiServices.httpPost(ACTION_STATUS, {
        status: toggleData.status > 0 ? 0 : 1,
        requestId: entData,
        userId: toggleData.associateSubId,
      });
      if (response.responseStatus.isSuccess) {
        adminDetails();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.description);
      }
    } catch (event) {
      toast.error("Internal server error");
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleCancelToggle = () => {
    setShowConfirmation(false);
  };
  const setMasterData = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      setEnterpriseTypeOptions(
        response.userTypeOptions.map((item) => {
          return { value: item.enterpriseTypeId, label: item.name };
        })
      );
      setAreaOptions(response.areas);
      setCareer(response.carrierRepresents);
      setIndustry(response.industryGroups);
      setTechnology(response.technologies);
    } catch (e) {
      showLoader(false);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleIsValid = (type, valid) => {
    if (type === "frequency") {
      setIsValid(
        (membershipId ? true : false) && subscriptionType > 0 && valid
      );
    } else if (type === "membershipId") {
      setIsValid(
        valid &&
          subscriptionType > 0 &&
          frequency > 0 &&
          getSubscriptionfees(frequency, subscriptionType) > 0 &&
          (expirationDate ? true : false)
      );
    } else {
      setIsValid(valid);
    }
  };

  const history = useHistory();

  useEffect(() => {
    setMasterData();
    getApprovalDetail();
    setSelectionColumns();
  }, [requestId]);

  useEffect(() => {
    if (selectedTab === TABS.ASSOCIATED_SUBSCRIBER) {
      adminDetails();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      userDetails();
      populateDropdownParent();
      populateDropdownSubscriptionType();
    }
  }, [state]);

  const userDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${ENTERPRISE_GROUP_USER}/${state.enterpriseUserId}`
    );
    showLoader(false);
    setUserData(data.result);
  };
  const onEdit = () => {
    setIsEditMode(true);
  };
  const agentOrBroker = userData?.enterpriseTypes?.some(
    (x) => x.name === "Agents / Broker"
  );

  const onApprove = async () => {
    await updateApproval(AssociationStatusEnum.Approved);
    setTimeout(() => {
      history.push("/approvals");
    }, 3000);
  };

  const onReject = () => {
    handleConfirmationOpen(
      "Reject",
      "Are you sure you want to reject this request?"
    );
  };

  const getSubscriptionfees = (frequency, subscriptionType) => {
    if (!frequency || !subscriptionType) return 0;
    return subscriptionTypeOptions
      .find((obj) => obj.value === parseInt(subscriptionType))
      ?.subscriptionPlan.find(
        (x) => Number(x.distributorSubscriptionPlanId) === Number(distSubPlanId)
      )?.baseValue;
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols.approvalAssociated
      ? selectedCols.approvalAssociated
      : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };
  const handleChangeExpanded = (accordion) => {
    let expandedListLocal = [...expandedList];
    if (isExpanded(accordion)) {
      expandedListLocal = expandedListLocal.filter((i) => i !== accordion);
    } else {
      expandedListLocal.push(accordion);
    }
    setExpandedList(expandedListLocal);
  };

  const isExpanded = (accordion) => {
    return expandedList.some((i) => i === accordion);
  };

  const getSubscriptionDetails = async () => {
    try {
      showLoader(true);
      let payload = {
        distributorId: distData,
        distributorSubscriptionId: subscriptionType,
        frequency: frequency,
        requestId: entData,
      };
      let response = await ApiServices.httpPost(SUBCRIPTION_DETAILS, payload);
      if (response.responseStatus.isSuccess) {
        let subContents = response.result?.associatedContents?.map((x) => {
          return {
            documentType: x.documentType,
            contentId: x.contentId,
            name: x.name,
            contents: x.contents,
            contentAssociationStatus: x.isContentSubscriptionActive
              ? x.contentAssociationStatus
              : 0,
            activitystatus: x.isContentSubscriptionActive,
          };
        });
        setSubscriptionPopupDetailsList(subContents);
        setPopupSubscriptionDetails(
          subscriptionTypeOptions?.find(
            (st) => Number(st.value) === Number(subscriptionType)
          )?.label
        );
      } else {
        toast.success(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  const getSubsDetails = () => {
    getSubscriptionDetails();
    setShowSubscriptionDetailsPopup(true);
  };
  const renderSubscriptionDetailsPopup = () => {
    const sortedList = [...subscriptionPopupDetailsList];
    sortedList.sort((i, j) => i.documentType - j.documentType);
    sortedList.sort((i, j) => j.activitystatus - i.activitystatus);
    function closeSubsPopup() {
      setExpandedList([]);
      setSubscriptionPopupDetailsList([]);
      setPopupSubscriptionDetails("");
      setShowSubscriptionDetailsPopup(false);
    }

    return (
      <Modal
        open={showSubscriptionDetailsPopup}
        onClose={() => {
          closeSubsPopup();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalSubsStyle}>
          <div className="subscription-popup-design">
            <Grid container>
              <Grid
                item
                xs={10}
                style={{ marginTop: "15px", marginLeft: "15px" }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 0,
                    fontWeight: 800,
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  {popupSubscriptionDetails}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.5}
                onClick={() => {
                  closeSubsPopup();
                }}
                style={{
                  cursor: "pointer",
                  justifyContent: "end",
                  display: "flex",
                  marginTop: "5px",
                }}
              >
                <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid
              container
              sx={{ backgroundColor: "#eee" }}
              className="accordion-class-1"
            >
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: "16px",
                  }}
                >
                  Details
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    fontWeight: 800,
                    fontSize: "16px",
                    paddingRight: "16px",
                  }}
                >
                  Associated
                </Typography>
              </Grid>
            </Grid>
            {subscriptionPopupDetailsList &&
              subscriptionPopupDetailsList.length > 0 &&
              sortedList.map((x) => {
                return x.contents && x.contents.length > 0 ? (
                  <Accordion
                    className="accordion-class-2"
                    key={`accordion-${x.contentId}`}
                    expanded={isExpanded(`accordion-header-${x.contentId}`)}
                    onChange={() =>
                      handleChangeExpanded(`accordion-header-${x.contentId}`)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                      aria-controls="panel1a-content"
                      // id="panel1a-header"
                      className="accordion-class-4"
                    >
                      {loadTextHeading(x, true)}
                    </AccordionSummary>

                    <AccordionDetails className="accordion-class-3">
                      <div>
                        {x.contents.map((y) => {
                          return y.contents && y.contents.length > 0 ? (
                            <Accordion
                              className="accordion-class-2"
                              key={`accordion-${x.contentId}-${y.contentId}`}
                              expanded={isExpanded(
                                `accordion-header-${x.contentId}-${y.contentId}`
                              )}
                              onChange={() =>
                                handleChangeExpanded(
                                  `accordion-header-${x.contentId}-${y.contentId}`
                                )
                              }
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon style={{ color: "black" }} />
                                }
                                aria-controls="panel1a-content"
                                // id="panel1a-header"
                                className="accordion-class-4 accordion-class-7"
                              >
                                <Typography sx={{ fontWeight: "700" }}>
                                  {y.name}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="accordion-class-3">
                                <div>
                                  {y.contents.map((z) => {
                                    return (
                                      <div
                                        className="accordion-class-1 accordion-class-5"
                                        ref={myRef}
                                        key={`accordion-${x.contentId}-${y.contentId}-${z.contentId}`}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: "700",
                                          }}
                                        >
                                          {z.name}
                                        </Typography>
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <div
                              className="accordion-class-1 accordion-class-7"
                              ref={myRef}
                            >
                              <Typography sx={{ fontWeight: "700" }}>
                                {y.name}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <div>{loadTextHeading(x, false)}</div>
                );
              })}
          </div>
        </Box>
      </Modal>
    );
  };

  const updateContentAssociation = async (e, item) => {
    try {
      const response = await ApiServices.httpPost(ASSOCIATE_CONTENT, {
        status: item.contentAssociationStatus > 0 ? 0 : 1,
        enterpriseUserId: state.enterpriseUserId,
        requestId: entData,
        contentId: item.contentId,
      });
      if (response.responseStatus.isSuccess) {
        getSubscriptionDetails();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.description);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  const loadTextHeading = (x, isMainAccordian) => {
    return (
      <Grid container>
        <Grid
          item
          xs={isMainAccordian ? 10.5 : 11}
          className={
            isMainAccordian ? "accordion-class-6" : "accordion-class-1"
          }
        >
          {x.activitystatus > 0 ? (
            <Typography sx={{ fontWeight: "700" }}>{x.name}</Typography>
          ) : (
            <Typography sx={{ fontWeight: "700", color: "lightgray" }}>
              {x.name}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <ToggleSwitch
            disabled={
              !x.activitystatus > 0 ||
              !isEditMode ||
              !(associatonStatus === AssociationStatusEnum.Approved)
            }
            id={`accordion-toggle-${x.contentId}`}
            state={(x.contentAssociationStatus || 0) > 0}
            onChange={(e) => {
              updateContentAssociation(e, x);
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const newLocalConfirmationMessage =
    toggleData?.status > 0
      ? "The subscriber will be disassociated from this subscription and and their access to all content/catalog/bundle will be revoked immediately. Are you sure you want continue with this action?"
      : "Performing this action will re-associate the subscriber with this subscription. This will grant them access to all the content, catalogs, and bundles included in this subscription. Are you sure you want to proceed with this action?";
  return (
    <div className="approval-container">
      <div className="approval-basic-details">
        <div>
          <div className="approval-page-title">Association Request</div>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="distributor-breadcrumbs"
          >
            <Link underline="hover" color="inherit" to="/approvals">
              Approval
            </Link>

            <Typography color="text.primary" className="last-node">
              Basic Information
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="modal-footer">
          <Button
            className="action-button mr-4"
            variant="contained"
            onClick={() => history.push("/approvals")}
          >
            Cancel
          </Button>

          {!state?.isUserInactive && isEditMode ? (
            <Button
              className="action-button mr-4"
              variant="contained"
              onClick={async () => {
                await updateApproval(AssociationStatusEnum.Pending);
                setTimeout(() => {
                  history.push("/approvals");
                }, 3000);
              }}
              disabled={
                !isValid ||
                [AssociationStatusEnum.Approved].includes(associatonStatus)
              }
            >
              Save
            </Button>
          ) : (
            ((permissions && permissions.includes(PermissionEnum.CREATE)) ||
              (permissions && permissions.includes(PermissionEnum.UPDATE))) && (
              <Button
                className="action-button mr-4"
                variant="contained"
                onClick={onEdit}
                disabled={state?.isUserInactive}
              >
                Edit
              </Button>
            )
          )}

          <Button
            className="action-button mr-4"
            variant="contained"
            color="success"
            onClick={onApprove}
            disabled={
              !(
                !state?.isUserInactive &&
                [
                  AssociationStatusEnum.Pending,
                  AssociationStatusEnum.Rejected,
                ].includes(associatonStatus) &&
                isEditMode &&
                isValid
              )
            }
          >
            Approve
          </Button>

          <Button
            className="action-button mr-4"
            variant="contained"
            color="error"
            onClick={onReject}
            disabled={
              !(
                !state?.isUserInactive &&
                [AssociationStatusEnum.Pending].includes(associatonStatus) &&
                isEditMode &&
                isValid
              )
            }
          >
            Reject
          </Button>
        </div>
      </div>
      <Grid container spacing={2} paddingLeft={4} marginTop={2}>
        <Grid item xs={2.2} p={2}>
          <div>Requested To</div>
          <div className="approval-basic-info">
            {distributorDetailsFiltered?.brandName}
          </div>
        </Grid>
        <Grid item xs={2.4} p={2}>
          <div>Distributor Full Name</div>
          <div className="approval-basic-info">
            {distributorDetailsFiltered?.name.split(" ").slice(0, 2).join(" ")}
          </div>
        </Grid>
        <Grid item xs={2.4} p={2}></Grid>
        <Grid item xs={2.4} p={2}></Grid>
        <Grid item xs={2.4} p={2}></Grid>
      </Grid>
      <Grid container spacing={2} paddingLeft={4} marginTop={2}>
        <Grid item xs={2.2} p={2}>
          <div>Requested By</div>
          <div className="approval-basic-info">{userData?.brandName}</div>
        </Grid>
        <Grid item xs={2.3} p={2}>
          <div>Full Name</div>
          <div className="approval-basic-info">
            {userData?.firstName + " " + userData?.lastName}
          </div>
        </Grid>
        <Grid item xs={2.7} p={2}>
          <div>Email</div>
          <div className="approval-basic-info">{userData?.email}</div>
        </Grid>
        <Grid item xs={2.4} p={2}>
          <div>System Generated ID</div>
          <div className="approval-basic-info">
            {userData?.systemGeneratedId}
          </div>
        </Grid>
        <Grid item xs={2.4} p={2}>
          <div>Registration Date</div>
          <div className="approval-basic-info">
            {formatDate(userData?.createdOn)}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0} paddingLeft={4} marginTop={2}>
        <Grid
          item
          md={9}
          lg={11}
          sm={12}
          xs={12}
          // className="distributor-profile-form custom-marginleft custom-width-box position-fix-panel"
        >
          <Box
            p={1}
            className="right-section"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => {
                adminDetails();
                setSelectedTab(newValue);
              }}
            >
              <Tab
                className="my-tabs"
                label={"Basic Information"}
                value={TABS.BASIC}
                disabled={false}
              />
              <Tab
                className="my-tabs"
                label={"Associated Subscribers"}
                value={TABS.ASSOCIATED_SUBSCRIBER}
                disabled={false}
              />
            </Tabs>

            <TabPanel
              value={selectedTab}
              index={TABS.BASIC}
              className="distributor-tab-panel"
            >
              <div className="parent-div-accord">
                <Accordion
                  className="accordion-ent"
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: "700" }}>
                      Basic Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className="modal-body">
                        <Grid
                          container
                          spacing={2}
                          className="blue-field-section"
                        >
                          <Grid item xs={4} p={2}>
                            <label
                              id="label-for-role-dropdown"
                              htmlFor="roles-dropdown"
                            >
                              Roles
                            </label>
                            <MandatoryField />
                            <Select
                              displayEmpty
                              data-testid="rolesDropdown"
                              id="roles-dropdown"
                              name="roles-dropdown"
                              labelId="label-for-role-dropdown"
                              className="form-select form-control select-user roles-dropdown"
                              multiple
                              disabled={true}
                              value={userData?.roles?.map(
                                (role) => role.roleId
                              )}
                              title={userData?.roles?.map(
                                (role) => role.roleId
                              )}
                              renderValue={() => {
                                if (userData?.roles?.length === 0) {
                                  return "Select Roles";
                                }
                                return userData?.roles
                                  ?.map((item) => item.name)
                                  .join(", ");
                              }}
                            ></Select>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="firstName">Brand Name</label>
                            <MandatoryField />
                            <input
                              id="brandName"
                              name="brandName"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Brand Name"
                              disabled
                              maxLength={25}
                              value={userData?.brandName}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="firstName">First Name</label>
                            <MandatoryField />
                            <input
                              id="firstName"
                              name="firstName"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter First Name"
                              disabled
                              maxLength={25}
                              value={userData?.firstName}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="lastName">Last Name</label>
                            <MandatoryField />
                            <input
                              id="lastName"
                              name="lastName"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Last Name"
                              disabled
                              maxLength={25}
                              value={userData?.lastName}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="distributorType">
                              Enterprise Type
                            </label>
                            <MandatoryField />
                            <MultiSelectDropDown
                              name="distributorType"
                              id="distributorType"
                              disabled
                              value={userData?.enterpriseTypes?.map((item) => {
                                return {
                                  value: item.enterpriseTypeId,
                                  label: item.name,
                                };
                              })}
                              options={enterpriseTypeOptions}
                            />
                          </Grid>
                          {props?.routeData?.userTypeId === 7 && (
                            <Grid
                              item
                              sx={4}
                              p={2}
                              className="blue-field-section serachable-dropdown"
                            >
                              <label htmlFor="DistributorIDEnterpriseID">
                                Distributor ID / Enterprise ID
                              </label>
                              <MandatoryField />
                              <SearchableDropDown
                                name="DistributorIDEnterpriseID"
                                id="DistributorIDEnterpriseID"
                                placeholder="Select Enterprise/Distributor Id"
                                disabled
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="primaryEmail">
                              Primary Email Address
                            </label>
                            <MandatoryField />
                            <a
                              className="primary-email-url"
                              href={`mailto:${userData?.email}`}
                            >
                              {userData?.email}
                            </a>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label
                              htmlFor="application"
                              className="file-upload-label"
                            >
                              <span>Logo </span>
                              {getLogoText(userData)}
                            </label>
                            <FileUploader disabled={true} sourceFile={""} />
                            {userData?.logoGcpPath && (
                              <div>{userData.logoGcpPath}</div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="secondaryEmail">
                              Secondary Email Address
                            </label>
                            <input
                              id="secondaryEmail"
                              name="secondaryEmail"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Secondary Email Address"
                              disabled
                              value={userData?.secondaryEmail}
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="carrierRepresents">
                              Carriers represented
                            </label>
                            <MultiSelectDropDown
                              id="carrierRepresents"
                              name="carrierRepresents"
                              menuShouldBlockScroll
                              isMulti
                              value={userData?.carrierRepresents?.map((res) => {
                                return {
                                  value: res.carrierRepresentId,
                                  label: res.name,
                                };
                              })}
                              options={career.map((res) => {
                                return {
                                  value: res.carrierRepresentId,
                                  label: res.name,
                                };
                              })}
                              disabled
                            />
                          </Grid>

                          {agentOrBroker && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="areas">Major Areas</label>
                              <MultiSelectDropDown
                                id="areas"
                                name="areas"
                                isMulti
                                disabled
                                value={userData?.areas?.map((res) => {
                                  return {
                                    value: res.areaId,
                                    label: res.name,
                                  };
                                })}
                                options={areaOptions.map((res) => {
                                  return {
                                    value: res.areaId,
                                    label: res.name,
                                  };
                                })}
                              />
                            </Grid>
                          )}
                          {agentOrBroker && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="producers">
                                Number of producers
                              </label>
                              <input
                                id="producers"
                                name="producers"
                                className="form-label form-control"
                                type="text"
                                placeholder="Enter Number of producers"
                                disabled
                                value={userData?.producers}
                              />
                            </Grid>
                          )}
                          {agentOrBroker && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="industryGroups">
                                Industry Groups/ Membership
                              </label>
                              <MultiSelectDropDown
                                id="industryGroups"
                                name="industryGroups"
                                disabled
                                value={userData?.industryGroups?.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                                options={industry.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                              />
                            </Grid>
                          )}
                          {agentOrBroker && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="technologies">
                                Technology being used now
                              </label>
                              <MultiSelectDropDown
                                id="technologies"
                                name="technologies"
                                disabled
                                value={userData?.technologies?.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                                options={technology.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                              />
                            </Grid>
                          )}
                          <Grid item xs={4} p={2}>
                            <label htmlFor="businessYear">
                              Years in Business
                            </label>
                            <input
                              id="businessYear"
                              name="businessYear"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Years in Business"
                              disabled
                              value={userData?.businessYear}
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="websiteUrl">Website URL</label>
                            <input
                              id="websiteUrl"
                              name="websiteUrl"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Website URL"
                              disabled
                              value={userData?.websiteUrl}
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="annualRevenue">
                              Annual revenue
                            </label>
                            <select
                              id="annualRevenue"
                              name="annualRevenue"
                              className="form-control form-select"
                              disabled
                              value={userData?.annualRevenueId}
                            >
                              <option>Select Revenue</option>
                              <option value={1}>0-100k</option>
                              <option value={2}>101 to 500k</option>
                              <option value={3}>500k to 1,250,000</option>
                              <option value={4}>1,250,000 to 2,500,000</option>
                              <option value={5}>2,500,000 to 5,000,000</option>
                              <option value={6}>5,000,000 to 10,000,000</option>
                              <option value={7}>
                                10,000,000 to 25,000,000"
                              </option>
                              <option value={8}> &#62; 25,000,000</option>
                            </select>
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="numberOfEmployees">
                              Number of employees
                            </label>
                            <input
                              id="numberOfEmployees"
                              name="numberOfEmployees"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Number of employees"
                              disabled
                              value={userData?.numberOfEmployees}
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="numberOfOwners">
                              Number of principals / owners
                            </label>
                            <input
                              id="numberOfOwners"
                              name="numberOfOwners"
                              className="form-label form-control"
                              type="text"
                              value={userData?.numberOfOwners}
                              placeholder="Enter Number of principals / owners"
                              disabled
                            />
                          </Grid>
                          {!agentOrBroker && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="industryGroups">
                                Industry Groups/ Membership
                              </label>
                              <MultiSelectDropDown
                                id="industryGroups"
                                name="industryGroups"
                                disabled
                                value={userData?.industryGroups?.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                                options={industry.map((item) => {
                                  return {
                                    value: item.industryGroupId,
                                    label: item.name,
                                  };
                                })}
                              />
                            </Grid>
                          )}
                          <Grid item xs={4} p={2}>
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <MandatoryField />
                            <PhoneNumberTextbox
                              id="phoneNumber"
                              className="form-label form-control"
                              name="phoneNumber"
                              placeholder="Enter Business Phone Number"
                              value={userData?.phoneNumber}
                              disabled
                              phoneCode={["+1"]} // Country Code for USA and Canada
                            />
                          </Grid>
                          {userData?.secondaryPhoneNumber && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="secondaryPhoneNumber">
                                Secondary Phone Number
                              </label>
                              <PhoneNumberTextbox
                                id="secondaryPhoneNumber"
                                className="form-label form-control"
                                name="secondaryPhoneNumber"
                                placeholder="Enter Phone Number"
                                value={userData?.secondaryPhoneNumber}
                                disabled
                                phoneCode={["+1"]} // Country Code for USA and Canada
                              />
                            </Grid>
                          )}
                          <Grid item xs={4} p={2}>
                            <label htmlFor="businessName">Business Name</label>
                            <input
                              id="businessName"
                              name="businessName"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Business Name"
                              value={userData?.businessName}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="taxID">Tax Id</label>
                            <input
                              id="taxID"
                              name="taxID"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Tax ID"
                              value={userData?.taxID}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="mobile">Mobile</label>
                            <PhoneNumberTextbox
                              id="mobile"
                              className="form-label form-control"
                              name="mobile"
                              placeholder="Enter Mobile Number"
                              value={userData?.mobile}
                              disabled
                              phoneCode={["+1"]} // Country Code for USA and Canada
                            />
                          </Grid>
                          {userData?.mobile1 && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="mobile1">Alternate Mobile</label>
                              <PhoneNumberTextbox
                                id="mobile1"
                                className="form-label form-control"
                                name="mobile1"
                                placeholder="Enter Alternate Mobile Number"
                                value={userData?.mobile1}
                                disabled
                                phoneCode={["+1"]} // Country Code for USA and Canada
                              />
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            <label htmlFor="fax">Fax</label>
                            <PhoneNumberTextbox
                              id="fax"
                              className="form-label form-control"
                              name="fax"
                              placeholder="Enter Fax"
                              value={userData?.fax}
                              disabled
                              phoneCode={["+1"]} // Country Code for USA and Canada
                            />
                          </Grid>
                          {userData?.fax1 && (
                            <Grid item xs={4} p={2}>
                              <label htmlFor="mobile1">Alternate Fax</label>
                              <PhoneNumberTextbox
                                id="fax1"
                                className="form-label form-control"
                                name="fax1"
                                placeholder="Enter Alternate Fax"
                                value={userData?.fax1}
                                disabled
                                phoneCode={["+1"]} // Country Code for USA and Canada
                              />
                            </Grid>
                          )}
                          <Grid item xs={4} p={2}>
                            <label htmlFor="billingContact">
                              Billing First Name{" "}
                            </label>
                            <input
                              id="billingContact"
                              name="billingContact"
                              className="form-label form-control"
                              type="text"
                              placeholder="Billing First Name"
                              value={userData?.billingContact}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="billingContactLastName">
                              Billing Last Name{" "}
                            </label>
                            <input
                              id="billingContactLastName"
                              name="billingContactLastName"
                              className="form-label form-control"
                              type="text"
                              placeholder="Billing Last Name"
                              value={userData?.billingContactLastName}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="billingPhoneNumber">
                              Billing Phone Number
                            </label>
                            <PhoneNumberTextbox
                              id="billingPhoneNumber"
                              className="form-label form-control"
                              name="billingPhoneNumber"
                              placeholder="Enter Billing Phone Number"
                              value={userData?.billingPhoneNumber}
                              disabled
                              phoneCode={["+1"]} // Country Code for USA and Canada
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="billingAddressLine1">
                              Billing Address Line 1
                            </label>
                            <input
                              id="billingAddressLine1"
                              name="billingAddressLine1"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Billing Address Line 1 "
                              value={userData?.billingAddressLine1}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="billingAddressLine2">
                              Billing Address Line 2
                            </label>
                            <input
                              id="billingAddressLine2"
                              name="billingAddressLine2"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Billing Address Line 2"
                              value={userData?.billingAddressLine2}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="countryId">Country</label>
                            <select
                              id="countryId"
                              name="countryId"
                              className="form-control form-select"
                              disabled
                              value={userData?.countryId}
                            >
                              <option value={1}>USA</option>
                            </select>
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="state">State/Province</label>
                            <input
                              id="state"
                              name="state"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter State"
                              disabled
                              value={userData?.state}
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="city">City</label>
                            <input
                              name="city"
                              id="city"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter City"
                              value={userData?.city}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            {userData?.countryId == "2" ? (
                              <label htmlFor="zip">Postal Code</label>
                            ) : (
                              <label htmlFor="zip">Zip Code</label>
                            )}
                            <input
                              id="zip"
                              name="zip"
                              className="form-label form-control"
                              type="text"
                              maxLength={userData?.countryId == "2" ? 7 : 10}
                              placeholder={
                                userData?.countryId == "2"
                                  ? "Enter Postal Code"
                                  : "Enter Zip Code"
                              }
                              value={userData?.zipCode}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className="accordion-ent"
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={{ fontWeight: "700" }}>
                      Association Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="blue-field-section">
                      <Grid item className="top-right-actions" xs={12}>
                        <Button
                          variant="contained"
                          className="action-button"
                          onClick={(e) => {
                            getSubsDetails();
                          }}
                        >
                          Subscription Details
                        </Button>
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="membershipId">
                          Membership/Acc Number
                        </label>
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                        <input
                          id="membershipId"
                          name="membershipId"
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Membership/Acc Number"
                          disabled={
                            !isEditMode ||
                            associatonStatus === AssociationStatusEnum.Approved
                          }
                          maxLength={25}
                          value={membershipId}
                          onChange={(e) => {
                            page.setDirty(true);
                            setMembershipId(e.target.value);
                            handleIsValid(
                              "membershipId",
                              e.target.value ? true : false
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="subscriptionType">
                          Subscription Type
                        </label>
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                        <select
                          id="subscriptionType"
                          name="subscriptionType"
                          value={subscriptionType}
                          onChange={(e) => {
                            page.setDirty(true);
                            setSubscriptionType(Number(e.target.value));
                            setExpirationDate("");
                            setFrequency(0);
                            handleIsValid("subscriptionType", false);
                          }}
                          className="form-control form-select"
                          disabled={
                            !isEditMode ||
                            associatonStatus === AssociationStatusEnum.Approved
                          }
                        >
                          <option selected="true" disabled="true" value="0">
                            Select Membership
                          </option>
                          {subscriptionTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="membershipId">Frequency</label>
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                        <select
                          value={frequency}
                          onChange={(e) => {
                            page.setDirty(true);
                            let addMonths =
                              FrequencyEnum[Number(e.target.value)].count;
                            let today = new Date();
                            let expDate = new Date(
                              today.setMonth(today.getMonth() + addMonths)
                            );
                            expDate.setDate(expDate.getDate() - 1);
                            setExpirationDate(
                              moment(expDate).format("YYYY-MM-DD")
                            );
                            let distPlanId = subscriptionTypeOptions
                              .find(
                                (obj) =>
                                  obj.value === parseInt(subscriptionType)
                              )
                              ?.subscriptionPlan.find(
                                (x) =>
                                  Number(x.frequency) === Number(frequency) &&
                                  x.status > 0
                              )?.distributorSubscriptionPlanId;
                            setDistSubPlanId(Number(distPlanId));
                            setFrequency(Number(e.target.value));
                            handleIsValid("frequency", true);
                          }}
                          className="form-control form-select"
                          disabled={
                            !isEditMode ||
                            associatonStatus === AssociationStatusEnum.Approved
                          }
                        >
                          <option selected="true" disabled="true" value="0">
                            Select Frequency
                          </option>
                          {frequencyOptions
                            .filter((option) =>
                              subscriptionTypeOptions
                                ?.find(
                                  (st) =>
                                    Number(st.value) ===
                                    Number(subscriptionType)
                                )
                                ?.subscriptionPlan.find(
                                  (x) =>
                                    Number(x.frequency) ===
                                      Number(option.value) &&
                                    (!isEditMode ||
                                      associatonStatus ===
                                        AssociationStatusEnum.Approved ||
                                      x.status > 0)
                                )
                            )
                            .map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="associationId">
                          Subscription Amount
                        </label>
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                        <CurrencyTextbox
                          id="associationId"
                          name="associationId"
                          value={getSubscriptionfees(
                            frequency,
                            subscriptionType
                          )}
                          disabled
                          type="number"
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="recurring">Auto Renewal</label>
                        <FormControlLabel
                          className={
                            "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm"
                          }
                          label={<Typography>Auto Renewal</Typography>}
                          checked={recurring}
                          name="recurring"
                          data-testid="recurring-checkbox"
                          onChange={(e) => {
                            page.setDirty(true);
                            setRecurring(e.target.checked);
                          }}
                          control={<Checkbox />}
                          disabled={
                            !isEditMode ||
                            associatonStatus === AssociationStatusEnum.Approved
                          }
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="desktop-date-picker">
                        <label htmlFor="membershipId">Expiration Date</label>
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            disablePast
                            className="form-label form-control date-field"
                            inputFormat="MM/DD/YYYY"
                            data-testid="startdate"
                            disabled
                            max="9999-12-31"
                            type="date"
                            value={
                              expirationDate === ""
                                ? null
                                : formatDate(expirationDate)
                            }
                            onChange={(e) => {
                              page.setDirty(true);
                              e && e._d
                                ? setExpirationDate(
                                    moment(e._d).format("YYYY-MM-DD")
                                  )
                                : setExpirationDate("");
                            }}
                            renderInput={(params) => (
                              <TextField id="from" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {showSubscriptionDetailsPopup &&
                  renderSubscriptionDetailsPopup()}
                <ConfirmationWithReason
                  open={confirmation}
                  handleClose={handleConfirmationClose}
                  title={confirmationMessage}
                  cancelButtonLabel="Cancel"
                  confirmButtonLabel="Reject"
                  showReason={true}
                  onConfirm={async (e, rejectReason) => {
                    await updateApproval(
                      AssociationStatusEnum.Rejected,
                      rejectReason
                    );
                    setTimeout(() => {
                      handleConfirmationClose();
                      history.push("/approvals");
                    }, 3000);
                  }}
                  onCancel={() => {
                    handleConfirmationClose();
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={TABS.ASSOCIATED_SUBSCRIBER}
              className="distributor-tab-panel"
            >
              <div className="manage-subscriber-table">
                <DataTable
                  uniqueKey="requestId"
                  columns={selectedColumns}
                  query={query}
                  adminTable={subscriptionDetailsList}
                  showView={false}
                  showEdit={false}
                  checkboxesNotRequired={true}
                  paginationNotRequired={true}
                  serachBarNotRequired={true}
                  onToggle={handleToggleClick}
                />
              </div>
            </TabPanel>
            {showConfirmation && (
              <Confirmation
                open={showConfirmation}
                handleClose={handleConfirmationClose}
                title={newLocalConfirmationMessage}
                onCancel={() => {
                  handleCancelToggle();
                }}
                onConfirm={() => {
                  handleConfirmToggle();
                }}
                cancelButtonLabel="No"
                confirmButtonLabel="Yes"
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

/*istanbul ignore next */
function getLogoText(userData) {
  return userData?.logoGcpPath ? (
    <span className="filename">Replace File</span>
  ) : (
    <span className="filename">Choose File</span>
  );
}
export default ConfirmApproval;
