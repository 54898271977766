import { useContext, createContext } from "react";
import { MODE, TABS } from "../../../constants/common";
/*istanbul ignore next */
export const CatalogInit = {
  userId: 0,
  selectedTab: TABS.CATALOG_INFORMATION,
  subSelectedTab: TABS.CATALOG_INFORMATION,
  catalogId: 0,
  catalogMode: MODE.LIST,
  sourceCatalogId: 0,
  setSelectedTab(mode) {
    this.selectedTab = mode;
  },
  setSubSelectedTab(mode) {
    this.subSelectedTab = mode;
  },
  setCatalogId(catalogId) {
    this.catalogId = catalogId;
  },
  setCatalogMode(mode) {
    this.catalogMode = mode;
  },
  setUserId(userId) {
    this.userId = userId;
  },
  copyCatalog(val) {
    this.copy = true;
    this.sourceCatalogId = val.contentId;
  },
  resetCopyCatalog() {
    this.copy = false;
    this.sourceCatalogId = 0;
  },
};

export const CatalogContext = createContext(CatalogInit);

export const useCatalogContext = () => useContext(CatalogContext);
