import * as Yup from "yup";
import { REGULAR_EXPRESSION } from "../../constants/regular-expressions";
/*istanbul ignore next */
const phoneWithExtensionSchema = Yup.string()
  .test("phone-extension", "Incorrect format", (value) => {
    if (!value) return true; // Allow empty value
    const [phonePart, extensionPart] = value.split(",");
    return (
      REGULAR_EXPRESSION.PHONE_NUMBER.test(phonePart) &&
      (!extensionPart || /^\d{2,6}$/.test(extensionPart))
    );
  })
  .required("");
/*istanbul ignore next */
const secondaryPhoneWithExtensionSchema = Yup.string()
  .test("combined-validation", "Invalid number", (value, context) => {
    const phoneNumberRegex = REGULAR_EXPRESSION.PHONE_NUMBER;
    const [phonePart, extensionPart] = value ? value.split(",") : [null, null];
    const primaryPhoneNumber = context.parent.phoneNumber;

    if (!value) {
      return true;
    } // Allow empty value
    else if (phonePart === primaryPhoneNumber) {
      return false; // Must be different from primary phone number
    } else if (
      !phoneNumberRegex.test(phonePart) ||
      (extensionPart && !/^\d{2,6}$/.test(extensionPart))
    ) {
      return false; // Invalid format
    } else {
      return true; // Valid
    }
  })
  .nullable(true);

export const SignUpSchema = Yup.object().shape({
  brandName: Yup.string().required("").nullable(true),
  fax: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  mobile1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  fax1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  distributorType: Yup.array().min(1, "Select Distributor").required(""),
  email: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  secondaryEmail: Yup.string().email("Invalid email").nullable(true),
  phoneNumber: phoneWithExtensionSchema,
  secondaryPhoneNumber: secondaryPhoneWithExtensionSchema,
  billingPhoneNumber: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  zip: Yup.string()
    .matches(REGULAR_EXPRESSION.ZIP_CODE, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits")
    .nullable(true),
  websiteUrl: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Must be a valid url")
    .nullable(true),
  numberOfEmployees: Yup.string()
    .matches(REGULAR_EXPRESSION.ZIP_CODE, "Must be only digits")
    .nullable(true),
  numberOfOwners: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  businessYear: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  producers: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  taxID: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
});
