import React from "react";
import { ARGB_To_RGBA_Color } from "../constants";

function TextBlock(props) {
  const { item, fType } = props;
  const checkAcordColor =
    fType === "NonAcord"
      ? ARGB_To_RGBA_Color(item.Foreground)
      : item.Foreground;
  const getColor = item.Foreground ? checkAcordColor : "black";
  return (
    <span
      style={{
        width: item.Width ? item.Width : "auto",
        height: item.Height ? item.Height : "auto",
        position: "absolute",
        top: item.SetTop,
        left: item.SetLeft,
        fontFamily: item.FontFamily,
        fontSize: item.FontSize,
        fontStyle: item.FontStyle,
        fontWeight: item.FontWeight,
        textAlign: item.TextAlignment,
        textDecoration: item.TextDecorations,
        color: getColor,
      }}
    >
      {item.Text?.split(/[\r\n]+/).map((line, index) => (
        <span key={`${item.SetLeft}-${item.SetTop}`}>
          {line}
          {index < item.Text.split(/[\r\n]+/).length - 1 && <br />}
        </span>
      ))}
    </span>
    // <span style=" width: @item.Width; height: @item.Height;position: absolute;top: @item.SetTop;left: @item.SetLeft;font-size:@fontSize">@item.Text</span>
  );
}

export default TextBlock;
