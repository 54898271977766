import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Filter from "../../common/Filter/Filter";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ContentList from "../Distributors/Content/ContentList/ContentList";
import "./ContentPage.css";
import { FILTER_OPTION } from "../../constants/filterOptions";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  GetSelectedColumns,
} from "../../utilities/SelectionFilter";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { useLoading } from "../../hooks/useLoader";
import ApiServices from "../../services/ApiServices";
import { CONTENT_EXPORT, DISTRIBUTORS } from "../../constants/api-constants";
import { useContentContext } from "../Distributors/Content/Content.context";
import { MODE, TABS } from "../../constants/common";
import { useDistributorProfileContext } from "../Distributors/DistributorProfile.context";
import { useHistory } from "react-router";
import { usePageMode } from "../../hooks/usePageMode";
import UploadUserModal from "../../common/Modal/UploadUserModal";
import { useAuth } from "../../hooks/useAuth";
import { useMasterData } from "../../hooks/useMasterData";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";
import {
  SystemModulesEnum,
  SystemUsersEnum,
} from "../../constants/SystemUsers";
import { PermissionService } from "../../constants/permissionService";

const ContentPage = () => {
  const auth = useAuth();
  const id =
    auth.getRole() === 3 || auth.getRole() === 5
      ? auth.getCurrentUser()?.userId
      : 0;
  const contentContext = useContentContext();
  const page = usePageMode();
  const distributorProfileContext = useDistributorProfileContext();
  const history = useHistory();
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { showLoader } = useLoading();
  const [contentQuery, setContentQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "contentId",
    PageNumber: 1,
    PageSize: 10,
    IsShowChildUserData: false,
    filter: {
      lineOfBusinessList: [],
      typeOfFormList: [],
      TypeOfContentList: [],
      documentType: [],
      selectedContentOriginators: [],
    },
  });
  const [contentFilterOptions, setContentFilterOptions] = React.useState(
    FILTER_OPTION.CONTENT_LIST
  );

  const [tabPermissions, setTabPermissions] = useState([]);
  const masterDataContext = useMasterData();

  useEffect(() => {
    loadDropndownOptions();
    let moduleId = MenuListItems.find(
      (item) => item.name === SystemModulesEnum.CONTENT
    ).moduleId;
    const currentTabPermissions = PermissionService(moduleId);
    setTabPermissions(currentTabPermissions);
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCancelTableColumnSelection = (value) => {
    setTableColumnModalVisibility(value);
  };

  const setContentOriginators = async (list) => {
    const contentOptions = [...contentFilterOptions];
    let originators = contentOptions.find(
      (x) => x.field === "contentOriginators"
    );
    originators.options = list.map((r) => {
      return {
        value: r,
        roleId: r,
        label: r,
      };
    });
    setContentFilterOptions(contentOptions);
  };

  const loadDropndownOptions = async () => {
    try {
      const response = masterDataContext.masterDataValue;
      const contentOptions = [...contentFilterOptions];
      // LOB
      contentOptions[5].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Type of Form
      contentOptions[10].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      contentOptions[3].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      contentOptions[7].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      //Content Originator
      let originators = contentOptions.find(
        (x) => x.field === "contentOriginators"
      );
      originators.options = [];
      // DISTRIBUTORS
      {
        const queryString = encodeURIComponent(
          JSON.stringify({
            filter: { documentType: 1, filterFrom: "CONTENTLIST" },
          })
        );
        const filteredResponses = await ApiServices.httpGet(
          `${DISTRIBUTORS}?query=${queryString}`
        );
        contentOptions[4].options = filteredResponses.result.map((r) => {
          return {
            value: r.userId,
            roleId: r.userId,
            label: r.name,
          };
        });
      }
      setContentFilterOptions(contentOptions);
    } catch (e) {
      showLoader(false);
    }
  };
  const applyContentFilterHandler = (filter) => {
    ClearSelectionData();
    setContentQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        filter: {
          lineOfBusiness:
            filter.lineOfBusiness?.map((item) => {
              return { lineOfBusinessId: item };
            }) || [],
          typeOfForm:
            filter.typeOfForm?.map((item) => {
              return { typeOfFormId: item };
            }) || [],
          countries:
            filter.countries?.map((item) => {
              return { countryId: item };
            }) || [],
          states:
            filter.states?.map((item) => {
              return { stateId: item };
            }) || [],
          contentType:
            filter.contentType?.map((item) => {
              return item;
            }) || [],
          status:
            filter.status?.map((item) => {
              return item;
            }) || [],
          publishStatus:
            filter.publishStatus?.map((item) => {
              return item;
            }) || [],
          contentFillable:
            filter.contentFillable?.map((item) => {
              return item;
            }) || [],
          userIds:
            filter.userIds?.map((item) => {
              return item;
            }) || [],
          TypeOfContent:
            filter.TypeOfContent?.map((item) => {
              return item;
            }) || [],
          selectedContentOriginators: filter.contentOriginators || [],
        },
      };
    });
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("contentId");
      let queryState = { ...contentQuery };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.filter.selectedColumns = GetSelectedColumns(8);
      let exportedContent;
      exportedContent = await ApiServices.httpGet(
        `${CONTENT_EXPORT}/0/0/${encodeURIComponent(
          JSON.stringify(queryState)
        )}`
      );

      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      }
      showLoader(false);
    } catch (e) {
      console.error("error");
    }
  };

  const onViewHandler = (content, e) => {
    distributorProfileContext.setSelectedTab(TABS.CONTENT);
    contentContext.setContentId(content.contentId);
    contentContext.setSelectedTab(TABS.CONTENT_PREVIEW);
    contentContext.setContentMode(MODE.VIEW);
    page.setBreadcrumbRootNode({
      title: "Contents",
      path: "/content",
    });
    let location =
      page.breadcrumbRoute && auth.getRole() === SystemUsersEnum.ENTERPRISE
        ? "enterprisegroup"
        : "distributor";
    history.push(
      `/${location}/${content.userId}/content/${TABS.CONTENT_PREVIEW}`
    );
  };

  const onEditHandler = (content, e) => {
    distributorProfileContext.setSelectedTab(TABS.CONTENT);
    contentContext.setContentId(content.contentId);
    contentContext.setSubSelectedTab(TABS.CONTENT_INFORMATION);
    contentContext.setContentMode(MODE.EDIT);
    page.setBreadcrumbRootNode({
      title: "Contents",
      path: "/content",
    });

    let location =
      page.breadcrumbRoute && auth.getRole() === SystemUsersEnum.ENTERPRISE
        ? "enterprisegroup"
        : "distributor";
    history.push(
      `/${location}/${content.userId}/content/${TABS.CONTENT_INFORMATION}`
    );
  };

  const onQueryChange = (updatedQuery) => {
    setContentQuery(updatedQuery);
  };

  return (
    <Grid spacing={2} container className="content-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Content</h5>
            {[SystemUsersEnum.DISTRIBUTOR].includes(auth.getRole()) && (
              <Typography style={{ fontSize: "12px" }}>
                Contents below does not include Sub Distributor Group Contents.
              </Typography>
            )}
            {[SystemUsersEnum.ENTERPRISE].includes(auth.getRole()) && (
              <Typography style={{ fontSize: "12px" }}>
                Contents below does not include Sub Enterprise Group Contents.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className="top-right-actions">
            <div className="add-welcome-widgets-content">
              <Tooltip placement="bottom" title="Filter">
                <FilterAltOutlinedIcon
                  onClick={handleClick}
                  title="filter-icon"
                />
              </Tooltip>
              <Filter
                anchorEl={anchorEl}
                id={id}
                open={open}
                options={contentFilterOptions}
                onApplyFilter={applyContentFilterHandler}
                onClose={handleClose}
              />
              <Tooltip placement="bottom" title="Change View">
                <BorderColorIcon
                  data-testid="TableColumnSelectionIcon"
                  onClick={() => {
                    setTableColumnModalVisibility(true);
                  }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title="Export to Excel">
                <IosShareRoundedIcon
                  data-testid="IosShareRoundedIcon"
                  onClick={handleExport}
                />
              </Tooltip>
              {![SystemUsersEnum.ADMIN, SystemUsersEnum.SUPERADMIN].includes(
                auth.getRole()
              ) && (
                <UploadUserModal
                  from="content-manage-subscribers"
                  userId={id}
                  title={"Bulk Upload Content Subscribers"}
                  bulkUploadTemplate={
                    "/templates/Content_Subscriber_Bulk_Upload.xlsx"
                  }
                  setRefresh={applyContentFilterHandler}
                  contentQuery={contentQuery}
                  isSelected={page.isContentIdsSelected}
                />
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box pr={1} pl={1} pb={0}>
          <ContentList
            id={id}
            query={contentQuery}
            showColumnPopup={tableColumnModalVisibility}
            onCancelTableColumnSelection={onCancelTableColumnSelection}
            onView={onViewHandler}
            onEdit={onEditHandler}
            setQuery={onQueryChange}
            isMassUploadPermission={false} //no permission from content page nav side
            permissions={tabPermissions}
            hideSubscriberAssociationOption={[
              SystemUsersEnum.ADMIN,
              SystemUsersEnum.SUPERADMIN,
            ].includes(auth.getRole())}
            setContentOriginators={setContentOriginators}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContentPage;
