import { createContext, useState, useMemo } from "react";

const LoaderInit = {
  show: false,
  showLoader: null,
};

export const LoaderContext = createContext(LoaderInit);

const LoaderContextProvider = ({ children }) => {
  const [show, showLoader] = useState(false);
  const value = useMemo(() => ({ show, showLoader }), [show]); 
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export default LoaderContextProvider;
