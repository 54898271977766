import React from "react";
import PropTypes from "prop-types";

InlineMessage.propTypes = {
  error: PropTypes.string,
};

function InlineMessage(props) {
  const { error } = props;
  return error ? (
    <div
      style={{
        color: "red",
        position: "relative",
        fontSize: 14,
        width: "100%",
        whiteSpace: "normal",
      }}
    >
      {error}
    </div>
  ) : null;
}

export default InlineMessage;
