function authHeader() {
  const loginData = localStorage.getItem("loginData") || null;
  const myToken = loginData ? JSON.parse(loginData).token : null;
  const role = localStorage.getItem("roleId");
  if (myToken) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${myToken}`,
      role: role ? Number(role) : 0,
    };
  } else {
    return {
      "Content-Type": "application/json",
      role: role ? Number(role) : 0,
    };
  }
}

function authFileHeader() {
  const loginData = localStorage.getItem("loginData");
  const myToken = JSON.parse(loginData)?.token || null;
  const role = localStorage.getItem("roleId");
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${myToken}`);
  myHeaders.append("role", role ? Number(role) : 0);
  return myHeaders;
}

const handleTokenExpiry = (error) => {
  const loginData = localStorage.getItem("loginData") || null;
  const myToken = loginData ? JSON.parse(loginData).token : null;
  if (!myToken) window.location.href = "/login";
};

const httpGet = (endpoint, params) => {
  return fetch(endpoint, {
    headers: authHeader(),
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const httpPost = (endpoint, data) => {
  return fetch(endpoint, {
    method: "post",
    body: JSON.stringify(data),
    headers: authHeader(),
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const httpPostFile = (endpoint, data) => {
  return fetch(endpoint, {
    method: "post",
    body: data,
    headers: authFileHeader(),
    redirect: "follow",
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const httpPutFile = (endpoint, data) => {
  return fetch(endpoint, {
    method: "put",
    body: data,
    headers: authFileHeader(),
    redirect: "follow",
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const httpPut = (endpoint, data) => {
  return fetch(endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: authHeader(),
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const httpDelete = (endpoint, data) => {
  return fetch(endpoint, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: authHeader(),
  })
    .then((response) => handleResponse(response))
    .then((response) => handleResponseCode(response))
    .catch((error) => {
      handleTokenExpiry(error);
      throw error;
    });
};

const handleResponse = (response) => {
  if (response.status === 401) {
    localStorage.clear();
    // Clear the page content
    document.body.innerHTML = "";

    // Replace the current URL with a new one
    window.history.replaceState(null, null, window.location.href);
    localStorage.setItem("SessionExpired", true);
    window.location.href = "/login";
  } else {
    if (response.status === 403) {
      window.location.href = "/dashboard";
    }
  }
  return response.json();
};

const handleResponseCode = (response) => {
  if (response?.responseStatus?.code === 403) {
    console.log(response?.responseStatus?.error);
  }
  return response;
};

export default {
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  httpPostFile,
  httpPutFile,
};
