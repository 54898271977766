import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import OtpTimer from "./OtpTimer";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";

const AuthWidget2FA = ({ onSubmitOtp, postSubmitLoading, resendOTP }) => {
  const [otp, setOtp] = useState("");
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const handleOtpExpiry = (val) => {
    setIsOtpExpired(val);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitOtp(otp);
  };

  const handleOTP = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 6);
    setOtp(sanitizedValue);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} class="custom-auth-content">
        <div class="custom-fieldset">
          <label htmlFor="otp">
            One-Time Password
            <MandatoryField />
          </label>
          <TextField
            type="text"
            placeholder="Enter One-Time Password"
            disabled={postSubmitLoading}
            id="otp"
            value={otp}
            onChange={(e) => handleOTP(e)}
            variant="outlined"
            maxLength={6}
          />
        </div>
        <div class="custom-fieldset">
          <Button
            disabled={isOtpExpired || otp.length < 6 || postSubmitLoading}
            disableRipple="true"
            class="sign-in"
            id="sign-in-id"
            type="submit"
            variant="contained"
            color="primary"
            data-testid="confirm-otp"
          >
            Submit
          </Button>
        </div>
      </form>
      <div class="custom-fieldset">
        <OtpTimer
          postSubmitLoading={postSubmitLoading}
          resendOTP={resendOTP}
          handleOtpExpiry={handleOtpExpiry}
        />
      </div>
    </div>
  );
};

export default AuthWidget2FA;
