import {
  SUBSCRIBER,
  ENTERPRISE,
  GET_ED_USERS,
  SUBSCRIBER_EXPORT,
  DISTRIBUTORS,
} from "../../constants/api-constants";
import ApiServices from "../ApiServices";
import { IUserService } from "./IUserService";

export class SubscriberService extends IUserService {
  getUsers = async (query) => {
    const response = await ApiServices.httpGet(query);
    return response;
  };
  getUser = async (userId) => {
    const response = await ApiServices.httpGet(`${SUBSCRIBER}/${userId}`);
    return {
      ...response.result,
      userId: response.result.userId,
      userTypeId: response.result.userTypeId,
      userTypeName: response.result.userTypeName,
      systemGeneratedId: response.result.systemGeneratedId,
      createdOn: response.result.createdOn,
      firstName: response.result.firstName,
      lastName: response.result.lastName,
      primaryEmail: response.result.email,
      secondaryEmail: response.result.secondaryEmail,
      phoneNumber: response.result.phoneNumber,
      secondaryPhoneNumber: response.result.secondaryPhoneNumber,
      fax: response.result.fax,
      mobile: response.result.mobile,
      fax1: response.result.fax1,
      mobile1: response.result.mobile1,
      egSubscriptions: response.result.egSubscriptions,
      distributorType: response.result.subscriberTypes.map((data) => {
        return {
          label: data.name,
          value: data.subscriberTypeId,
        };
      }),
      areas: response.result.areas.map((data) => {
        return {
          label: data.name,
          value: data.areaId,
        };
      }),
      carrierRepresents: response.result.carrierRepresents.map((data) => {
        return {
          label: data.name,
          value: data.carrierRepresentId,
        };
      }),
      industryGroups: response.result.industryGroups.map((data) => {
        return {
          label: data.name,
          value: data.industryGroupId,
        };
      }),
      technologies: response.result.technologies.map((data) => {
        return {
          label: data.name,
          value: data.technologyId,
        };
      }),
      annualRevenue: response.result.annualRevenueId,
      annualRevenueText: response.result.annualRevenueText,
      billingContact: response.result.billingContact,
      billingContactLastName: response.result.billingContactLastName,
      businessYear: response.result.businessYear,
      websiteUrl: response.result.websiteUrl,
      numberOfEmployees: response.result.numberOfEmployees,
      numberOfOwners: response.result.numberOfOwners,
      producers: response.result.producers,
      billingPhoneNumber: response.result.billingPhoneNumber,
      billingAddressLine1: response.result.billingAddressLine1,
      billingAddressLine2: response.result.billingAddressLine2,
      zip: response.result.zipCode,
      city: response.result.city,
      stateId: response.result.stateId,
      countryId: response.result.countryId,
      id: response.result.userId,
      status: response.result.status,
      roles: response.result.roles,
      subscription: response.result.subscription,
      parentUser: response.result.parentUser,
      userAddOns: response.result.userAddOns,
      // parentUserId: { label: "", value: response.result.parentUserId },
      enterpriseIds: response.result.enterpriseIds,
      distributorIds: response.result.distributorIds,
    };
  };

  createUser = async (values) => {
    const payload = {
      ...values,
      status: 1,
      userId: 0,
      enterpriseIds:
        values.enterpriseIds?.map((el) =>
          el && typeof el == "object" ? el.value : el
        ) || [],
      distributorIds:
        values.distributorIds?.map((el) =>
          el && typeof el == "object" ? el.value : el
        ) || [],
      userTypeId: 7,
      firstName: this.getOrDefaultValue(values.firstName),
      lastName: this.getOrDefaultValue(values.lastName),
      email: this.getOrDefaultValue(values.primaryEmail),
      secondaryEmail: this.getOrDefaultValue(values.secondaryEmail),
      phoneNumber: this.getOrDefaultValue(values.phoneNumber),
      secondaryPhoneNumber: this.getOrDefaultValue(values.secondaryPhoneNumber),
      billingContact: this.getOrDefaultValue(values.billingContact),
      billingContactLastName: this.getOrDefaultValue(
        values.billingContactLastName
      ),
      billingPhoneNumber: this.getOrDefaultValue(values.billingPhoneNumber),
      billingAddressLine1: this.getOrDefaultValue(values.billingAddressLine1),
      billingAddressLine2: this.getOrDefaultValue(values.billingAddressLine2),
      zipCode: this.getOrDefaultValue(values.zip),
      countryId: values.countryId ? Number(values.countryId) : null,
      city: this.getOrDefaultValue(values.city),
      stateId: values.stateId ? Number(values.stateId) : null,
      businessYear: this.getOrDefaultValue(values.businessYear),
      websiteUrl: this.getOrDefaultValue(values.websiteUrl),
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      mobile: values.mobile,
      fax1: values.fax1,
      mobile1: values.mobile1,
      fax: values.fax,
      roles: values.roles,
      areas: values.areas.map((res) => {
        return {
          areaId: res.value,
          name: res.label,
        };
      }),
      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: res.value,
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: res.value,
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: res.value,
          name: res.label,
        };
      }),
      subscriberTypes: values.distributorType.map((res) => {
        return {
          subscriberTypeId: Number(res.value),
          name: res.label,
        };
      }),
      // subscription: {
      //   subscriptionId: values.subscription.subscriptionId,
      //   startDate: values.subscription.startDate,
      //   endDate: values.subscription.endDate,
      // },
    };
    const response = await ApiServices.httpPost(SUBSCRIBER, payload);
    return response;
  };
  updateUser = async (values) => {
    const payload = {
      ...values,
      status: values.status,
      userId: values.id,
      enterpriseIds:
        values.enterpriseIds?.map((el) =>
          el && typeof el == "object" ? el.value : el
        ) || [],
      distributorIds:
        values.distributorIds?.map((el) =>
          el && typeof el == "object" ? el.value : el
        ) || [],
      userTypeId: 7,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.primaryEmail,
      secondaryEmail: values.secondaryEmail,
      phoneNumber: values.phoneNumber,
      secondaryPhoneNumber: values.secondaryPhoneNumber,
      billingContact: values.billingContact,
      billingContactLastName: values.billingContactLastName,
      billingPhoneNumber: values.billingPhoneNumber,
      billingAddressLine1: values.billingAddressLine1,
      billingAddressLine2: values.billingAddressLine2,
      zipCode: values.zip,
      countryId: values.countryId ? Number(values.countryId) : null,
      city: values.city,
      stateId: values.stateId ? Number(values.stateId) : null,
      businessYear: values.businessYear,
      websiteUrl: values.websiteUrl,
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      systemGeneratedId: values.systemGeneratedId,
      mobile: values.mobile,
      egSubscriptions: values.egSubscriptions,
      fax: values.fax,
      fax1: values.fax1,
      mobile1: values.mobile1,
      roles: values.roles,
      areas: values.areas.map((res) => {
        return {
          areaId: Number(res.value),
          name: res.label,
        };
      }),
      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: Number(res.value),
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: Number(res.value),
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: Number(res.value),
          name: res.label,
        };
      }),
      subscriberTypes: values.distributorType.map((res) => {
        return {
          subscriberTypeId: Number(res.value),
          name: res.label,
        };
      }),
      // subscription: values.subscription,
    };
    const response = await ApiServices.httpPut(SUBSCRIBER, payload);
    return this.setResponseData(response);
  };

  populateDropdownOptions = async () => {
    const response = JSON.parse(localStorage.getItem("masterData"));
    if (response.responseStatus.isSuccess) {
      return {
        userTypeOptions: response.result.subscriberTypes.map((res) => {
          return {
            value: res.subscriberTypeId,
            label: res.name,
          };
        }),
        countries: response.result.countries,
        states: response.result.states,
        annualRevenues: response.result.annualRevenues,
        roles: response.result.roles,
        areas: response.result.areas.map((res) => {
          return {
            value: res.areaId,
            label: res.name,
          };
        }),
        carrierRepresents: response.result.carrierRepresents.map((res) => {
          return {
            value: res.carrierRepresentId,
            label: res.name,
          };
        }),
        industryGroups: response.result.industryGroups.map((res) => {
          return {
            value: res.industryGroupId,
            label: res.name,
          };
        }),
        technologies: response.result.technologies.map((res) => {
          return {
            value: res.technologyId,
            label: res.name,
          };
        }),
        subscriptions: response.result.subscriptions.map((res) => {
          return {
            value: res.subscriptionId,
            name: res.name,
          };
        }),
      };
    } else {
      return {
        userTypeOptions: [],
        countries: [],
        states: [],
        annualRevenues: [],
        areas: [],
        carrierRepresents: [],
        industryGroups: [],
        technologies: [],
        subscriberTypes: [],
        subscriptions: [],
        roles: [],
      };
    }
  };

  setResponseData = (response) => {
    //if you are changing here change this in the enterprise/dist/subscriber service ones too if required
    if (response && response.result) {
      let newRespone = {
        responseStatus: { ...response.responseStatus },
        result: {
          ...response.result,
          id: response.result.userId,
          primaryEmail: response.result.email,
          zip: response.result.zipCode,
          distributorType:
            response.result.distributorTypes &&
            response.result.distributorTypes.map((res) => {
              return {
                value: res.subscriberTypeId,
                label: res.name,
              };
            }),
          industryGroups:
            response.result.industryGroups &&
            response.result.industryGroups.map((res) => {
              return {
                value: res.industryGroupId,
                label: res.name,
              };
            }),
          subscription: response.result.subscription,
          parentUserId: response.result.parentUserId,
        },
      };
      return newRespone;
    } else {
      return response;
    }
  };

  populateEDDropdown = async () => {
    const response = await ApiServices.httpGet(GET_ED_USERS);
    if (response.responseStatus.isSuccess) {
      return {
        allEDUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.name,
          };
        }),
      };
    } else {
      return {
        allEDUsers: [],
      };
    }
  };

  populateEnterpriseDropdown = async () => {
    const response = await ApiServices.httpGet(ENTERPRISE);
    if (response.responseStatus.isSuccess) {
      return {
        allEnterpriseUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.brandName || "NA",
          };
        }),
      };
    } else {
      return {
        allEnterpriseUsers: [],
      };
    }
  };

  populateDistributorDropdown = async () => {
    const response = await ApiServices.httpGet(DISTRIBUTORS);
    if (response.responseStatus.isSuccess) {
      return {
        allDistributorUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.brandName || "NA",
          };
        }),
      };
    } else {
      return {
        allDistributorUsers: [],
      };
    }
  };

  exportData = (query) => {
    return ApiServices.httpGet(`${SUBSCRIBER_EXPORT}${query}`);
  };

  getOrDefaultValue(value, defaultValue = "") {
    return value || defaultValue;
  }
}
