import React, { useEffect, useState } from "react";
import "./SignInWidget.css";
import {
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { useLoading } from "../../hooks/useLoader";
import cloneDeep from "lodash.clonedeep";
import { toast } from "react-toastify";
import { usePageMode } from "../../hooks/usePageMode";
import { useMasterData } from "../../hooks/useMasterData";

import { useFormik } from "formik";
import { useAuth } from "../../hooks/useAuth";
import MultiSelectDropDown from "../../common/MultiSelectDropDown/MultiSelectDropDown";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { SignUpSchema } from "../../common/ValidationSchema/SignUpSchema";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import ApiServices from "../../services/ApiServices";
import {
  DISTRIBUTORS,
  ENTERPRISE,
  MASTER_DATA,
  SIGNUP,
} from "../../constants/api-constants";
import BusinessPhoneNumberTextbox from "../../common/PhoneNumberTextbox/BusinessPhoneNumberTextbox";
import PhoneNumberTextbox from "../../common/PhoneNumberTextbox/PhoneNumberTextbox";
import { getPhoneCode } from "../../utilities/common";

/*istanbul ignore next */
export default function SignUpPage(props) {
  const history = useHistory();
  const [isDivVisible, setDivVisible] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState("signup");
  const [isDivBasic, setisDivBasic] = useState(false);
  const [isDivContact, setisDivContact] = useState(false);
  const [isDivFinish, setisDivFinish] = useState(false);
  const [distributorTypeOptions, setDistributorTypeOptions] = useState([]);
  const [enterpriseTypes, setEnterpriseTypes] = useState([]);
  const [distributorTypes, setDistributorTypes] = useState([]);
  const [annualRevenues, setAnnualRevenues] = useState([]);
  const [showAnotherMobile, setShowAnotherMobile] = useState(false);
  const [showAnotherFax, setShowAnotherFax] = useState(false);
  const [showAnotherNumber, setShowAnotherNumber] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const auth = useAuth();
  const { showLoader } = useLoading();
  const masterDataContext = useMasterData();

  const [industry, setIndustry] = useState([]);
  const page = usePageMode();
  const initialData = {
    systemGeneratedId: "",
    userTypeId: SystemUsersEnum.DISTRIBUTOR,
    firstName: "",
    lastName: "",
    brandName: "",
    email: "",
    secondaryEmail: "",
    phoneNumber: "",
    secondaryPhoneNumber: "",
    distributorTypes: [],
    enterpriseTypes: [],
    areas: [],
    carrierRepresents: [],
    carriers: [],
    allindustryGroups: [],
    technologies: [],
    billingContact: "",
    billingContactLastName: "",
    businessYear: "",
    websiteUrl: "",
    numberOfEmployees: "",
    numberOfOwners: "",
    producers: "",
    userTypeName: "",
    billingPhoneNumber: "",
    billingAddressLine1: "",
    billingAddressLine2: "",
    businessName: "",
    taxID: null,
    zipCode: "",
    countryId: 1,
    city: "",
    state: "0",
    mobile: "",
    mobile1: "",
    fax: "",
    fax1: "",
    status: 1,
    associateDistributors: [],
    roles: [],
    distributorSubscriptionDomains: [],
    dirty: false,
    // enterpriseGroupId: null,
    // distributorUserId: null,
    userAddOns: [],
    logoGcpPath: "",
    logoGcpFileUrl: "",
  };
  const isValidBasicDetails = () => {
    return (
      formik.values.brandName &&
      !formik.errors.brandName &&
      formik.values.email &&
      !formik.errors.email
    );
  };
  const isValidBillingDetails = () => {
    return (
      formik.values.firstName &&
      !formik.errors.firstName &&
      formik.values.lastName &&
      !formik.errors.lastName &&
      formik.values.phoneNumber &&
      !formik.errors.phoneNumber
    );
  };

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: SignUpSchema,
  });

  const toggleDiv = () => {
    setDivVisible(true);
    setisDivContact(false);
    setisDivBasic(false);
    setActiveMenuItem("signup");
    setSelectedMenuItem("Sign up as");
  };
  const toggleBasicdiv = () => {
    setisDivBasic(true);
    setisDivContact(false);
    setActiveMenuItem("basicDetails");
    setSelectedMenuItem("Basic Details Section");
  };
  const toggleContactdiv = () => {
    setisDivContact(true);
    setActiveMenuItem("contactDetails");
    setSelectedMenuItem("Contact & Billing Details");
  };
  const toogleFinish = () => {
    setisDivFinish(true);
    setDivVisible(false);
    setisDivBasic(false);
    setisDivContact(false);
    setActiveMenuItem("finish");
    setSelectedMenuItem("Finish");
  };

  const populateEnterpriseDropdown = async () => {
    const response = await ApiServices.httpGet(ENTERPRISE);
    if (response.responseStatus.isSuccess) {
      return {
        allEnterpriseUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.name,
          };
        }),
      };
    } else {
      return {
        allEnterpriseUsers: [],
      };
    }
  };
  const populateDistributorDropdown = async () => {
    const response = await ApiServices.httpGet(DISTRIBUTORS);
    if (response.responseStatus.isSuccess) {
      return {
        allDistributorUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.name,
          };
        }),
      };
    } else {
      return {
        allDistributorUsers: [],
      };
    }
  };

  const setMasterData = async () => {
    try {
      showLoader(true);
      let response;
      let res = masterDataContext.masterDataValue;
      if (res && Object.keys(res).length > 0) {
        response = masterDataContext.masterDataValue;
      } else {
        response = await ApiServices.httpGet(MASTER_DATA);
        masterDataContext.setMasterData(response);
      }
      showLoader(false);
      let allEDUsers = response.result?.distributorTypes.map((res) => {
        return {
          value: res.distributorTypeId,
          label: res.name,
        };
      });
      setDistributorTypeOptions(allEDUsers);
      setCountryOptions(response.result.countries);
      setAnnualRevenues(response.result.annualRevenues);
      let allindustryGroups = response.result.industryGroups.map((res) => {
        return {
          value: res.industryGroupId,
          label: res.name,
        };
      });
      setIndustry(allindustryGroups);
      if (auth.getRole() === 1 || auth.getRole() === 2) {
        let allEnterpriseUsers = populateEnterpriseDropdown();
        setEnterpriseTypes(allEnterpriseUsers);
        let allDistributorUsers = populateDistributorDropdown();
        setDistributorTypes(allDistributorUsers);
      } else if (auth.getRole() === 4) {
        formik.setFieldValue(
          "enterpriseGroupId",
          distributorTypes.allDistributorUsers[0]
        );
        formik.setFieldValue(
          "distributorUserId",
          enterpriseTypes.allEnterpriseUsers[0]
        );
      }
    } catch (e) {
      showLoader(false);
    }
  };
  useEffect(() => {
    setMasterData();
    formik.validateForm();
  }, []);

  const signUpUser = async () => {
    try {
      showLoader(true);
      let payload = cloneDeep(formik.values);
      if (payload.distributorSubscriptionDomains) {
        payload.distributorSubscriptionDomains.forEach((el) => {
          el.frequency =
            el.frequency &&
            el.frequency.map((item) => {
              return item.value;
            });
        });
      }
      const allindustryGroups = payload?.industryGroups?.map((res) => {
        return {
          industryGroupId: res.value,
          name: res.label,
        };
      });
      const distributorTypes = payload?.distributorType?.map((res) => {
        return {
          distributorTypeId: res.value,
          name: res.label,
        };
      });
      const enterpriseTypes = payload?.enterpriseType?.map((res) => {
        return {
          enterpriseTypeId: res.value,
          name: res.label,
        };
      });
      delete payload?.industryGroupId;
      const response = await ApiServices.httpPost(SIGNUP, {
        ...payload,
        distributorTypes,
        enterpriseTypes,
        industryGroups: allindustryGroups,
        annualRevenueId: Number(payload.annualRevenue) || 0,
      });
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        setApiMessage(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      showLoader(false);

      toast.error("Internal server error");
    }
    toogleFinish();
  };

  return (
    <Grid className="myContainer">
      <Container maxWidth="md" className="main-grid-container">
        <Grid>
          <Paper className="paper-cus">
            <Grid className="main-div-cus">
              <Grid className="signUp-sub-main">
                <Typography>Welcome to Inchanted!</Typography>
                <Typography>Sign up here to start the journey...</Typography>
                <Grid>
                  <MenuList className="sign-up-cus-menu">
                    <MenuItem
                      className={`menu-item-cus ${
                        activeMenuItem === "signup" ? "selected" : ""
                      }`}
                      onClick={toggleDiv}
                      disabled={!isDivVisible}
                    >
                      Sign up as
                    </MenuItem>
                    <MenuItem
                      className={`menu-item-cus ${
                        selectedMenuItem === "Basic Details Section"
                          ? "selected"
                          : ""
                      }`}
                      onClick={toggleBasicdiv}
                      disabled={!isDivBasic}
                    >
                      Basic Details Section
                    </MenuItem>
                    <MenuItem
                      className={`menu-item-cus ${
                        selectedMenuItem === "Contact & Billing Details"
                          ? "selected"
                          : ""
                      }`}
                      onClick={toggleContactdiv}
                      disabled={!isDivContact}
                    >
                      Contact & Billing Details
                    </MenuItem>
                    <MenuItem
                      className={`menu-item-cus ${
                        selectedMenuItem === "Finish" ? "selected" : ""
                      }`}
                      disabled={!isDivFinish}
                    >
                      Finish
                    </MenuItem>
                  </MenuList>
                </Grid>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                className="cus-divider"
              ></Divider>
              <Grid container spacing={2} className="signUp-main">
                <Grid className="sign-up-text-cus">
                  Already have an Account?
                  <Button
                    variant="outlined"
                    className="signup-button"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    SIGN IN
                  </Button>
                </Grid>
                <Grid className="signUp-main">
                  {activeMenuItem === "signup" && signUpSection()}
                  {activeMenuItem === "basicDetails" && basicDetailsSection()}
                  {activeMenuItem === "contactDetails" &&
                    contactDetailsSection()}
                  {activeMenuItem === "finish" && finishSection()}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </Grid>
  );

  function finishSection() {
    return (
      <div className="signup-div-cus">
        <div className="radio-buttons-align">
          <label className="inpur-radio">Finish</label>
        </div>
        {/* <p>
          Thank you for signing up with Inchanted. Your application
          is under process. Meanwhile please validate your email
          address and start your journey with us.
        </p> */}
        {apiMessage !== "" ? (
          <div>{apiMessage}</div>
        ) : (
          "One or More validations occur"
        )}
      </div>
    );
  }

  function contactDetailsSection() {
    return (
      <div className="signup-div-cus">
        <div className="radio-buttons-align">
          <label className="inpur-radio">Contact & Billing Details</label>
          <Grid container spacing={2}>
            <Grid item xs={6} p={2}>
              <label htmlFor="firstName">First Name</label>
              <MandatoryField />
              <input
                id="firstName"
                name="firstName"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                  formik.setFieldValue("billingContact", e.target.value);
                }}
                className="form-label form-control"
                type="text"
                placeholder="Enter First Name"
                value={formik.values.firstName}
              />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="lastName">Last Name</label>
              <MandatoryField />
              <input
                id="lastName"
                name="lastName"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                  formik.setFieldValue(
                    "billingContactLastName",
                    e.target.value
                  );
                }}
                value={formik.values.lastName}
                className="form-label form-control"
                type="text"
                placeholder="Enter Last Name"
              />
              <InlineMessage error={formik.errors.lastName} />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="phoneNumber">Phone Number</label>
              <MandatoryField />
              <BusinessPhoneNumberTextbox
                id="phoneNumber"
                className="form-label form-control"
                name="phoneNumber"
                placeholder="Enter Business Phone Number"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.setFieldValue("phoneNumber", e);
                }}
                value={formik?.values?.phoneNumber}
                phoneCode={getPhoneCode(
                  countryOptions,
                  formik.values.countryId
                )}
              />
              <InlineMessage error={formik.errors.phoneNumber} />
            </Grid>
            {secNumberContactSection()}

            {secNumberContactExistSection()}
            <Grid item xs={6} p={2}>
              <label htmlFor="mobile">Mobile</label>
              <PhoneNumberTextbox
                id="mobile"
                className="form-label form-control"
                name="mobile"
                placeholder="Enter Mobile Number"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik.values.mobile}
                phoneCode={getPhoneCode(
                  countryOptions,
                  formik.values.countryId
                )}
              />
            </Grid>

            {altMobileContactSection()}

            {altMobileContactExistSection()}
            <Grid item xs={6} p={2}>
              <label htmlFor="fax"> Fax</label>
              <PhoneNumberTextbox
                id="fax"
                className="form-label form-control"
                name="fax"
                placeholder="Enter Fax"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik.values.fax}
                phoneCode={getPhoneCode(
                  countryOptions,
                  formik.values.countryId
                )}
              />
            </Grid>

            {altFaxContactSection()}

            {altFaxContactExistSection()}
            <Grid item xs={6} p={2}>
              <label htmlFor="businessName">Billing First Name</label>
              <input
                id="businessName"
                name="businessName"
                className="form-label form-control"
                type="text"
                placeholder="Enter First Name"
              />
            </Grid>
          </Grid>
        </div>
        <div className="align-button-cus">
          <Button
            className={
              isValidBillingDetails()
                ? "  signup-buttons"
                : "signup-buttons-disabled"
            }
            variant="contained"
            onClick={signUpUser}
            disabled={!isValidBillingDetails()}
          >
            Register
          </Button>
        </div>
      </div>
    );
  }

  function altFaxContactExistSection() {
    return showAnotherFax || props?.formData?.fax1 ? (
      <Grid item xs={6} p={2}>
        <label htmlFor="mobile1">Alternate Fax</label>
        <PhoneNumberTextbox
          id="fax1"
          className="form-label form-control"
          name="fax1"
          placeholder="Enter Alternate Fax"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.fax1}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
      </Grid>
    ) : (
      <></>
    );
  }

  function altFaxContactSection() {
    return (!props?.formData?.userId && !props?.formData?.fax1) ||
      (page.isEditMode && !props?.formData?.fax1) ? (
      <Grid item xs={6} p={2}>
        <div
          role="button"
          name="AddAnotherFax"
          className="add-item"
          data-testid="AddAnotherFax"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                fax1: "",
              });
            }
            setShowAnotherFax((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherFax ? "- Remove Another Fax" : "+ Add Another Fax"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function altMobileContactExistSection() {
    return showAnotherMobile || props?.formData?.mobile1 ? (
      <Grid item xs={6} p={2}>
        <label htmlFor="mobile1">Alternate Mobile</label>
        <PhoneNumberTextbox
          id="mobile1"
          className="form-label form-control"
          name="mobile1"
          placeholder="Enter Alternate Mobile Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.mobile1}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
        <InlineMessage error={formik.errors.mobile1} />
      </Grid>
    ) : (
      <></>
    );
  }

  function altMobileContactSection() {
    return (!props?.formData?.userId && !props?.formData?.mobile1) ||
      (page.isEditMode && !props?.formData?.mobile1) ? (
      <Grid item xs={6} p={2}>
        <div
          role="button"
          name="AddAnotherMobile"
          className="add-item"
          data-testid="AddAnotherMobile"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                mobile1: "",
              });
            }
            setShowAnotherMobile((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherMobile
                ? "- Remove Another Mobile"
                : "+ Add Another Mobile"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function secNumberContactExistSection() {
    return showAnotherNumber || props?.formData?.secondaryPhoneNumber ? (
      <Grid item xs={6} p={2}>
        <label htmlFor="secondaryPhoneNumber">Secondary Phone Number</label>
        <BusinessPhoneNumberTextbox
          id="secondaryPhoneNumber"
          className="form-label form-control"
          name="secondaryPhoneNumber"
          placeholder="Enter Phone Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.setFieldValue("secondaryPhoneNumber", e);
          }}
          value={formik.values.secondaryPhoneNumber}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
      </Grid>
    ) : (
      <></>
    );
  }

  function secNumberContactSection() {
    return (!props?.formData?.userId &&
      !props?.formData?.secondaryPhoneNumber) ||
      (page.isEditMode && !props?.formData?.secondaryPhoneNumber) ? (
      <Grid item xs={6} p={2}>
        <div
          role="button"
          name="AddAnotherNumber"
          className="add-item"
          data-testid="AddAnotherNumber"
          onClick={() => {
            if (showAnotherNumber) {
              formik.setValues({
                ...formik.values,
                secondaryPhoneNumber: "",
              });
            }
            setShowAnotherNumber((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherNumber
                ? "- Remove Another Number"
                : "+ Add Another Number"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function basicDetailsSection() {
    return (
      <div className="signup-div-cus">
        <div className="radio-buttons-align">
          <label className="inpur-radio">Basic Details Section</label>
          <Grid container spacing={2}>
            <Grid item xs={6} p={2}>
              <label htmlFor="brandName">Brand Name</label>
              <MandatoryField />
              <input
                id="brandName"
                name="brandName"
                onChange={(e) => {
                  formik?.setFieldValue("dirty", true);
                  formik?.handleChange(e);
                  formik?.setFieldValue("businessName", e.target.value);
                }}
                className="form-label form-control"
                type="text"
                value={formik?.values?.brandName}
                placeholder="Enter Brand Name"
              />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="businessName">Business Name</label>
              <input
                id="businessName"
                name="businessName"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik?.values?.businessName}
                className="form-label form-control"
                type="text"
                placeholder="Enter Business Name"
              />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="taxID">Tax Id</label>
              <input
                id="taxID"
                name="taxID"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik?.values?.taxID}
                className="form-label form-control"
                type="text"
                placeholder="Enter Tax ID"
              />
              <InlineMessage error={formik.errors.taxID} />
            </Grid>

            {formik?.values?.userTypeId === SystemUsersEnum.DISTRIBUTOR && (
              <Grid item xs={6} p={2}>
                <label htmlFor="distributorType">Distributor Type</label>
                <MultiSelectDropDown
                  name="distributorType"
                  id="distributorType"
                  value={formik?.values?.distributorType}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      distributorType: value,
                      dirty: true,
                    });
                  }}
                  options={distributorTypeOptions}
                />
                <InlineMessage
                  error={
                    formik.errors.distributorType &&
                    props?.routeData?.distTypeError
                  }
                />
              </Grid>
            )}
            {formik?.values?.userTypeId === SystemUsersEnum.ENTERPRISE && (
              <Grid item xs={6} p={2}>
                <label htmlFor="enterpriseType">Enterprise Type</label>
                <MultiSelectDropDown
                  name="enterpriseType"
                  id="enterpriseType"
                  value={formik.values.enterpriseType}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      enterpriseType: value,
                    });
                  }}
                  options={distributorTypeOptions}
                />
                <InlineMessage
                  error={
                    formik.errors.enterpriseType &&
                    props?.routeData?.distTypeError
                  }
                />
              </Grid>
            )}

            <Grid item xs={6} p={2}>
              <label htmlFor="countryId">Country</label>
              <select
                id="countryId"
                name="countryId"
                value={formik.values?.countryId}
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                className="form-control form-select"
              >
                {countryOptions?.map((res) => {
                  return (
                    <option key={res.countryId} value={res.countryId}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="email">Primary Business Email Address</label>
              <MandatoryField />
              <>
                <input
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Primary Email Address"
                />
              </>
              <InlineMessage error={formik.errors.email} />
            </Grid>

            <Grid item xs={6} p={2}>
              <label htmlFor="secondaryEmail">
                Secondary Business Email Address
              </label>
              <input
                id="secondaryEmail"
                name="secondaryEmail"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik.values.secondaryEmail}
                className="form-label form-control"
                type="text"
                placeholder="Enter Secondary Email Address"
              />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="businessYear">Years in Business</label>
              <input
                id="businessYear"
                name="businessYear"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik?.values?.businessYear}
                className="form-label form-control"
                type="text"
                placeholder="Enter Years in Business"
              />
              <InlineMessage error={formik.errors.businessYear} />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="websiteUrl">Website URL</label>
              <input
                id="websiteUrl"
                name="websiteUrl"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik?.values?.websiteUrl}
                className="form-label form-control"
                type="url"
                placeholder="Enter Website URL"
              />
              <InlineMessage error={formik.errors.websiteUrl} />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="annualRevenue">Annual revenue</label>

              <select
                id="annualRevenue"
                name="annualRevenue"
                value={formik?.values?.annualRevenue}
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                className="form-control form-select"
              >
                <option value="0">Select Revenue</option>
                {annualRevenues?.map((res) => {
                  return (
                    <option
                      key={res.annualRevenueId}
                      value={res.annualRevenueId}
                    >
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="numberOfEmployees">Number of employees</label>
              <input
                id="numberOfEmployees"
                name="numberOfEmployees"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                value={formik?.values?.numberOfEmployees}
                className="form-label form-control"
                type="text"
                placeholder="Enter Number of employees"
              />
              <InlineMessage error={formik.errors.numberOfEmployees} />
            </Grid>

            <Grid item xs={6} p={2}>
              <label htmlFor="numberOfOwners">
                Number of principals / owners
              </label>
              <input
                id="numberOfOwners"
                name="numberOfOwners"
                value={formik?.values?.numberOfOwners}
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  formik.handleChange(e);
                }}
                className="form-label form-control"
                type="text"
                placeholder="Enter Number of principals / owners"
              />
              <InlineMessage error={formik.errors.numberOfOwners} />
            </Grid>
            <Grid item xs={6} p={2}>
              <label htmlFor="industryGroups">
                Industry Groups/ Membership
              </label>
              <MultiSelectDropDown
                id="industryGroups"
                name="industryGroups"
                value={formik?.values?.industryGroups}
                isMulti
                options={industry}
                onChange={(value) => {
                  formik.setValues({
                    ...formik.values,
                    industryGroups: value,
                    dirty: true,
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="align-button-cus">
          <Button
            className={
              isValidBasicDetails()
                ? "signup-buttons"
                : "signup-buttons-disabled"
            }
            variant="contained"
            onClick={toggleContactdiv}
            disabled={!isValidBasicDetails()}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }

  function signUpSection() {
    return (
      <div className="signup-div-cus">
        <Typography style={{ fontWeight: "bold" }}>Sign up as</Typography>
        <div className="sign-custom">
          <div className="div-flex-cus">
            <div className="div-radio-cus">
              <input
                type="radio"
                name="radioGroup"
                value={SystemUsersEnum.DISTRIBUTOR}
                checked={
                  SystemUsersEnum.DISTRIBUTOR === formik.values.userTypeId
                }
                onChange={() => {
                  formik.setFieldValue(
                    "userTypeId",
                    SystemUsersEnum.DISTRIBUTOR
                  );
                }}
              />
            </div>
            <div className="div-radio-cuss">
              <label>Distributor</label>
            </div>
            <div className="div-radio-cus">
              <input
                type="radio"
                name="radioGroup"
                value={SystemUsersEnum.ENTERPRISE}
                checked={
                  SystemUsersEnum.ENTERPRISE === formik.values.userTypeId
                }
                onChange={() => {
                  formik.setFieldValue(
                    "userTypeId",
                    SystemUsersEnum.ENTERPRISE
                  );
                }}
              />
            </div>
            <div className="div-radio-cuss">
              <label>Enterprise</label>
            </div>
          </div>
        </div>
        <div className="align-button-cus">
          <Button
            className="signup-buttons"
            onClick={toggleBasicdiv}
            variant="contained"
            color="primary"
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}
