import React from "react";
import Select from "react-select";

function MultiSelectDropDown(props) {
  const customStyles = {
    // For the select itself (not the options)
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "#e9ecef;" : "#ffffff",
        border: isDisabled ? "0.5px solid transparent" : "0.5px solid #b8e7ff",
      };
    },
  };
  const customMenuPosition = () => {
    return {
      top: 0,
    };
  };
  return (
    <div data-testid={props.id}>
      <Select
        className="multi-select-dropdown input-border"
        key={props.key}
        name={props.name}
        styles={customStyles}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
        options={props.options}
        isDisabled={props.disabled}
        menuPosition={customMenuPosition}
      />
    </div>
  );
}

export default MultiSelectDropDown;
